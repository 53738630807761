import React, { Component } from 'react';

// third party resources imports
import {
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListSubheader
} from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';

import { FaEye, FaDollarSign } from 'react-icons/fa';

// custom components
import WYSIWYGRenderer from './WYSIWYGRenderer';
import Styles from '../lib/Styles';

const styles = Styles.components.hintsModal;

export default class InteractionHints extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showHintContent: false,
			hintContent: ''
		};
	}

	renderHintsTitles() {
		return (
			<div style={Object.assign({}, styles.hintsTitleContainer, { backgroundColor: this.props.gameStyles.secondary.color })}>
				<ListSubheader style={Object.assign({}, styles.hintsTitle, { flex: 2 })}>{'Hint'}</ListSubheader>
				<ListSubheader style={Object.assign({}, styles.hintsTitle)}>{'Cost'}</ListSubheader>
				<div style={Object.assign({}, styles.hintsTitle)}/>
			</div>
		);
	}

	renderHintCost(item) {
		const { gameData } = this.props;
		let penalty_text = 'None';

		switch (item.penalty_type) {
		case 'time':
			penalty_text = item.penalty_value + ' mins';
			break;
		case 'points':
			let pointsPreface = gameData.points_preface.length > 0 ? ' ' + gameData.points_preface : '';
			let pointsSuffix = gameData.points_singular.length > 0 ? ' ' + gameData.points_singular : ' point';

			if (parseInt(item.penalty_value) > 1) {
				pointsSuffix = gameData.points_plural.length > 0 ? ' ' + gameData.points_plural : ' points';
			}

			penalty_text = pointsPreface + item.penalty_value + pointsSuffix;
			break;
		default:
			break;
		}

		return (
			<p style={styles.hintsItemText}>{penalty_text}</p>
		);
	}

	renderHintButton(item, index) {
		const {
			userData,
			currentMasterStageIndex,
			currentStageIndex,
			currentInteractionIndex
		} = this.props;

		let purchased = userData.master_stage[currentMasterStageIndex].stage[currentStageIndex].puzzle[currentInteractionIndex].puzzleHints[index].purchased;

		return (
			<IconButton
				style={Object.assign({}, styles.hintsItemButton, { backgroundColor: purchased ? 'green' : 'red' })}
				onClick={() => {
					if (!purchased) {
						this.props.purchaseHint(index, item);
					} else {
						this.setState({
							showHintContent: true,
							hintContent: item.content
						});
					}
				}}>{purchased ? <FaEye style={styles.hintsItemIcon} /> : <FaDollarSign style={styles.hintsItemIcon} />}</IconButton>
		);
	}

	renderHintsList() {
		const { data } = this.props;

		return (
			data.map((item, index) => {
				return (
					<ListItem style={Object.assign({}, styles.hintsItem, { backgroundColor: (index % 2 ? 'white' : 'whitesmoke') })} key={index}>
						<ListItemText style={Object.assign({}, styles.hintsItemText, { flex: 2 })} primary={item.title} />
						{this.renderHintCost(item)}
						<div style={Object.assign({}, styles.hintsItemText, { justifyContent: 'flex-end' })}>
							{this.renderHintButton(item, index)}
						</div>
					</ListItem>
				);
			})
		);
	}

	render() {
		const { showHintContent } = this.state;

		return (
			<div style={styles.container}>
				{showHintContent ?
					<div>
						<IconButton onClick={() => {
							this.setState({
								showHintContent: false,
								hintContent: ''
							});
						}}>
							<BackIcon />
						</IconButton>
						<WYSIWYGRenderer style={styles.container} html={this.state.hintContent} />
					</div>
					:
					<List style={Object.assign({}, styles.container, this.props.style)} subheader={this.renderHintsTitles()}>
						{this.renderHintsList()}
					</List>
				}
			</div>
		);
	}
}
