import React, { Component } from 'react';

// third party resources imports
import {
	List,
	ListItem,
	ListItemText,
	ListSubheader
} from '@material-ui/core';

// custom components
import Styles from '../lib/Styles';

const styles = Styles.components.prevAnswersModal;

export default class PreviousAnswers extends Component {
	renderPrevAnswersTitles() {
		const { gameData } = this.props;

		return (
			<div style={styles.listTitleContainer}>
				<ListSubheader style={styles.listTitle}>{(gameData.puzzle_singular !== '') ? gameData.puzzle_singular : 'Interaction'}</ListSubheader>
				<ListSubheader style={styles.listTitle}>{(gameData.points_plural !== '') ? gameData.points_plural : 'Points'}</ListSubheader>
				<ListSubheader style={styles.listTitle}>Answer</ListSubheader>
			</div>
		);
	}

	listRows() {
		const { stickyHeaders } = this.props;

		return (
			<List style={styles.listContainer} subheader={this.renderPrevAnswersTitles()}>
				{stickyHeaders.length > 0 ? this.stickyHeaders() : this.rowItems()}
			</List>
		);
	}

	stickyHeaders() {
		const { stickyHeaders } = this.props;

		return stickyHeaders.map((header) => (
			<li key={header.id} style={styles.listSection}>
				<ListSubheader style={Object.assign({}, styles.listHeaders, { backgroundColor: this.props.gameStyles.secondary.color })}>{header.stageName}</ListSubheader>
				{this.rowItems(header)}
			</li>
		));
	}

	rowItems(parent) {
		const { gameData, data } = this.props;

		return (
			data.map((item, index) => {
				let answerItem;
				if (!item.id.includes('_Sticky') && parent.location.mStg === item.location.mStg && parent.location.stg === item.location.stg) {
					answerItem = (
						<ListItem style={Object.assign({}, styles.listItem, { backgroundColor: (index % 2 ? 'white' : 'whitesmoke') })} key={index}>
							<ListItemText style={styles.listItemText} primary={item.puzzleName} />
							<ListItemText style={styles.listItemText} primary={((gameData.points_preface !== '') ? gameData.points_preface : '') + item.puzzlePoints} />
							<ListItemText style={styles.listItemText} primary={item.answer} />
						</ListItem>
					);
				}

				return answerItem;
			})
		);
	}

	render() {
		return (
			<div style={Object.assign({}, styles.container, this.props.style)}>
				{this.listRows()}
			</div>
		);
	}
}
