// react and react native resources imports
import React, { Component } from 'react';

// third party resources imports
import { Grid } from '@material-ui/core';

// custom resources imports
import Styles from '../../lib/Styles';

// declares local variables
const styles = Styles.components.results;

export default class Results extends Component {
	renderScoreResults() {
		const { gameData, userData } = this.props;

		return (
			<Grid
				item
				xs={6}>
				<div style={styles.gridSquareItems}>
					<div style={styles.gridSquareComponent}>
						<h1>{(gameData.points_preface !== '' ? ' ' + gameData.points_preface : '') + userData.totalPoints}</h1>
					</div>
					<div style={styles.gridSquareComponent}>
						<h2>{(gameData.points_plural === '' || gameData.points_plural === null) ? '' : gameData.points_plural}</h2>
					</div>
				</div>
			</Grid>
		);
	}

	renderPuzzlesSolved() {
		const { gameData } = this.props;

		return (
			<Grid
				item
				xs={6}>
				<div style={styles.gridSquareItems}>
					<div style={styles.gridSquareComponent}>
						<h1>{this.props.endResults.solvedPuzzles}</h1>
					</div>
					<div style={styles.gridSquareComponent}>
						<h2 style={{ textAlign: 'center' }}>{gameData.puzzle_plural + ' Completed'}</h2>
					</div>
				</div>
			</Grid>
		);
	}

	renderTimeSpent() {
		return (
			<Grid
				item
				xs={6}>
				<div style={styles.gridSquareItems}>
					<div style={styles.gridSquareComponent}>
						<h1>{this.props.endResults.timeSpent}</h1>
					</div>
					<div style={styles.gridSquareComponent}>
						<h2>Time Spent</h2>
					</div>
				</div>
			</Grid>
		);
	}

	renderHintResults() {
		return (
			<Grid
				item
				xs={6}>
				<div style={styles.gridSquareItems}>
					<div style={styles.gridSquareComponent}>
						<h1>{this.props.endResults.usedHints}</h1>
					</div>
					<div style={styles.gridSquareComponent}>
						<h2>Hints Used</h2>
					</div>
				</div>
			</Grid>
		);
	}

	render() {
		const { gameData, userData } = this.props;

		return (
			<div style={styles.container}>
				<div style={styles.container}>
					<h1 style={styles.gridSquareComponent}>Summary</h1>
					{gameData.extra_settings.includes('skip_registration') ? <div /> : <h2 style={styles.gridSquareComponent}>{(gameData.team_singular !== '' ? gameData.team_singular : 'Team') + ': ' + userData.userDetails.teamName}</h2>}
					<p style={styles.gridSquareComponent}>Here are the stats from your experience</p>
					{gameData.mailchimp.length > 0 ? <p style={styles.gridSquareComponent}>This will be emailed to you.</p> : <div /> }
					<p style={styles.gridSquareComponent}>Feel free to close this tabe once you're done.</p>
				</div>
				<Grid container spacing={0}>
					{this.renderScoreResults()}
					{this.renderPuzzlesSolved()}
					{gameData.extra_settings.includes('time_restriction') ? this.renderTimeSpent() : <div />}
					{this.renderHintResults()}
				</Grid>
			</div>
		);
	}
}
