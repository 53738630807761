/* import action types */
import {
	HANDLE_FAILED_EXPORT,
	LB_FAILED_EXPORT,
	MANUAL_DATA_EXPORT,
	SEND_TO_LEADERBOARD,
	UPDATE_LB_FREQUENTLY,
	PROCESS_LEADERBOARD_RESULTS,
	USER_MEDIA_UPLOAD_STATE,
	SET_MAILCHIMP_FOLDER_ID,
	SET_MAILCHIMP_DETAILS,
	SUBSCRIBE_USER,
	PREP_DATA_OBJS,
	IDLE_FAILED_DATA_EXPORT,
	UPDATE_DATA_EXPORT_OBJ
} from '../actions/Network';

/* reducers */

export function failedExportAttempt(state = { attempts: 0, lbAttempts: 0 }, action) {
	switch (action.type) {
	case HANDLE_FAILED_EXPORT:
		state.attempts = action.attempts;
		return state;
	case LB_FAILED_EXPORT:
		state.attempts = action.attempts;
		state.lbAttempts = action.lbAttempts;
		return state;
	default:
		return state;
	}
}
/* ---------- End WIFI and Internet testing and setting logic ---------- */

export function expectedDataObjs(state = 0, action) {
	switch (action.type) {
	case PREP_DATA_OBJS:
		state = action.expectedNumExports;
		return state;
	default:
		return state;
	}
}

export function leaderboard(state = { frequentUpdate: false, timerObj: null }, action) {
	switch (action.type) {
	case SEND_TO_LEADERBOARD:
		return state;
	case UPDATE_LB_FREQUENTLY:
		state = {
			frequentUpdate: action.update,
			timerObj: action.timerObj
		};

		return state;
	default:
		return state;
	}
}

export function prevLBData(state = {}, action) {
	switch (action.type) {
	case PROCESS_LEADERBOARD_RESULTS:
		state = action.prevLBData;
		return state;
	default:
		return state;
	}
}

export function userMediaUploadState(state = {}, action) {
	switch (action.type) {
	case USER_MEDIA_UPLOAD_STATE:
		state = {
			uploadingStateTitle: action.uploadingStateTitle,
			uploadingUserMedia: action.uploadingUserMedia
		};
		return state;
	default:
		return state;
	}
}

export function mailchimpDetails(state = {}, action) {
	switch (action.type) {
	case SET_MAILCHIMP_FOLDER_ID:
		state.folderID = action.folderID;
		return state;
	case SET_MAILCHIMP_DETAILS:
		state = action.details;
		return state;
	default:
		return state;
	}
}

// mailchimp specific function for saving which users are subscribed and which ones are not
export function successfulSubs(state = { subs: 0, nonSubs: 0 }, action) {
	switch (action.type) {
	case SUBSCRIBE_USER:
		state.subs = action.subs;
		state.nonSubs = action.nonSubs;
		return state;
	default:
		return state;
	}
}

export function idleFailedDataExport(state = false, action) {
	switch (action.type) {
	case IDLE_FAILED_DATA_EXPORT:
		state = action.bool;
		return state;
	default:
		return state;
	}
}

export function successiveExportAttempts(state = 0, action) {
	switch (action.type) {
	case UPDATE_DATA_EXPORT_OBJ:
		state = action.exportAttempts;
		return state;
	default:
		return state;
	}
}

export function manuallyExportData(state = false, action) {
	switch (action.type) {
	case MANUAL_DATA_EXPORT:
		state = !state;
		return state;
	default:
		return state;
	}
}
