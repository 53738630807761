import React, { Component } from 'react';
import './App.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from './actions';
import { Switch, Route } from 'react-router-dom';

import Theme from './lib/Theme.js';

import LoginEntry from './scenes/LoginEntry';
import PreExperience from './scenes/PreExperience';
import Experience from './scenes/Experience';
import Summary from './scenes/Summary';

// third party components

class App extends Component {
	constructor(props) {
		super(props);

		this.props.initialiseScreenDimensions();
	}

	componentDidMount() {
		window.onpopstate = () => {
			this.props.processBrowserBackButtonPush();
		};
	}

	componentWillUnmount() {
		this.props.clearScreenDimensionListener();
	}

	render() {
		return (
			<Theme {...this.props}>{
				<Switch>
					<Route exact path='/' render={() => <LoginEntry {...this.props} />} />
					<Route path='/preexperience' render={() => <PreExperience {...this.props} />} />
					<Route path='/experience' render={() => <Experience {...this.props} />} />
					<Route path='/summary' render={() => <Summary {...this.props} />} />
				</Switch>}
			</Theme>
		);
	}
}

/*
 * Redux: hooks the actions in the default the container, so all the
 * children components can access them as props.
 */
const mapDispatchToProps = dispatch => {
	return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
	() => {
		return {};
	},
	mapDispatchToProps
)(App);
