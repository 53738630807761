import React, { Component } from 'react';

// third party resources imports
import {
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	ListSubheader,
	IconButton,
	TextField
} from '@material-ui/core';

import {
	Delete as DeleteIcon
} from '@material-ui/icons';

// custom components
import IndividualRego from './IndividualRegistration';
import WYSIWYGRenderer from '../WYSIWYGRenderer';
import Styles from '../../lib/Styles';

const styles = Styles.scenes.registration;

export default class Registration extends Component {
	renderTeamName() {
		const { registrationProps } = this.props;

		return (
			<div style={styles.regoContentContainer}>
				<h1 style={{ textAlign: 'center' }}>Enter your {registrationProps.regoTeamPronoun} name</h1>
				<TextField
					placeholder={`${registrationProps.regoTeamPronoun} name`}
					onChange={userInput => this.props.setTeamNameText(userInput.target.value)}
					value={this.props.teamName}
					{...this.props}
				/>
				<Button style={{ margin: 10 }} variant='contained' color='primary' onClick={() => {
					if (this.props.teamName.length >= 3) {
						this.props.setRegistrationState('forward');
					} else {
						this.props.addNotification({
							id: 'team_name' + Date.now(),
							message: `Your ${registrationProps.regoTeamPronoun} name needs to be 3 characters or longer`,
							notificationType: 'error'
						});
					}
				}}>Next</Button>
				<p
					style={{ textAlign: 'center', textDecoration: 'underline', color: this.props.gameStyles.primary.color }}
					onClick={() => {
						this.props.openModal('terms_and_cond');
					}}>By continuing you agree to the Terms and Conditions of this experience.</p>
			</div>
		);
	}

	renderUserRegistration() {
		return (
			<div style={styles.regoContentContainer}>
				<IndividualRego {...this.props} />
			</div>
		);
	}

	renderMembersList() {
		const { registrationProps, userData } = this.props;

		return (
			<div style={styles.regoContentContainer}>
				<div style={styles.teamMembersListContainer}>
					<h1 style={{ textAlign: 'center' }}>{this.props.teamName + ' member list'}</h1>
					<List	style={styles.listContainer} subheader={this.renderTeamListHeader()} >
						{userData.userDetails.teamMembers.map((item, index) => (
							<ListItem
								style={styles.teamMembersListItem}
								key={`item-${item.firstName}`}
								button
	          		onClick={() => {
	          			this.props.showSelectedTeamMemberDetails(index);
	          		}}
							>
								<ListItemText primary={`${item.firstName}`} />
								<ListItemText style={{ textAlign: 'left' }}primary={`${item.email}`} />
								{ userData.userDetails.teamMembers.length > 1 ?
									<ListItemSecondaryAction>
										<IconButton edge='end' aria-label='delete'
											onClick={() => {
				          			this.props.removedSelectedTeamMember(index);
				          		}}
										>
											<DeleteIcon />
										</IconButton>
									</ListItemSecondaryAction> :
									<div />
								}
							</ListItem>
						))}
					</List>
					<Button variant='contained' color='primary' onClick={() => {
						this.props.openModal('rego_member');
					}}>Add new {registrationProps.regoTeamPronoun} member</Button>
				</div>
				<Button variant='contained' color='primary' onClick={() => {
					this.props.setRegistrationState('forward');
				}}>Next</Button>
			</div>
		);
	}

	renderTeamListHeader() {
		return (
			<div style={styles.teamMembersListHeader}>
				<ListSubheader>First Name</ListSubheader>
				<ListSubheader>Email</ListSubheader>
				<ListSubheader />
			</div>
		);
	}

	render() {
		let regoScreen = this.renderTeamName();

		switch (this.props.registrationState) {
		case 'add_team_member':
			regoScreen = this.renderUserRegistration();
			break;
		case 'team_list':
			regoScreen = this.renderMembersList();
			break;
		default:
			regoScreen = this.renderTeamName();
			break;
		}

		return (
			<div style={styles.container}>
				{regoScreen}
			</div>
		);
	}
}
