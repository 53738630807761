import { combineReducers } from 'redux';
import * as GameReducers from './Game';
import * as UserReducers from './User';
import * as ExperienceReducers from './Experience';
import * as EventReducers from './Event';
import * as UniModalReducers from './UniversalModal';
import * as TimersReducers from './Timers';
import * as InteractionReducers from './Interaction';
import * as CustomNumberpadReducers from './CustomNumberpad';
import * as NetworkReducers from './Network';
import * as NotificationReducers from './Notifications';

export default combineReducers(
	Object.assign(
		GameReducers,
		UserReducers,
		ExperienceReducers,
		EventReducers,
		UniModalReducers,
		TimersReducers,
		InteractionReducers,
		CustomNumberpadReducers,
		NetworkReducers,
		NotificationReducers
	)
);
