// custom resources
import Addresses from '../lib/Addresses';
import * as userActions from './User';
import * as uniModalActions from './UniversalModal';

/* action types */
export const HANDLE_FAILED_EXPORT = 'HANDLE_FAILED_EXPORT';
export const LB_FAILED_EXPORT = 'LB_FAILED_EXPORT';
// Data export variables
export const MANUAL_DATA_EXPORT = 'MANUAL_DATA_EXPORT';
export const SEND_TO_LEADERBOARD = 'SEND_TO_LEADERBOARD';
export const UPDATE_LB_FREQUENTLY = 'UPDATE_LB_FREQUENTLY';
export const PROCESS_LEADERBOARD_RESULTS = 'PROCESS_LEADERBOARD_RESULTS';
export const USER_MEDIA_UPLOAD_STATE = 'USER_MEDIA_UPLOAD_STATE';
export const SET_MAILCHIMP_FOLDER_ID = 'SET_MAILCHIMP_FOLDER_ID';
export const SET_MAILCHIMP_DETAILS = 'SET_MAILCHIMP_DETAILS';
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER';
export const PREP_DATA_OBJS = 'PREP_DATA_OBJS';
export const IDLE_FAILED_DATA_EXPORT = 'IDLE_FAILED_DATA_EXPORT';
export const UPDATE_DATA_EXPORT_OBJ = 'UPDATE_DATA_EXPORT_OBJ';

const md5 = require('crypto-js/md5');

/* action creators */
export function sendAllProcessedUserData() {
	return (dispatch, getState) => {
		let allProcessedUserData = getState().allProcessedUserData;

		// loops through all processed userdata and sends it to the server 1 by 1
		for (let i = 0; i < allProcessedUserData.length; i++) {
			if (allProcessedUserData[i].sent === false) {
				dispatch(sendToAdminPortal(allProcessedUserData[i]));
			}
		}
	};
}

/* ---------- Start Sending data logic ---------- */
export function prepExportDataObjs() {
	return (dispatch, getState) => {
		let userData = getState().userData;

		let newValue = userData.dataExports[0].expectedExports.length - userData.dataExports[0].successfulDataObjs.length;

		dispatch({
			type: PREP_DATA_OBJS,
			expectedNumExports: newValue
		});
		dispatch(callNextDataExport());
	};
}

export function callNextDataExport() {
	return (dispatch, getState) => {
		let userData = getState().userData;

		userData.dataExports[0].exportState = 'In progress';

		for (let i = 0; i < userData.dataExports.length; i++) {
			if (userData.dataExports[i].type !== 'allData' && !userData.dataExports[i].sent && !userData.dataExports[i].finishedAttempts) {
				userData.dataExports[0].processing = userData.dataExports[i].type;
				userData.dataExports[i].exportState = 'In progress';

				switch (userData.dataExports[i].type) {
				case 'adminPanel':
					let processedUserData = getState().processedUserData;

					dispatch(sendToAdminPortal(processedUserData));
					break;
				case 'leaderboard':
					dispatch(processLeaderboardResults(true));
					break;
				case 'mailchimp':
					dispatch(exportMailchimpUserData());
					break;
				default:
					break;
				}

				// need to break out of the loop so we don't call the next function until the current one finishes
				break;
			}
		}
	};
}

// used by exportToLeaderboard, sendToAdminPortal and all of the mailchimp fetch requests to narrow down fetch requests to a singular point of failure
async function makeFetchRequest(connectionStatus, address, restObj) {
	// if (connectionStatus) {
	let queryResult = '';

	try {
		let res = await fetch(address, restObj);

		if (!res.ok) {
			let errorObj = await httpErrorCodes(res.status);

			throw {
				cause: 'bad-url',
				error: 'makeFetchRequest bad response ' + errorObj.errorMsg,
			};
		}

		queryResult = await res.json();
		return queryResult;
	} catch (err) {
		let cause = err.cause !== undefined ? err.cause : err.message;
		throw {
			cause: cause,
			error: err.error,
		};
	}
	// } else {
	// 	throw {
	// 		cause: 'no-internet',
	// 		error: 'makeFetchRequest: No internet connection',
	// 	};
	// }
}

export function sendToAdminPortal(data) {
	return (dispatch, getState) => {
		// non state variables
		let connected = false;

		if (getState().wifiConnectedStatus && getState().internetConnection) {
			connected = true;
		}

		let bodyData = JSON.stringify(data);

		let restObj = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: bodyData
		};

		makeFetchRequest(connected, 'api/admin/export', restObj)
			.then(() => {
				dispatch(userActions.updateProcessedUserData(data.results_id, true));
				dispatch(updateDataExportObj('adminPanel', true));
			})
			.catch(err => {
				dispatch(updateDataExportObj('adminPanel', false, err.error));
			});
	};
}

export function updateLeaderboardFrequently(bool) {
	return (dispatch, getState) => {
		let leaderboardUpdater = getState().leaderboard.timerObj;

		if (bool) {
			dispatch(processLeaderboardResults(true));
			leaderboardUpdater = setInterval(() => {
				if (!getState().allTimersPaused) {
					dispatch(processLeaderboardResults());
				}
			}, 30000);
		} else {
			clearInterval(leaderboardUpdater);
		}

		dispatch({
			type: UPDATE_LB_FREQUENTLY,
			update: bool,
			timerObj: leaderboardUpdater
		});
	};
}

export function processLeaderboardResults(forceUpdate) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		// let results = getState().endResults;
		let prevLBObj = getState().prevLBData;

		if (forceUpdate === undefined) {
			forceUpdate = false;
		}

		// non state variables
		let exportLeaderboardDataAllowed = false;
		let lbObj = gameData.leaderboard;

		// loop through the columns for the leaderboard and get the data that should be associated with that columns variable that it was passed
		for (let i = 0; i < lbObj.columns.length; i++) {
			let result = '';
			let templbObj = lbObj.columns[i].resultObj;

			switch (lbObj.columns[i].columnVariable) {
			case 'interaction_result':
				let tempInteractionResult = {
					id: lbObj.columns[i].columnTitle
				};

				tempInteractionResult.result = userData.master_stage[templbObj.mStgIndex].stage[templbObj.stgIndex].puzzle[templbObj.interactionIndex].answer;

				if (Object.keys(prevLBObj).length > 0) {
					let prevInteraction = JSON.parse(prevLBObj.columns[i].result);
					let prevResult = prevInteraction.result;

					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(prevResult, tempInteractionResult.result);
					}
				}

				result = JSON.stringify(tempInteractionResult);
				break;
			case 'progressive_points':
				let score = 0;
				let tempProgressivePointsResult = {
					id: lbObj.columns[i].columnTitle
				};

				if (templbObj.stgIndex === 'None') {
					score = userData.master_stage[templbObj.mStgIndex].masterStageTotalPoints;
				} else {
					score = userData.master_stage[templbObj.mStgIndex].stage[templbObj.stgIndex].stageTotalPoints;
				}

				tempProgressivePointsResult.result = score;

				if (Object.keys(prevLBObj).length > 0) {
					let prevScore = JSON.parse(prevLBObj.columns[i].result);
					let prevResult = prevScore.result;

					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(prevResult, tempProgressivePointsResult.result);
					}
				}

				result = JSON.stringify(tempProgressivePointsResult);
				break;
			case 'total_points':
				result = userData.totalPoints;

				if (Object.keys(prevLBObj).length > 0) {
					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(parseInt(prevLBObj.columns[i].result), userData.totalPoints);
					}
				}
				break;
			case 'time_left':
				let tempTimeLeftObj = {
					id: lbObj.columns[i].columnTitle
				};
				let timeLeftObj = lbObj.columns[i].timeObj;
				let timerLeft = 0;

				switch (timeLeftObj.timer) {
				case 'msTimer':
					timerLeft = userData.master_stage[timeLeftObj.timerIndex.mStgIndex].masterStageTimeLeft;
					break;
				case 'sTimer':
					timerLeft = userData.master_stage[timeLeftObj.timerIndex.mStgIndex].stage[timeLeftObj.timerIndex.stgIndex].stageTimeLeft;
					break;
				default:
					timerLeft = userData.gameTimer;
					break;
				}

				tempTimeLeftObj.result = timerLeft;

				if (Object.keys(prevLBObj).length > 0) {
					let prevTimeLeft = JSON.parse(prevLBObj.columns[i].result);
					let prevResult = prevTimeLeft.result;

					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(prevResult, tempTimeLeftObj.result);
					}
				}

				result = JSON.stringify(tempTimeLeftObj);
				break;
			case 'time_spent':
				let tempTimeSpentObj = {
					id: lbObj.columns[i].columnTitle
				};
				let timeSpentObj = lbObj.columns[i].timeObj;
				let timerSpent = 0;

				switch (timeSpentObj.timer) {
				case 'msTimer':
					let gameMasterStageTime = gameData.master_stages[timeSpentObj.timerIndex.mStgIndex].time_restriction * 60;
					timerSpent = gameMasterStageTime - userData.master_stage[timeSpentObj.timerIndex.mStgIndex].masterStageTimeLeft;
					break;
				case 'sTimer':
					let gameStageTimer = gameData.master_stages[timeSpentObj.timerIndex.mStgIndex].stages[timeSpentObj.timerIndex.stgIndex].time_restriction * 60;
					timerSpent = gameStageTimer - userData.master_stage[timeSpentObj.timerIndex.mStgIndex].stage[timeSpentObj.timerIndex.stgIndex].stageTimeLeft;
					break;
				default:
					if (getState().gameOver || getState().idleFailedDataExport) {
						timerSpent = (userData.finishTime - userData.startTime) / 1000;
					} else {
						timerSpent = (Date.now() - userData.startTime) / 1000;
					}
					break;
				}

				tempTimeSpentObj.result = timerSpent;

				if (Object.keys(prevLBObj).length > 0) {
					let prevTimeSpent = JSON.parse(prevLBObj.columns[i].result);
					let prevResult = prevTimeSpent.result;

					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(prevResult, tempTimeSpentObj.result);
					}
				}

				result = JSON.stringify(tempTimeSpentObj);
				break;
			case 'game_time_finished':
				if (getState().gameOver || getState().idleFailedDataExport) {
					result = userData.finishTime;
				} else {
					result = 'NFY';
				}

				if (Object.keys(prevLBObj).length > 0) {
					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(prevLBObj.columns[i].result, result);
					}
				}
				break;
			case 'team_name':
				result = userData.userDetails.teamName;
				break;
			case 'hints_used':
				if (Object.keys(prevLBObj).length > 0) {
					if (!exportLeaderboardDataAllowed) {
						exportLeaderboardDataAllowed = compareResults(prevLBObj.columns[i].result, userData.hints_used);
					}
				}

				result = userData.hints_used;
				break;
			default:
				console.log('You managed to add something that doesn\'t exist. Well done!');
				break;
			}

			lbObj.columns[i].result = String(result);
		}

		// determine if an update to leaderboard data is required when no data has changed
		if (forceUpdate || getState().gameOver) {
			exportLeaderboardDataAllowed = true;
		}

		// dispatch(gameActions.setDebugMsg('processed LB results for display. ' + (exportLeaderboardDataAllowed ? 'Exporting': 'Not exporting') + ' changes'));

		if (exportLeaderboardDataAllowed) {
			dispatch(exportToLeaderboard(lbObj, forceUpdate));
			lbObj.lastUpdate = Date.now();
		}

		dispatch({
			type: PROCESS_LEADERBOARD_RESULTS,
			prevLBData: lbObj
		});
	};
}

function compareResults(oldValue, newValue) {
	if (oldValue !== newValue) {
		return true;
	}

	return false;
}

// sends the team results at the end of an experience to the web based leaderboard
export function exportToLeaderboard(lbDataObj, forceUpdate) {
	return (dispatch, getState) => {
		let userData = getState().userData;

		// non state variables
		let lbObj = lbDataObj;
		lbObj.source = 'doe_app';
		lbObj.results_id = userData.resultsID;
		lbObj = JSON.stringify(lbObj);

		let team_results = {
			api_key: 'DOTEAPIKEY',
			lb_obj: lbObj,
			message: 'app result received'
		};

		let restObj = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(team_results)
		};

		makeFetchRequest(getState().internetConnection, Addresses.LEADERBOARD_URL, restObj)
			.then(async res => {
				userData.sentToLB = true;
				// dispatch(gameActions.setDebugMsg('Leaderboard data has successfully been sent'));
				dispatch({ type: SEND_TO_LEADERBOARD });

				// we only care about updating the data for the final push
				if ((getState().gameOver || getState().idleFailedDataExport || getState().manuallyExportData) && userData.dataExports[0].processing === 'leaderboard') {
					dispatch(updateDataExportObj('leaderboard', true, res));
				}
			})
			.catch(err => {
				let errorMsg = 'exportToLeaderboard: Error encountered ' + err.error;
				// dispatch(gameActions.sendErrorReport(err.cause, 'leaderboard', errorMsg));

				if ((getState().gameOver || getState().idleFailedDataExport || getState().manuallyExportData) && userData.dataExports[0].processing === 'leaderboard') {
					dispatch(updateDataExportObj('leaderboard', false, err.error));
				}
			});
	};
}

/* ---- Start Mailchimp ---- */
// get all of the potential interests that a player can be subscribed to <- migrate this to Admin panel eventually
export function getMailchimpDetails(gameID) {
	return (dispatch, getState) => {
		// non state variables
		let restObj = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		makeFetchRequest(getState().internetConnection, '/api/mailchimp/' + gameID, restObj).then(obj => {
			dispatch({
				type: SET_MAILCHIMP_DETAILS,
				details: obj
			});
		}).catch(err => {
			console.error('ERROR Network.js getMailchimpDetails: ', err);
		});
	};
}

// checks members list by their emails to see if they're already subscribed or not
export function exportMailchimpUserData() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let results = getState().endResults;
		let mailchimpDetails = getState().mailchimpDetails;

		// non state variables
		// MC specific
		let experienceGroups = mailchimpDetails.groups; // groups added from Game Data
		let mailchimpGroups = mailchimpDetails.mailchimpGroups; // group collected from mailchimp
		// team specific
		let teamMembers = userData.userDetails.teamMembers;
		let teamPhotoURL = userData.userDetails.teamPhotoURL;
		// export data
		let exportObj = {
			photoSent: false,
			numMembers: teamMembers.length,
			newAccounts: 0
		};

		// common data points for the data obj
		let data = {
			merge_fields: {
				GAMEPLAYED: userData.gameName,
				TEAMNAME: userData.userDetails.teamName,
				START: userData.displayStartTime,
				INTCOMP: results.solvedPuzzles,
				HINTS: results.usedHints,
				DURATION: results.timeSpent,
				POINTS: userData.totalPoints,
				INTPERC: results.perSolvedInt,
				HINTSPERC: results.perHintsUsed,
				POINTSPERC: results.perUserPoints,
				RESULTSID: userData.resultsID
			}
		};

		// include team photo if it exists
		if (gameData.extra_settings.includes('team_photo')) {
			data.merge_fields['TEAMPHOTO'] = teamPhotoURL;
		}

		// include leaderboard URL if there's a leaderboard present
		if (gameData.extra_settings.includes('send_to_leaderboard')) {
			data.merge_fields['LEADER'] = userData.lbSessionURL;
		}

		// assign the correct groups to the Users
		let assignedGroups = {};

		if (mailchimpGroups !== undefined) {
			for (let j = 0; j < mailchimpGroups.length; j++) {
				// gets the relevant interest name that corresponds with a game name
				let mailChimpName = mailchimpGroups[j].interest_name;

				for (let l = 0; l < experienceGroups.length; l++) {
					let experienceGroupName = experienceGroups[l];

					if (mailChimpName === experienceGroupName) {
						assignedGroups[mailchimpGroups[j].id] = true;
					}
				}
			}
		}

		// only send interests if they're present
		if (Object.keys(assignedGroups).length !== 0) {
			data.interests = assignedGroups;
		}

		// check if there are custom merge tags that need to be assigned
		let customMergeTags = mailchimpDetails.mergeTags;

		for (let k = 0; k < customMergeTags.length; k++) {
			let tag = customMergeTags[k].tag;
			let mSI = customMergeTags[k].resultObj.mStgIndex;
			let sI = customMergeTags[k].resultObj.stgIndex;
			let intI = customMergeTags[k].resultObj.interactionIndex;
			let interactionResult = userData.master_stage[mSI].stage[sI].puzzle[intI].answer;
			// if survey get the point that the user selected
			if (userData.master_stage[mSI].stage[sI].puzzle[intI].type === 'survey') {
				interactionResult = userData.master_stage[mSI].stage[sI].puzzle[intI].answer.selection;
			}

			data.merge_fields[tag] = interactionResult;
		}

		let restObj = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		for (let i = 0; i < teamMembers.length; i++) {
			let teamMember = teamMembers[i];
			let memberHashEmail = md5(teamMember.email);
			// set personal details
			data.merge_fields['FNAME'] = teamMember.firstName;
			data.merge_fields['LNAME'] = teamMember.lastName;
			data.merge_fields['GENDER'] = teamMember.gender;
			data.merge_fields['AGE'] = teamMember.age;
			data.email_address = teamMember.email;

			restObj.body = JSON.stringify(data);

			makeFetchRequest(getState().internetConnection, '/api/mailchimp/export/' + gameData.id + '/' + memberHashEmail, restObj).then(res => {
				userData.userDetails.teamMembers[i].status = res.status;

				let successfulSubs = getState().successfulSubs.subs;
				let nonSubs = getState().successfulSubs.nonSubs;

				if (res.status === 'subscribed') {
					successfulSubs ++;
				} else {
					nonSubs ++;
				}

				dispatch({
					type: SUBSCRIBE_USER,
					userIndex: i,
					subs: successfulSubs,
					nonSubs: nonSubs
				});

				if (userData.userDetails.teamMembers.length === (successfulSubs + nonSubs)) {
					// updating Export Data object with mailchimp details
					exportObj.subs = successfulSubs;
					exportObj.nonSubs = nonSubs;

					dispatch(updateDataExportObj('mailchimp', true, exportObj));
				}
			}).catch(err => {
				let errorMsg = 'ERROR exportMailchimpUserData(): Something went wrong subscribing/updating user: ' + teamMember.firstName + ' but caught ' + err;
				console.error(errorMsg);
				dispatch(updateDataExportObj('mailchimp', false, exportObj));
			});
		}
	};
}
/* ---- End Mailchimp ---- */

export function updateDataExportObj(dataType, sent, extra) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let leaderboard = getState().leaderboard;
		let exportAttempts = getState().successiveExportAttempts;

		// non state variables
		let index = userData.dataExports.findIndex(data => data.type === dataType);
		let allDataIndex = userData.dataExports.findIndex(data => data.type === 'allData');
		let allowedAttempts = 3;
		// below is assigned for the purpose of determining if the current data type being updated has failed previously or not
		let failedDataObjIndex = userData.dataExports[allDataIndex].failedDataObjs.findIndex(type => type === dataType);
		let completedAllExportAttempts = false;
		let msg = '';

		userData.dataExports[index].attempts ++;
		userData.dataExports[index].sent = sent;
		userData.dataExports[index].lastAttempt = Date.now();

		if (sent) {
			msg = dataType + ' has been successfully sent';
			userData.dataExports[index].exportState = 'completed';
			userData.dataExports[index].reason = msg;
			userData.dataExports[index].finishedAttempts = true;

			switch (dataType) {
			case 'adminPanel':
				break;
			case 'leaderboard':
				userData.dataExports[index].successfulPushes++;
				break;
			case 'mailchimp':
				userData.dataExports[index].photoSent = extra.photoSent;
				userData.dataExports[index].numMembers = extra.numMembers;
				userData.dataExports[index].newAccounts = extra.newAccounts;
				userData.dataExports[index].subs = extra.subs;
				userData.dataExports[index].nonSubs = extra.nonSubs;

				// if (!extra.photoSent) {
				//   // this will cause the logic chain to be run again, which needs to happen in order to update users mailchimp entry with teamphoto
				//   dispatch(pushTeamPhotoMailchimp());
				// }
				break;
			default:
				break;
			}

			// check failedDataObjs array if the data type we just tried to update is already there, if it is, remove it from the array
			if (failedDataObjIndex !== -1) {
				userData.dataExports[allDataIndex].failedDataObjs.splice(failedDataObjIndex, 1);
			}

			let successfulDataObjIndex = userData.dataExports[allDataIndex].successfulDataObjs.findIndex(type => type === dataType);
			if (successfulDataObjIndex === -1) {
				userData.dataExports[allDataIndex].successfulDataObjs.push(dataType);
			}
			exportAttempts++;
			// dispatch(gameActions.setDebugMsg('successfulExports: ' + userData.dataExports[allDataIndex].successfulDataObjs.length));
		} else { // not sent
			if (dataType !== 'allData') {
				userData.dataExports[index].exportState = 'error';
				msg = dataType + ' data not sent. Current number of attempts: ' + userData.dataExports[index].attempts;

				if (failedDataObjIndex === -1) {
					userData.dataExports[allDataIndex].failedDataObjs.push(dataType);
				}

				// did not get sent
				// dispatch(gameActions.setDebugMsg(msg));
				if (getState().internetConnection) {
					if (extra === undefined) {
						userData.dataExports[index].reason = 'Could not reach destination URL';
					} else {
						// structured to compensate if the response is an error msg
						if (typeof extra.response === 'object') {
							userData.dataExports[index].reason = extra.response.errorMsg;
						} else {
							userData.dataExports[index].reason =  extra.response;
						}
					}
				} else {
					msg = 'Failed to export ' + dataType + ' after ' + userData.dataExports[index].attempts + '/' + allowedAttempts + ' attempts. The device does not have an active internet connection'
					userData.dataExports[index].reason = msg;
					// dispatch(gameActions.sendErrorReport('no-internet', dataType, userData.dataExports[index].reason));

					// if we're using a leaderboard with frequent update, we want to try and get the internet up and running before attempting to push data out so we're going to turn it off for now
					if (dataType === 'leaderboard' && gameData.leaderboard.frequent_update === true && leaderboard.frequentUpdate === true) {
						dispatch(updateLeaderboardFrequently(false));
					}
				}

				// moves onto next data export if the current obj has failed to send
				if (userData.dataExports[index].attempts >= allowedAttempts) {
					exportAttempts ++;
					userData.dataExports[index].finishedAttempts = true;
					userData.dataExports[index].exportState = 'failed';
					msg = 'Failed to export ' + dataType + ' data';
				}
			} else { // All data called by prepDataForExport
				msg = 'No internet connection found. No data has been exported. App will reattempt to export user data next time it is idle';
				userData.dataExports[allDataIndex].exportState = 'failed';
				userData.dataExports[allDataIndex].failedDataObjs = userData.dataExports[allDataIndex].expectedExports;
				for (let i = 0; i < userData.dataExports[allDataIndex].failedDataObjs.length; i++) {
					userData.dataExports[(i + 1)].exportState = 'failed';
				}
			}
		}

		// this is flawed. Expected exports is set and forgotten when user data is initialised. If we're dealing with partial exports then
		if (exportAttempts < getState().expectedDataObjs) {
			dispatch(callNextDataExport());
		} else {
			if (userData.dataExports[allDataIndex].failedDataObjs.length > 0) {
				msg = userData.dataExports[allDataIndex].failedDataObjs.length + ' data object' + (userData.dataExports[allDataIndex].failedDataObjs.length > 1 ? 's' : '') + ' failed to be exported. App will re-attempt to export when it is idle next';
				userData.dataExports[allDataIndex].finishedAttempts = true;
				userData.dataExports[allDataIndex].lastAttempt = Date.now();
				userData.dataExports[allDataIndex].reason = msg;
				userData.dataExports[allDataIndex].exportState = 'failed';

				completedAllExportAttempts = true;
				exportAttempts = 0;

				/*if (getState().gameOver) {
					dispatch(gameActions.setResetAllowed(true));
				}*/
			} else {
				userData.dataExports[allDataIndex].sent = true;
				userData.dataExports[allDataIndex].attempts ++;
				userData.dataExports[allDataIndex].finishedAttempts = true;
				userData.dataExports[allDataIndex].lastAttempt = Date.now();

				// we need to check to make sure the expected export objects have actually been added to either the success or failure array. If they haven't been added to either, we'll allocate them to the failed array and let the app resend the data at a later time
				if (userData.dataExports[allDataIndex].expectedExports.length === userData.dataExports[allDataIndex].successfulDataObjs.length) {
					msg = 'All data objects successfully exported';
					userData.dataExports[allDataIndex].reason = msg;
					userData.dataExports[allDataIndex].failedDataObjs = [];
					userData.dataExports[allDataIndex].exportState = 'completed';
				} else {
					let missingNumObjects = userData.dataExports[allDataIndex].expectedExports.length - userData.dataExports[allDataIndex].successfulDataObjs.length;
					let errorMsg = missingNumObjects + ' did not get allocated to successfulDataObjs or failedDataObjs. They have been assigned to failedDataObjs for next idle data push';
					msg = errorMsg;
					// dispatch(gameActions.sendErrorReport('data-obj-allocation-failure' , 'updateDataExportObj', errorMsg));

					userData.dataExports[allDataIndex].reason = errorMsg;
					// compare successfulDataObjs against the expectedExports, take the data types missing from successfulDataObjs array and assign them to failedDataObjs
					userData.dataExports[allDataIndex].failedDataObjs = userData.dataExports[allDataIndex].expectedExports.filter(n => !userData.dataExports[allDataIndex].successfulDataObjs.includes(n));
					userData.dataExports[allDataIndex].exportState = 'failed';
				}

				completedAllExportAttempts = true;
				exportAttempts = 0;

				/*if (getState().gameOver) {
					dispatch(gameActions.setResetAllowed(true));
				}*/
			}
		}

		dispatch({
			type: UPDATE_DATA_EXPORT_OBJ,
			exportAttempts: exportAttempts
		});

		// idleFailDataExport is only set to true when attempting to export failed data objs
		if (getState().idleFailedDataExport && completedAllExportAttempts) {
			dispatch(setIdleFailedDataExport(false));
			// dispatch(uniModalActions.closeModal());
		}
	};
}

async function getFailedUserDataObj(prevUserDataStorage) {
	return new Promise((resolve, reject) => {
		for (let i = 0; i < prevUserDataStorage.length; i++) {
			if (prevUserDataStorage[i].dataExports[0].failedDataObjs.length > 0) {
				return resolve(prevUserDataStorage[i]);
			}
		}
		return reject({ msg: 'No failed userdata objs found' });
	});
}

export function setIdleFailedDataExport(bool) {
	return (dispatch, getState) => {
		// if app is active and bool is false and the modal is open. Close the modal
		if (getState().appState === 'active' && !bool && getState().showModal && getState().modalProps.screenType === 'help') {
			dispatch(uniModalActions.closeModal());
		}

		dispatch({
			type: IDLE_FAILED_DATA_EXPORT,
			bool: bool
		});
	};
}

// TODO need to set this up so it will cut off any function calls to do with exporting data
// export function stopAllDataExports() {
// 	return (dispatch, getState) => {
// 		// dispatch(gameActions.setDebugMsg('Reseting userData back to blank'));
// 		dispatch(userActions.initialiseUserData());
// 		dispatch(userActions.clearUserMediaArray());

// 		if (getState().idleFailedDataExport) {
// 			dispatch(setIdleFailedDataExport(false));
// 		}

// 		// somehow we need to stop the process of sending any data
// 		if (getState().gameOver) {
// 			dispatch(gameActions.setResetAllowed(true));
// 		}
// 	};
// }

export function handleFailedExport(exportType) {
	return (dispatch, getState) => {
		let exportAttempts = getState().failedExportAttempt.attempts;
		let lbAttempts = getState().failedExportAttempt.lbAttempts;

		if (exportType === 'leaderboard') {
			switch (exportAttempts) {
			case 0:
				if (lbAttempts <= 1) {
					exportAttempts++;

					setTimeout(() => {
						dispatch({
							type: HANDLE_FAILED_EXPORT,
							attempts: exportAttempts,
						});
						dispatch(processLeaderboardResults(true));
					}, 500);
				}
				break;
			case 1:
				exportAttempts++;

				setTimeout(() => {
					dispatch({
						type: HANDLE_FAILED_EXPORT,
						attempts: exportAttempts
					});

					dispatch(processLeaderboardResults(true));
				}, 6500);
				break;
			default:
				exportAttempts = 0;
				lbAttempts++;

				if (lbAttempts >= 2) {
					lbAttempts = 0;
					let reinstateTime = 5 * 60 * 1000;
					dispatch(updateLeaderboardFrequently(false));

					setTimeout(() => {
						dispatch(updateLeaderboardFrequently(true));
					}, reinstateTime);
				}

				dispatch({
					type: LB_FAILED_EXPORT,
					attempts: exportAttempts,
					lbAttempts: lbAttempts
				});
				break;
			}
		}
	};
}

function httpErrorCodes(errorCode) {
	let errorObj = {
		errorCode: errorCode,
		errorMsg: ''
	};

	switch (errorCode) {
	case 400:
		errorObj.errorMsg = 'Error - 400: Bad Request! The server could not understand the request due to invalid syntax';
		break;
	case 401:
		errorObj.errorMsg = 'Error - 401: Unauthorised! The client must authenticate itself to get the requested response. Make sure your credentials are correct before trying again';
		break;
	case 403:
		errorObj.errorMsg = 'Error - 403: Forbidden! The client does not have access rights to the content. Check your access token';
		break;
	case 404:
		errorObj.errorMsg = 'Error - 404: Not Found! The server cannot find the requested resource';
		break;
	case 408:
		errorObj.errorMsg = 'Error - 408: Request Timeout! The server would like to shut down this unused connection due to idle client';
		break;
	case 415:
		errorObj.errorMsg = 'Error - 415: Unsupported Media Type! The media format of the requested data is not supported by the server';
		break;
	case 429:
		errorObj.errorMsg = 'Error - 429: Too many requests! The user has sent too many requests in a given amount of time';
		break;
	case 502:
		errorObj.errorMsg = 'Error - 502: Bad Gateway! The server, while working as a gateway to get a response needed to handle the request, got an invalid response';
		break;
	case 503:
		errorObj.errorMsg = 'Error - 503: Service unavailable! The server is not ready to handle the request';
		break;
	case 504:
		errorObj.errorMsg = 'Error - 504: Gateway Timeout! The server did not respond in time';
		break;
	default:
		errorObj.errorMsg = 'Error - ' + errorCode + ': Skynet attack! Not really. Find the closest dev and tell them the http(number) code displayed here';
		break;
	}

	return errorObj;
}
