import React, { Component } from 'react';

// third party resources imports
import { Button, TextField } from '@material-ui/core';

// custom resources imports
import Styles from '../lib/Styles';
import WYSIWYGRenderer from './WYSIWYGRenderer';

// Interaction components
import ImageSelection from './ImageSelection.js';
import SurveyPanel from './SurveySelection.js';

// declares local variables
const styles = Styles.scenes.interactionPanel;

export default class InteractionPanel extends Component {
	_renderInteractionContent() {
		const {
			gameData,
			currentMasterStageIndex,
			currentStageIndex,
			currentInteractionIndex,
			interactionSettings
		} = this.props;

		let questionComponent = <div />;
		let interaction = gameData.master_stages[currentMasterStageIndex].stages[currentStageIndex].puzzles[currentInteractionIndex];

		switch (interactionSettings.type) {
		case 'image selection':
			questionComponent = (
				<ImageSelection
					dataArray={interaction.answer.images}
					{...this.props}
				/>
			);
			break;
		case 'survey':
			questionComponent = (
				<SurveyPanel
					dataArray={interaction.resolutions}
					{...this.props}
				/>
			);
			break;
		default:
			break;
		}

		return (
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100vw' }/*styles.interactionContentContainer*/}>
				<WYSIWYGRenderer
					// htmlStyle={{ maxHeight: '25%', backgroundColor: 'rgba(255, 255, 255, 0.9)',	backgroundSize: 'cover' }}
					html={interactionSettings.content}
					// textStyles={style}
					// centered={false}
					// expandable={true}
					// backgroundImage={false}
					// basicView={true}
					{...this.props}
				/>
				{questionComponent}
				{interactionSettings.userNoteAllowed ? this._renderUserNotes() : <div />}
			</div>
		);
	}

	_renderUserNotes() {
		return (
			<TextField
				placeholder='Enter your notes'
				onChange={userNotesInput => this.props.setUserNoteText(userNotesInput.target.value)}
				value={this.props.userNotesText}
				{...this.props}
			/>
		);
	}

	_renderTextInput() {
		return (
			<TextField
				placeholder='Type your answer'
				onChange={userInput => this.props.setInteractionText(userInput.target.value)}
				value={this.props.interactionTextInput}
				{...this.props}
			/>
		);
	}

	render() {
		const {
			screenDimensions,
			interactionSettings,
			interactionAttempts
		} = this.props;

		return (
			<div style={styles.container}>
				<div style={{ display: 'flex', flex: 1 }}>
					{this._renderInteractionContent()}
				</div>
				{screenDimensions.size !== 'small' ?
					<div style={styles.answerInputContainer}>
						<div style={{ display: 'flex', flex: 1 }}>
							{interactionSettings.showTextInputField ? this._renderTextInput() : <div style={{ display: 'flex', flex: 1 }} />}
						</div>
						{interactionAttempts.showAttempts ? <div style={{ display: 'flex', flex: 1 }}>
							<h1 style={{ display: 'flex', flex: 1 }}>{'ATTEMPTS\nLEFT'}</h1>
							<h1 style={{ display: 'flex', flex: 1 }}>{interactionAttempts.attempts}</h1>
						</div> : <div />}
						<div style={{ display: 'flex', flex: 1 }}>
							<Button
								variant='contained'
								color='primary'
								disabled={this.props.submitLocked}
								onClick={() => {
									if (!this.props.submitLocked) {
										if (!this.props.ignoreSubmitLock) {
											this.props.lockSubmitButton(true);
										}
										this.props.submitButtonPressed();
									}
								}}
							>
								<p>{interactionSettings.submitButtonText}</p>
							</Button>
						</div>
					</div> : <div />}
			</div>
		);
	}
}
