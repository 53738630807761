import React, { Component } from 'react';

import {
	Button,
	Tab,
	Tabs
} from '@material-ui/core';

// custom resources imports
import Styles from '../../lib/Styles';
import WYSIWYGRenderer from '../WYSIWYGRenderer';

// declares local variables
const styles = Styles.components.modalWindow;

export default class ModalWindow extends Component {
	renderHeaderType() {
		switch (this.props.universalModalHeader.headerType) {
		case 'windowed':
			return this.renderTitleBar();
		case 'tabbed':
			return this.renderTabs();
		default:
			return null;
		}
	}

	renderTitleBar() {
		return (
			<div style={styles.windowHeader}>
				<div style={styles.windowTitle}>
					<h2 style={styles.titleFont}>{this.props.title}</h2>
				</div>
				<div style={styles.closeContainer}>
					<Button onClick={() => {
						this.props.closeModalWindow();
					}}>X</Button>
				</div>
			</div>
		);
	}

	renderTabs() {
		const { modalTabLabels } = this.props;

		return (
			<div >
				{this.renderTitleBar()}
				<div style={{ display: 'flex', flex: 1, maxHeight: '50px' }}>
					<Tabs
						value={this.props.modalTabIndex}
						onChange={(event, newValue) => {
							this.props.setModalTab(newValue);
						}}>
						{modalTabLabels.map((tabName) => (
							 <Tab label={tabName} />
						))}
	        </Tabs>
				</div>
			</div>
		);
	}

	renderModalWindowView() {
		const { modalProps } = this.props;

		switch (modalProps.modalType) {
		case 'bespoke':
			return this.renderBespokeContent();
		default:
			return this.renderContentView();
		}
	}


	renderContentView() {
		const { modalProps } = this.props;

		return (
			<WYSIWYGRenderer
				style={{ display: 'flex', flex: 10, border: '1px solid white' }}
				html={modalProps.data.html}
				bgImage={modalProps.data.bg}
				{...this.props}
			/>
		);
	}

	renderBespokeContent() {
		return (
			<div style={styles.contentContainer}>
				{this.props.children}
			</div>
		);
	}

	renderBottomButton() {
		const { modalProps } = this.props;

		return (
			<div style={{ display: 'flex', flex: 1, maxHeight: '40px', justifyContent: 'center', padding: '10px' }}>
				<Button
					variant='contained'
					color='primary'
					onClick={() => {
						this.props.processGameAction();
					}}>{modalProps.data.buttonText}</Button>
			</div>
		);
	}

	render() {
		const { modalProps } = this.props;
		return (
			<div style={styles.container}>
				{this.props.showHeader ? this.renderHeaderType() : <div />}
				{this.renderModalWindowView()}
				{modalProps.data.bottomButton ? this.renderBottomButton() : <div />}
			</div>
		);
	}
}
