import React, { Component } from 'react';

// third party resources imports
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export default class Notification extends Component {
	render() {
		const { showNotification, notificationProps } = this.props;

		return (
			<Snackbar
				key={notificationProps.id}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={showNotification}
				autoHideDuration={6000}
				onClose={() => this.props.closeNotification(notificationProps.id)}
			>
				<Alert
					elevation={6}
					variant='filled'
					onClose={() => this.props.closeNotification(notificationProps.id)}
					severity={notificationProps.severity}>
					{notificationProps.content}
				</Alert>
			</Snackbar>
		);
	}
}
