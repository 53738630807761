import React, { Component } from 'react';

// third party resources imports
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent
} from '@material-ui/core';

export default class ConfirmationModal extends Component {
	render() {
		const { confirmationModalProps } = this.props;
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth='xs'
				open={this.props.showConfirmationModal}
			>
				<DialogTitle>{confirmationModalProps.title}</DialogTitle>
				<DialogContent>
					<p>{confirmationModalProps.content}</p>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						onClick={() => {
							this.props.closeConfirmationModal();
						}}>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							confirmationModalProps.confirmFunc();
							this.props.closeConfirmationModal();
						}}>
						{confirmationModalProps.confirmText}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
