import React, { Component } from 'react';

// third party resources
import { connect } from 'react-redux';

// custom components
import Results from '../components/summary/Results';
import Styles from '../lib/Styles';
import Swiper from '../components/CustomSwiper';

const styles = Styles.scenes.summary;

class Summary extends Component {
	render() {
		const { gameStyles } = this.props;

		return (
			<div style={Object.assign({}, styles.container, { backgroundImage: `url(${gameStyles.background})` })}>
				<h1>Summary</h1>
				<Swiper swiperData={this.props.gameData.pre_summary_screens} lastSlide={<Results xs={6} {...this.props}/>} {...this.props} />
			</div>
		);
	}
}

/*
 * Redux: hooks the state in the default the container, so all the
 * children components can access them as props.
 */
const mapStateToProps = (state) => {
	return {
		gameData: state.gameData,
		gameStyles: state.gameStyles,
		userData: state.userData,
		windowState: state.windowState,
		endResults: state.endResults,
		processedUserData: state.processedUserData,

		// network
		leaderboard: state.leaderboard,
		prevLBData: state.prevLBData,
		mailchimpDetails: state.mailchimpDetails
	};
};

export default connect(mapStateToProps)(Summary);
