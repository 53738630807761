import GLOBAL from '../lib/Globals';
import Addresses from '../lib/Addresses';
import * as userActions from './User';
import * as experienceActions from './Experience';
import * as timerActions from './Timers';
import * as eventActions from './Event';
import * as networkActions from './Network';
import * as uniModalActions from './UniversalModal';

/* action types */
export const INIT_GAME_DATA = 'INIT_GAME_DATA';
export const UPDATE_GAME_STYLES = 'UPDATE_GAME_STYLES';
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';
export const UPDATE_UI_LAYOUT = 'UPDATE_UI_LAYOUT';
export const ASSIGN_REGO_PROPS = 'ASSIGN_REGO_PROPS';
export const SELECT_EDIT_MEMBER = 'SELECT_EDIT_MEMBER';
export const SET_WINDOW_STATE = 'SET_WINDOW_STATE';
export const SET_GAME_OVER = 'SET_GAME_OVER';
export const SET_HISTORY = 'SET_HISTORY';
export const ASSIGN_MASTERSTAGE_BREAKDOWN = 'ASSIGN_MASTERSTAGE_BREAKDOWN';
export const PROCESS_MASTERSTAGE_BREAKDOWN = 'PROCESS_MASTERSTAGE_BREAKDOWN';
export const FORCE_BROWSER_MOVEMENT_BACK = 'FORCE_BROWSER_MOVEMENT_BACK';

/* action creators */

// retrieve game data
export function retrieveGameData(expId) {
	return (dispatch) => {
		fetch(Addresses.REMOTE_URL + expId).then((res) => {
			return res.json();
		}).then((rawGameData) => {
			dispatch(loadGameData(rawGameData));
		}).catch((err) => {
			console.error('fetch error getting game data: ', err);
		});
	};
}

// load gameData
export function loadGameData(rawGameData) {
	return (dispatch) => {
		// check if there is gameData already present in local storage,
		let localGameData = localStorage.getItem('exp_data');

		if (localGameData) {
			localGameData = JSON.parse(localGameData);

			if (localGameData.id == rawGameData.id && localGameData.updated_at == rawGameData.updated_at) {
				dispatch(initilaiseExperience(localGameData));
			} else {
				dispatch(storeGameData(rawGameData));
			}
		} else {
			dispatch(storeGameData(rawGameData));
		}
	};
}

// process game data
export function storeGameData(rawGameData) {
	return (dispatch) => {
		processGameResources(rawGameData).then((gameData) => {
			localStorage.setItem('exp_data', JSON.stringify(gameData));

			dispatch(initilaiseExperience(gameData));
		}).catch((err) => {
			console.error('[ERROR] Issue processing game data: ', err);
		});
	};
}

export function initilaiseExperience(gameData) {
	return (dispatch) => {
		dispatch({
			type: INIT_GAME_DATA,
			gameData: gameData
		});

		dispatch(updateGameStyles());
		dispatch(userActions.initialiseUserData());
		dispatch(eventActions.processExperienceEvents());

		if (gameData.extra_settings.includes('send_emails')) {
			dispatch(networkActions.getMailchimpDetails(gameData.id));
		}
	};
}

async function processGameResources(gameData) {
	for (let key in gameData) {
		if (gameData[key]) {
			let tempValue = null;
			switch (key) {
			case 'extra_settings':
			case 'leaderboard':
			case 'mailchimp':
			case 'intro_screens':
			case 'outro_screens':
			case 'pre_summary_screens':
			case 'success_screen':
			case 'failure_screen':
			case 'help_screen':
			case 'experience_events':
				tempValue = GLOBAL.isValidJSON(gameData[key]) ? JSON.parse(gameData[key]) : gameData[key];
				break;
			case 'master_stages':
				tempValue = await processMasterStages(gameData[key]);
				break;
			case 'theme_settings':
				let themeSettings = GLOBAL.isValidJSON(gameData[key]) ? JSON.parse(gameData[key]) : gameData[key];
				for (let setting in themeSettings) {
					if (setting === 'master_theme_background_image' || setting === 'master_theme_logo_image') {
						// let filename = await this._getFileFromStorage(themeSettings[setting], 'Master theme setting');
						// themeSettings[setting] = filename;
					}
				}
				tempValue = themeSettings;
				break;
			default:
				tempValue = gameData[key];
				break;
			}

			gameData[key] = tempValue;
		} else {
			// defaults
			// Team name defaults
			if (key === 'team_singular' || key === 'team_plural') {
				let teamName = 'Team';
				if (key === 'team_plural') {
					teamName = 'Team\'s';
				}
				gameData[key] = teamName;
			}
		}
	}

	// add test mode variable to gameData object if it doesn't exist
	if (gameData['test_mode'] === undefined) {
		gameData['test_mode'] = false;
	}

	return gameData;
}

async function processMasterStages(masterStageObj) {
	// process master stages
	let tempMasterStages = GLOBAL.isValidJSON(masterStageObj) ? JSON.parse(masterStageObj) : masterStageObj;
	for (let i = 0; i < tempMasterStages.length; i++) {
		// master stage
		if (tempMasterStages[i] === null) {
			tempMasterStages.splice(i, 1);
			i--;
			continue;
		}

		// MS map
		// if (tempMasterStages[i]['master_stage_map_image'] !== '') {
		// 	tempMasterStages[i]['master_stage_map_image'] = await this._getFileFromStorage(tempMasterStages[i]['master_stage_map_image'], 'Master Stage map');
		// }

		// MS instruction screen
		if (tempMasterStages[i]['instruction'] !== null) {
			tempMasterStages[i]['instruction'] = await processWYSIWYGContent(tempMasterStages[i]['instruction']);
		}

		// process success screen
		if (tempMasterStages[i]['success_screen'] !== null) {
			tempMasterStages[i]['success_screen'] = await processWYSIWYGContent(tempMasterStages[i]['success_screen']);
		}

		// process failure screen
		if (tempMasterStages[i]['failure_screen'] !== null) {
			tempMasterStages[i]['failure_screen'] = await processWYSIWYGContent(tempMasterStages[i]['failure_screen']);
		}

		// process stage selection instructions content
		if (tempMasterStages[i]['stage_sel_instructions'] !== null) {
			tempMasterStages[i]['stage_sel_instructions'] = await findAllHTMLMediaInstances(tempMasterStages[i]['stage_sel_instructions']);
		}

		// Master Stage events
		if (tempMasterStages[i].mStgEvents !== undefined) {
			for (let n = 0; n < tempMasterStages[i].mStgEvents.length; n++) {
				// Convert WYSIWYG content for master stage events that have them
				if (tempMasterStages[i].mStgEvents[n].feedback_option === 'pop_up_modal') {
					tempMasterStages[i].mStgEvents[n].feedback_content = await processWYSIWYGContent(tempMasterStages[i].mStgEvents[n].feedback_content);
				}
				// process content for note so it will display
				if (tempMasterStages[i].mStgEvents[n].event_type === 'give_note') {
					tempMasterStages[i].mStgEvents[n].content = await findAllHTMLMediaInstances(tempMasterStages[i].mStgEvents[n].content);
				}
			}
		}

		// MS theme_settings
		// if (tempMasterStages[i]['theme_settings'] !== undefined) {
		// 	let masterStageTheme = tempMasterStages[i]['theme_settings'];

		// 	tempMasterStages[i]['theme_settings']['master_stage_theme_background_image'] = await this._getFileFromStorage(masterStageTheme['master_stage_theme_background_image'], 'Master stage background');
		// 	tempMasterStages[i]['theme_settings']['master_stage_theme_logo_image'] = await this._getFileFromStorage(masterStageTheme['master_stage_theme_logo_image'], 'Master stage logo');
		// }

		// Process stages
		let tempStages = tempMasterStages[i]['stages'];
		for (let j = 0; j < tempStages.length; j++) {
			// stage
			if (tempStages[j] == null) {
				tempStages.splice(j, 1);
				j--;
				continue;
			}

			// process interaction selection instructions content
			tempStages[j]['selection_instructions'] = await findAllHTMLMediaInstances(tempStages[j]['selection_instructions']);

			// process interaction selection instructions content
			tempStages[j]['instructions'] = await processWYSIWYGContent(tempStages[j]['instructions']);

			// process success all screen
			tempStages[j]['success_all_screen'] = await processWYSIWYGContent(tempStages[j]['success_all_screen']);

			// process failure screen/time expired
			tempStages[j]['failure_screen'] = await processWYSIWYGContent(tempStages[j]['failure_screen']);

			// selection image
			// tempStages[j]['selection_image'] = await this._getFileFromStorage(tempStages[j]['selection_image'], 'Stage selection image');

			// selection map
			// tempStages[j]['selection_map'] = await this._getFileFromStorage(tempStages[j]['selection_map'], 'Stage map');

			// stage theme background image
			// tempStages[j]['theme_settings']['stage_theme_background_image'] = await this._getFileFromStorage(tempStages[j]['theme_settings']['stage_theme_background_image'], 'stage backgorund');

			// stage theme logo
			// tempStages[j]['theme_settings']['stage_theme_logo_image'] = await this._getFileFromStorage(tempStages[j]['theme_settings']['stage_theme_logo_image'], 'Stage logo');

			// Stage Events
			if (tempStages[j].stgEvents !== undefined) {
				for (let o = 0; o < tempStages[j].stgEvents.length; o++) {
					// Convert WYSIWYG content for stage events that have them
					if (tempStages[j].stgEvents[o].feedback_option === 'pop_up_modal') {
						tempStages[j].stgEvents[o].feedback_content = await processWYSIWYGContent(tempStages[j].stgEvents[o].feedback_content);
					}
					// process content for note so it will display
					if (tempStages[j].stgEvents[o].event_type === 'give_note') {
						tempStages[j].stgEvents[o].content = await findAllHTMLMediaInstances(tempStages[j].stgEvents[o].content);
					}
				}
			}

			let tempPuzzles = tempStages[j]['puzzles'];
			if (tempPuzzles) {
				for (let k = 0; k < tempPuzzles.length; k++) {
					if (tempPuzzles[k] === null) {
						tempPuzzles.splice(k, 1);
						k--;
						continue;
					}
					// check if there is images array in the puzzle answer
					if (tempPuzzles[k]['answer']['images']) {
						let tempImagesArray = GLOBAL.isValidJSON(tempPuzzles[k]['answer']['images']) ? JSON.parse(tempPuzzles[k]['answer']['images']) : tempPuzzles[k]['answer']['images'];
						// for (let l = 0; l < tempImagesArray.length; l++) {
						// 	tempImagesArray[l] = await this._getFileFromStorage(tempImagesArray[l], 'Interaction image array');
						// }
						tempPuzzles[k]['answer']['images'] = tempImagesArray;
					}

					// selection image
					// tempPuzzles[k]['selection_image'] = await this._getFileFromStorage(tempPuzzles[k]['selection_image'], 'Interaction selection image');

					// content type puzzle
					if (tempPuzzles[k]['type'] === 'content') {
						tempPuzzles[k]['answer']['content'] = await findAllHTMLMediaInstances(tempPuzzles[k]['answer']['content']);
					} else {
						tempPuzzles[k]['question'] = await findAllHTMLMediaInstances(tempPuzzles[k]['question']);
					}
					// Interaction correct feedback_content
					tempPuzzles[k]['correct_feedback'] = await processWYSIWYGContent(tempPuzzles[k]['correct_feedback']);

					// Interaction incorrect feedback
					tempPuzzles[k]['incorrect_feedback'] = await processWYSIWYGContent(tempPuzzles[k]['incorrect_feedback']);
					// hint
					if (tempPuzzles[k]['hints']) {
						let tempHints = GLOBAL.cleanNullsFromArray(tempPuzzles[k]['hints']);
						for (let m = 0; m < tempHints.length; m++) {
							if (tempHints[m]) {
								tempHints[m]['content'] = await findAllHTMLMediaInstances(tempHints[m]['content']);
							}
						}

						tempPuzzles[k]['hints'] = tempHints;
					}

					// interaction resolution
					let tempResolutions = tempPuzzles[k]['resolutions'];
					if (tempResolutions) {
						for (let l = 0; l < tempResolutions.length; l++) {
							if (tempResolutions[l] === null) {
								tempResolutions.splice(l, 1);
								l--;
							}
						}
					}

					// interaction rewards
					let tempRewards = tempPuzzles[k]['rewards'];
					if (Array.isArray(tempRewards)) {
						for (let m = 0; m < tempRewards.length; m++) {
							if (tempRewards[m] !== null && tempRewards[m] !== undefined) {
								if (tempRewards[m]['type'] === 'notes') {
									tempRewards[m]['notes'] = await findAllHTMLMediaInstances(tempRewards[m]['notes']);
								}
							}
						}
						tempPuzzles[k]['rewards'] = tempRewards;
					}
				}
				tempStages[j]['puzzles'] = tempPuzzles;
			}
		}
		tempMasterStages[i]['stages'] = tempStages;
	}

	return tempMasterStages;
}

// find any media components within an HTML string and wrap them in an <a> tag to make a thumbnail
async function processWYSIWYGContent(htmlString) {
	let objToSet = htmlString;

	if (htmlString !== '') {
		if (htmlString.html !== undefined) {
			objToSet.html = await findAllHTMLMediaInstances(htmlString.html);
			objToSet.bg = htmlString.bg;

			return objToSet;
		} else {
			return {
				html: htmlString,
				bg: ''
			};
		}
	} else {
		return '';
	}
}

// find every instance where a html tag img or source is used and process the tags to be put into <a> tag for conversion to thumbnail
async function findAllHTMLMediaInstances(htmlString) {
	return new Promise(async resolve => {
		let regex = /(<img[^>]+>)|(<video(.*?)video>)/g;
		let modifiedHTMLString = htmlString;
		let allMatches = [...htmlString.matchAll(regex)];

		if (allMatches.length === 0) {
			resolve(htmlString);
		}

		for (let i = 0; i < allMatches.length; i++) {
			let modifiedLocalHTMLString = await processHTMLMediaFromString(allMatches[i]['0']);

			modifiedHTMLString = modifiedHTMLString.replace(allMatches[i]['0'], modifiedLocalHTMLString);

			if (i === allMatches.length - 1) {
				resolve(modifiedHTMLString);
			}
		}
	});
}

async function processHTMLMediaFromString(htmlString) {
	let html = htmlString;
	let tagRegex = /<img[^>]+>/g;
	let urlRegex = /<img[^>]+src="(http:\/\/[^">]+)"/g;
	let tagType = 'img';

	// required for files that are not saved under https
	if (html.includes('https')) {
		urlRegex = /<img[^>]+src="(https:\/\/[^">]+)"/g;
	}

	// video html content
	if (html.includes('<video')) {
		tagRegex = /<video(.*?)video>/g;
		urlRegex = /<source[^>]+src="(http:\/\/[^">]+)"/g;
		tagType = 'video';

		// required for files that are not saved under https
		if (html.includes('https')) {
			urlRegex = /<source[^>]+src="(https:\/\/[^">]+)"/g;
		}
	}

	html = await convertToThumbnail(html, tagRegex, urlRegex, tagType);

	return html;
}

async function convertToThumbnail(html, tagRegex, urlRegex, tagType) {
	let tagMatch = tagRegex.exec(html);
	let urlMatch = urlRegex.exec(html);
	let updatedTag = tagMatch[0];
	let htmlMedia = `<a href="${urlMatch[1]}">${updatedTag}</a>`;

	// inject the style property into the media tag
	if (tagType === 'img') {
		let styleStringifyed = ` width="320" height="240" `;	// white spaces in string are deliberate
		updatedTag = updatedTag.replace(/\s/, styleStringifyed);
		htmlMedia = updatedTag;
	}

	let updatedHTML = html.replace(tagRegex, htmlMedia);

	return updatedHTML;
}

export function checkPrevGameData() {
	return (dispatch) => {
		// check if there is pre existing game data
		let currentGameData = localStorage.getItem('exp_data');
		if (currentGameData) {
			currentGameData = JSON.parse(currentGameData);

			// let currentUserDataArray = localStorage.getItem('userdata_array');
			// currentUserDataArray = JSON.parse(currentUserDataArray);

			// for (var i = 0; i < currentUserDataArray.length; i++) {
			// 	if (currentUserDataArray[i].finishTime === 0) {
			// 		if (currentUserDataArray[i].gameID == currentGameData.id) {
			// 		}
			// 	}
			// }
			dispatch({
				type: INIT_GAME_DATA,
				gameData: currentGameData
			});
		}

		// check if there is user data that is current(not completed) that matches the game id of the pre existing experience
			// if there is, prompt the user to enter their session code
				// if the session code matches the user data code, then resume the experience from the last position
				// if it doesn't match, reject the code and inform the user that they need to speak to an admin or something
			// if there isn't, prompt the user to enter their session id
	};
}

/* ---------- Start Theme Styling logic ---------- */
export function updateGameStyles() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let windowState = getState().windowState;

		// non state variables
		let gameTheme = gameData.theme_settings;
		let theme = {};

		switch (windowState) {
		case 'masterStage':
			let masterStageTheme = gameData.master_stages[cMSI].theme_settings;
			theme = processThemeStyle(gameTheme, masterStageTheme);
			break;
		case 'stage':
		case 'interaction':
			let stageTheme = gameData.master_stages[cMSI].stages[cSI].theme_settings;
			masterStageTheme = gameData.master_stages[cMSI].theme_settings;
			theme = processThemeStyle(gameTheme, masterStageTheme, stageTheme);
			break;
		default:
			theme = processThemeStyle(gameTheme);
			break;
		}

		dispatch({
			type: UPDATE_GAME_STYLES,
			gameStyles: theme
		});
	};
}

function processThemeStyle(gameTheme, masterStageTheme, stageTheme) {
	// h1 base = 40px, h2 base = 30px, p base = 20px
	let DefaultThemeStyle = function () {
		return {
			background: 'https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350',
			logo: '',
			primary: {
				color: 'blue',
				fontFamily: 'Roboto',
				fontSize: '40px'
				// fontSize: parseInt(wp('3.91%')),
			},
			secondary: {
				color: 'blue',
				fontFamily: 'Roboto',
				fontSize: '30px'
				// fontSize: parseInt(wp('2.93%')),
			},
			tertiary: {
				color: 'blue',
				fontFamily: 'Roboto',
				fontSize: '20px'
				// fontSize: parseInt(wp('1.95%')),
			}
		};
	};
	let defaultThemeStyle = new DefaultThemeStyle(); // we use this as a measure to determine whether we need to change the values or keep the previous one
	let themeStyle = new DefaultThemeStyle();
	let themeArray = [];

	themeArray.push(gameTheme);

	if (masterStageTheme !== undefined) {
		themeArray.push(masterStageTheme);
	}

	if (stageTheme !== undefined) {
		themeArray.push(stageTheme);
	}

	for (let i = 0; i < themeArray.length; i++) {
		let theme = themeArray[i];
		for (let key in theme) {
			if (theme[key] !== null && theme[key] !== '') {
				let keyCheck = key; // required for background and logo as they're nested within their own objects
				if (keyCheck.includes('background')) {
					keyCheck = 'background';
				}

				if (keyCheck.includes('logo')) {
					keyCheck = 'logo';
				}

				switch (keyCheck) {
				case 'background':
					themeStyle.background = 'https://app-admin.doextraordinary.com/' + theme[key];
					break;
				case 'logo':
					themeStyle.logo = 'https://app-admin.doextraordinary.com/' + theme[key];
					break;
				case 'primary_color':
					themeStyle.primary['color'] = theme[key];
					break;
				case 'primary_font_family':
					themeStyle.primary['fontFamily'] = theme[key];
					break;
				case 'primary_font_size':
					if (theme[key] + '%' !== defaultThemeStyle.primary.fontSize) {
						themeStyle.primary['fontSize'] = convertFromAppPercentage(theme[key], 40);
					}
					break;
				case 'secondary_color':
					themeStyle.secondary.color = theme[key];
					break;
				case 'secondary_font_family':
					themeStyle.secondary.fontFamily = theme[key];
					break;
				case 'secondary_font_size':
					if (theme[key] + '%' !== defaultThemeStyle.secondary.fontSize) {
						themeStyle.secondary.fontSize = convertFromAppPercentage(theme[key], 30);
					}
					break;
				case 'tertiary_color':
					themeStyle.tertiary.color = theme[key];
					break;
				case 'tertiary_font_family':
					themeStyle.tertiary.fontFamily = theme[key];
					break;
				case 'tertiary_font_size':
					if (theme[key] + '%' !== defaultThemeStyle.tertiary.fontSize) {
						themeStyle.tertiary.fontSize = convertFromAppPercentage(theme[key], 20);
					}
					break;
				default:
					break;
				}
			}
		}
	}

	return themeStyle;
}

function convertFromAppPercentage(appPercentage, defaultValue) {
	let fontValue = defaultValue;
	let baseWidth = 1024;
	let size = (baseWidth * appPercentage) / 100;

	if (size !== defaultValue) {
		let adjustmentValue = size - defaultValue;
		fontValue = (adjustmentValue / defaultValue) * 100;
	}

	return fontValue;
}

export function initialiseScreenDimensions() {
	return (dispatch) => {
		window.addEventListener('resize', () => dispatch(updateScreenDimensions()));
	};
}

export function clearScreenDimensionListener() {
	return (dispatch) => {
		window.removeEventListener('resize', () => dispatch(updateScreenDimensions()));
	};
}

export function updateScreenDimensions() {
	return (dispatch) => {
		// non state variables
		let windowWidth = typeof window !== undefined ? window.innerWidth : 0;
		let windowHeight = typeof window !== undefined ? window.innerHeight : 0;

		dispatch({
			type: UPDATE_DIMENSIONS,
			height: windowHeight,
			width: windowWidth
		});
	};
}

export function updateUILayout() {
	return (dispatch) => {
		// components that would need to update/change

		dispatch({
			type: UPDATE_UI_LAYOUT
		});
	};
}
/* ---------- End Theme Styling logic ---------- */

// determine what next action needs to happen based on extra settings
export function progressPreExperience() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let windowState = getState().windowState;
		let history = getState().historyState;

		switch (windowState) {
		case 'introduction':
			if (gameData.extra_settings.includes('skip_registration')) {
				dispatch(setWindowState('outroduction'));
			} else {
				dispatch(assignRegoProps());
				dispatch(setWindowState('registration'));
			}
			break;
		case 'registration':
			dispatch(setWindowState('outroduction'));
			break;
		default:
			// loading
			if (gameData.intro_screens.length > 0) {
				dispatch(setWindowState('introduction'));
			} else if (gameData.extra_settings.includes('skip_registration')) {
				dispatch(setWindowState('outroduction'));
			} else {
				dispatch(assignRegoProps());
				dispatch(setWindowState('registration'));
			}
			break;
		}

		history.push('/preexperience');
	};
}

export function assignRegoProps() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let regoMemberInputState = getState().registrationProps.regoMemberInputState;

		// non state variables
		let teamPronoun = gameData.team_singular !== '' ? gameData.team_singular : 'Team';
		let memberRegoTitle = (gameData.extra_settings.includes('single_person_rego') ? 'Enter your details' : (regoMemberInputState === 'create' ? 'Add a ' : 'Edit a ') + teamPronoun + ' Member');

		dispatch({
			type: ASSIGN_REGO_PROPS,
			teamNameRego: teamPronoun,
			memberRego: memberRegoTitle
		});
	};
}

export function updateRegoMember() {
	return {
		type: SELECT_EDIT_MEMBER
	};
}

export function startGame() {
	return (dispatch, getState) => {
		let history = getState().historyState;
		let gameData = getState().gameData;
		let leaderboard = getState().leaderboard;

		let mStgIndex = 0;
		let stgIndex = null;
		let interactionIndex = null;

		if (gameData.master_stages[0].structure === 'Linear') {
			stgIndex = 0;

			if (gameData.master_stages[0].stages[0].structure === 'Linear') {
				interactionIndex = 0;
			}
		}

		dispatch(userActions.setStartTime()); // logs the time the user presses start and saves it to user data
		dispatch(timerActions.startGameTimer());

		if (gameData.map.length > 0) {
			dispatch(experienceActions.assignGameMap());
		}

		dispatch(experienceActions.setUserLocation(mStgIndex, stgIndex, interactionIndex));
		if (gameData.extra_settings.includes('send_to_leaderboard') && gameData.leaderboard.frequent_update && !leaderboard.frequentUpdate) {
			dispatch(networkActions.updateLeaderboardFrequently(true));
		}

		history.push('/experience');
	};
}

export function goToSummary() {
	return (dispatch, getState) => {
		let history = getState().historyState;

		dispatch({
			type: SET_GAME_OVER,
			gameOver: true
		});

		dispatch(progressSummary());
		history.push('/summary');
		dispatch(userActions.processResults());
		dispatch(eventActions.resetAllEmitters());
		dispatch(networkActions.prepExportDataObjs());

		// close the previous modal
		// if (getState().showModal) {
		//   dispatch(uniModalActions.closeModal());
		// }
	};
}

export function progressSummary() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		if (getState().windowState !== 'pre_results' && (gameData.pre_summary_screens !== null || gameData.pre_summary_screens.length > 0)) {
			dispatch(setWindowState('pre_results'));
		} else {
			if (gameData.summary_pin_code.length === 0) {
				dispatch(setWindowState('results'));
			} else {
				// dispatch(uniModalActions.openModal('keypad_locked_end'));
				dispatch(setWindowState('results'));
			}
		}
	};
}

export function setWindowState(windowState) {
	return (dispatch) => {
		dispatch({
			type: SET_WINDOW_STATE,
			windowState: windowState
		});

		dispatch(updateGameStyles());
	};
}

export function setHistoryState(history) {
	return {
		type: SET_HISTORY,
		history: history
	};
}

export function assignMasterStageBreakdown() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		// non state variables
		let masterStageSelection = [];

		for (let i = 0; i < gameData.master_stages.length; i++) {
			masterStageSelection.push({
				id: i + '_Section',
				title: gameData.master_stages[i].title
			});
		}

		dispatch({
			type: ASSIGN_MASTERSTAGE_BREAKDOWN,
			masterStageSelections: masterStageSelection
		});

		dispatch(processMasterStageBreakdown('0_Section'));
	};
}

// should be called after processedResults
export function processMasterStageBreakdown(selectedIndex) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;

		// non state variables
		let mSI = parseInt(selectedIndex.replace('_Section', ''));
		let stages = gameData.master_stages[mSI].stages;
		let result = [];
		let stageStickyHeaderIndices = [];

		// Each iteration represents a single stage.
		for (let i = 0; i < stages.length; i++) {
			let completedAnswersCount = 0;
			let interactionArray = userData.master_stage[mSI].stage[i].puzzle; // array of interactions
			let stageResults = [];

			for (let j = 0; j < interactionArray.length; j++) {
				if (interactionArray[j].completed) {
					completedAnswersCount++;
				}

				// handles setting up how many hints the user has purchased
				let usedHints = 0;

				// looks for purchased hints
				for (let k = 0; k < interactionArray[j].puzzleHints.length; k++) {
					if (interactionArray[j].puzzleHints[k].purchased === true) {
						usedHints++;
					}
				}

				let interactionPoints = (gameData.points_preface !== '' ? gameData.points_preface : '') + interactionArray[j].puzzlePoints + (gameData.points_plural !== '' ? ' ' + gameData.points_plural : ' points');
				let hintStats = usedHints + '/' + interactionArray[j].puzzleHints.length + ' used';

				// Content under header
				stageResults.push({
					id: i + '_' + j + '_Content',
					interactionTitle: (interactionArray[j].puzzleName ? interactionArray[j].puzzleName : 'Interaction'),
					interactionPoints: interactionPoints,
					displayTime: GLOBAL.formatTimeHHMMSS(interactionArray[j].timeSpent),
					hintStats: hintStats
				});
			}

			// Sticky header obj
			stageStickyHeaderIndices.push(result.length);
			result.push({
				id: i + '_Sticky',
				stageTitle: (stages[i].display_title ? stages[i].display_title : stages[i].title) + ':',
				interactionsCompleted: (completedAnswersCount + '/' + stages[i].puzzles.length + ' completed')
			});

			result.push({
				id: i + '_Content',
				results: stageResults
			});
		}

		dispatch({
			type: PROCESS_MASTERSTAGE_BREAKDOWN,
			activeBreakdownWindow: selectedIndex,
			masterStageBreakdown: result,
			stageStickyHeaderIndices: stageStickyHeaderIndices
		});
	};
}

export function processBrowserBackButtonPush() {
	return (dispatch, getState) => {
		let history = getState().historyState;
		let windowState = getState().windowState;

		switch (window.location.pathname) {
		case '/preexperience':

			history.go(1);
			if (windowState === 'master_stage') {
				let title = 'Not allowed';
				let content = 'You\'re attempting to move out of the experience\nThis is not an allowed action';
				let confirmText = 'Ok';
				let confirmFunc = () => {
					dispatch(uniModalActions.closeConfirmationModal());
				};

				dispatch(uniModalActions.assignConfirmationModalProps(title, content, confirmText, confirmFunc));
			} else {
				dispatch(experienceActions.backButtonPressed());
			}
			break;
		case '/experience':
			history.go(1);
			break;
		default:
			break;
		}
	};
}

export function forceBrowserMovementBack() {
	return {
		type: FORCE_BROWSER_MOVEMENT_BACK
	};
}
