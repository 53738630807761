// wp and hp need to be converted into dimensions recognised by react/css

module.exports = {
	components: {
		button: {
			verticalButton: {
				flex: 1,
				justifyContent: 'center',
				alignContent: 'center'
			},
			horizontalButton: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-around',
				alignItems: 'center',
				borderRadius: 4
			},
			shadowContainer: {
				shadowColor: 'black',
				shadowOffset: {
					width: 0,
					height: 3
				},
				shadowOpacity: 0.2,
				shadowRadius: 4.65,
				elevation: 6
			},
			dropShadowContainer: {
				alignSelf: 'stretch',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				borderRadius: 3
			},
			iconContainer: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			},
			iconOutlineContainer: {
				flex: 1,
				justifyContent: 'center',
				alignContent: 'center',
				backgroundColor: 'rgba(0, 0, 0, 0.25)',
				// borderRadius: wp('19.53%')
			},
			icon: {
				flex: 1,
				// fontSize: wp('3%'),
				textAlign: 'center',
				textAlignVertical: 'center'
			},
			flipIcon: {
				flex: 1,
				// fontSize: wp('3%'),
				textAlign: 'center',
				transform: [{ scaleX: -1 }]
			},
			defaultFont: {
				// fontSize: wp('1.95%'),
				textAlign: 'center',
				textAlignVertical: 'center',
				color: 'white'
			}
		},
		floatingButtonContainer: {
			floatingButtonPosition: {
				flex: 1,
				position: 'absolute',
				// top: hp('20.18%'),
				// width: wp('100%'),
			},
			container: {
				flex: 1,
				flexDirection: 'row',
			},
			leftBtnsContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'flex-start',
				// marginLeft: wp('1.95%'),
			},
			rightBtnsContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			floatingButtonObject: {
				// width: wp('6.35%'),
				// height: wp('6.35%'),
				alignItems: 'center',
				// marginRight: wp('0.98%'),
			},
			floatingButtonContent: {
				flex: 1,
				flexDirection: 'column',
				// width: wp('6.35%'),
				// height: wp('6.35%'),
				// borderRadius: wp('9.77%'),
				// paddingVertical: hp('1.56%'),
			},
			floatingButtonFontStyle: {
				// fontSize: wp('1.27%'),
				fontWeight: 'bold',
				color: 'white',
			},
			floatingButtonIconStyle: {
				// fontSize: wp('2.64%'),
				color: 'white',
			},
		},
		accordionView: {
			container: {
				flex: 1,
			},
			rowButton: {
				// flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-between',
				// minHeight: hp('3.91%'),
				// minWidth: wp('1.95%'),
				// maxWidth: wp('20%'),
				alignItems: 'center',
				// paddingHorizontal: wp('1.95%'),
			},
			buttonTitle: {
				// fontSize: wp('1.37%'),
				color: 'white',
			},
			childRow: {
				justifyContent: 'space-between',
			},
			parentHr: {
				height: 1,
				width: '100%',
			},
			childHr: {
				height: 1,
				backgroundColor: 'lightgrey',
				width: '100%',
			},
			iconStyle: {
				// fontSize: wp('2.93%'),
				color: 'white',
			},
		},
		swiperScreen: { // global style used by Introduction, Outroduction and PreResults
			container: {
				display: 'flex',
				flex: 1
			},
			topBarContainer: {
				flex: 1,
				// paddingVertical: hp('1.3%'),
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
			},
			topBarLeftContainer: {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
			},
			topBarRightContainer: {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
			},
			topBarCenterContainer: {
				flex: 10,
				// padding: wp('1.95%'),
				justifyContent: 'center',
			},
			topBarButton: {
				// minWidth: wp('4.88%'),
				// minHeight: hp('6.51%'),
			},
			title: {
				// fontSize: wp('2.9%'),
				textAlign: 'center',
			},
			mainContentContainer: {
				flex: 10,
				flexDirection: 'row',
				zIndex: 2,
			},
			mainLeftContainer: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			contentContainer: {
				flex: 10,
				alignItems: 'center',
				justifyContent: 'center',
			},
			swiperContainer: {
				// width: wp('70%'),
				// height: hp('80%'),
			},
			dotHidden: {
				backgroundColor: 'rgba(200, 200, 200, 0)',
			},
			mainRightContainer: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			swiperButtonDimensions: {
				// width: wp('3.91%'),
				// height: hp('7.81%'),
			},
			swiperButtonIcon: {
				// fontSize: wp('3.91%'),
				color: 'white',
			},
			progressButtonContainer: {
				flex: 1,
				position: 'absolute',
				bottom: -6,
			},
			progressButtonSize: {
				flex: 1,
				// width: wp('23.44%'),
				// height: hp('10.42%'),
				justifyContent: 'center'
			},
			bottomContainer: {
				flex: 1,
				justifyContent: 'center'
			}
		},
		individualRegistration: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'stretch',
				padding: 10,
				borderRadius: 5
			},
			title: {
				// fontSize: wp('2.9%'),
				textAlign: 'center'
			},
			regoRowContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				// flexDirection: 'row', <- use this for sizes over 480 px
				justifyContent: 'center',
				padding: 4
			},
			regoTextField: {
				display: 'flex',
				flex: 1,
				padding: 8
			},
			regoAgePickerParent: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: 8
			},
			regoAgePickerTitleContainer: {
				display: 'flex',
				flex: 1
			},
			regoAgePickerSelectParentContainer: {
				display: 'flex',
				flex: 3
			},
			regoAgePickerSelectContainer: {
				display: 'flex',
				flex: 1,
				alignItems: 'stretch'
			},
			regoGenderContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center'
			},
			regoGenderRadioContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
				// flexDirection: 'row' <- use this for sizes over 480 px
			},
			regoButtonContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-around'
			},
			disclaimer: {
				fontSize: 10,
				textAlign: 'center'
			}
		},
		customSwiper: {
			container: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			},
			swiperSideContainer: {
				display: 'flex',
			  flex: 1,
			  maxWidth: '5vw',
			  justifyContent: 'center',
				alignItems: 'center',
			  margin: '10px'
			},
			swiperCenterContainer: {
				display: 'flex',
			  flex: 1,
			  flexDirection: 'column',
			  justifyContent: 'center',
			  alignItems: 'center'
			},
			swiperContainer: {
			  display: 'flex',
			  flex: 5,
			  border: '5px solid white',
				borderRadius: 5
			},
			wysiwygContainer: {
				display: 'flex',
				flex: 1
			},
			arrowIcon: {
				color: 'white',
				fontSize: 40
			}
		},
		pagination: {
			root: {
		    display: 'flex',
		    flex: 1,
		    flexDirection: 'row',
		    margin: 5
	  	},
	  	dot: {
		    backgroundColor: '#e4e6e7',
		    height: 12,
		    width: 12,
		    borderRadius: 6,
		    margin: 3
		  }
		},
		logViewer: {
			container: {
				flex: 1
			},
			logListItemContainer: {
				flex: 1
				// height: hp('10.42%'),
			},
			logListItem: {
				flex: 1,
				backgroundColor: 'transparent',
				borderColor: '#dddddd',
				borderBottomWidth: 1,
				alignItems: 'flex-start',
				justifyContent: 'center',
				// marginHorizontal: wp('2.6%'),
			},
			logTitleFont: {
				fontWeight: 'bold',
				// fontSize: wp('1.76%'),
			},
			centerItems: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			closeContainer: {
				flex: 1,
				flexDirection: 'row',
				alignContent: 'center',
				// paddingRight: wp('0.98%'),
			},
			exitButton: {
				flex: 1,
				flexDirection: 'row',
				alignSelf: 'flex-end',
				// paddingRight: wp('0.98%'),
				// height: hp('5.21%'),
			},
			windowTitle: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'flex-start',
				// paddingLeft: wp('1.95%'),
			},
			titleFont: {
				// fontSize: wp('2.4%'),
				fontWeight: 'bold',
			},
			windowHeader: {
				flex: 1,
				flexDirection: 'row',
				backgroundColor: '#dddddd',
				borderRadius: 5,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
			windowContent: {
				flex: 10,
				flexDirection: 'row',
			},
			logList: {
				flex: 1,
				backgroundColor: 'white',
				borderBottomLeftRadius: 5,
			},
			emptyLogListView: {
				flex: 1,
				// height: hp('6.51%'),
				justifyContent: 'center',
				alignItems: 'center',
				borderBottomLeftRadius: 5,
				borderBottomRightRadius: 5,
			},
			logContentDisplay: {
				flex: 2,
				backgroundColor: 'white',
				borderBottomRightRadius: 5,
			},
			logContentBorderRight: {
				flex: 1,
				borderLeftWidth: 2,
				borderColor: '#dddddd'
				// padding: wp('1.95%'),
			},
			logListOrderBar: {
				backgroundColor: '#dddddd',
				// height: hp('5.21%'),
				borderWidth: 2
			},
			logListOrderButtons: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center'
				// marginHorizontal: wp('0.98%'),
			}
		},
		listDetailsWindow: {
			container: {
				flex: 1
			},
			listItemContainer: {
				flex: 1
				// height: hp('10.42%'),
			},
			listItem: {
				flex: 1,
				backgroundColor: 'transparent',
				borderColor: '#dddddd',
				borderBottomWidth: 1,
				alignItems: 'flex-start',
				justifyContent: 'center'
				// marginHorizontal: wp('1.95%'),
			},
			listTitleFont: {
				fontWeight: 'bold'
				// fontSize: wp('2%'),
			},
			centerItems: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			},
			exitButton: {
				flex: 1,
				flexDirection: 'row',
				alignSelf: 'flex-end'
				// paddingRight: wp('0.98%'),
				// height: hp('6.51%'),
			},
			windowTitle: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'flex-start'
				// paddingLeft: wp('1.95%'),
			},
			titleFont: {
				// fontSize: wp('2.4%'),
				fontWeight: 'bold'
			},
			windowHeader: {
				flex: 1,
				flexDirection: 'row',
				backgroundColor: '#dddddd',
				borderRadius: 5,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0
			},
			windowContent: {
				flex: 10,
				flexDirection: 'row'
			},
			dataList: {
				flex: 1,
				backgroundColor: 'white',
				borderBottomLeftRadius: 5
			},
			emptyDataListView: {
				flex: 1,
				// height: hp('6.51%'),
				justifyContent: 'center',
				alignItems: 'center',
				borderBottomLeftRadius: 5,
				borderBottomRightRadius: 5
			},
			dataContentDisplay: {
				flex: 2,
				backgroundColor: 'white',
				borderBottomRightRadius: 5
			},
			dataContentBorderRight: {
				flex: 1,
				borderLeftWidth: 2,
				borderColor: '#dddddd'
				// padding: wp('1.95%'),
			},
			dataListItemContainer: {
				flex: 1
				// height: hp('10.42%'),
			},
			dataListOrderBar: {
				backgroundColor: '#dddddd',
				// height: hp('5.21%'),
				borderWidth: 2
			},
			dataListOrderButtons: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center'
				// marginHorizontal: wp('0.98%'),
			},
			memberText: {
				flex: 1,
				textAlign: 'left',
				// fontSize: wp('2.78%'),
				color: 'grey'
			},
			beaconButtonHeader: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottomWidth: 1,
				backgroundColor: 'transparent',
				margin: 4
			},
			beaconButton: {
				flex: 1,
				flexDirection: 'row',
				padding: 4
				// height: hp('5.21%'),
			},
			beaconRow: {
				flex: 1,
				flexDirection: 'row',
				alignItems: 'flex-start'
			},
			beaconText: {
				// fontSize: wp('2%'),
			}
		},
		primaryTimer: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column-reverse',
				alignItems: 'center',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				padding: '0px 4px'
			},
			timerContainer: {
				display: 'flex',
				flex: 1
			},
			titleContainer: {
				display: 'flex',
				flex: 1
			},
			titleText: {
				display: 'flex',
				flex: 1,
				flexWrap: 'wrap',
				fontSize: 12,
				color: 'white',
				margin: '0px'
			},
			timerText: {
				color: 'white',
				fontSize: 20,
				margin: '0px'
			}
		},
		secondaryTimer: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				backgroundColor: 'rgba(0, 0, 0, 0)'
			},
			timerContainer: {
				display: 'flex',
				flex: 2,
				justifyContent: 'flex-end'
			},
			titleContainer: {
				display: 'flex',
				flex: 1,
				justifyContent: 'flex-end'
			},
			titleText: {
				display: 'flex',
				flex: 1,
				flexWrap: 'wrap',
				textAlign: 'right',
				fontSize: 10,
				color: 'white',
				margin: '0px'
			},
			timerText: {
				color: 'white',
				fontSize: 16,
				margin: '0px'
			}
		},
		// expandedPuzzleModal: {
		// 	modalSize: {
		// 		// height: hp('86.98%'),
		// 		// width: wp('80%'),
		// 		borderRadius: 5
		// 	},
		// 	body: {
		// 		flex: 1,
		// 	},
		// 	header: {
		// 		flex: 1.1,
		// 		justifyContent: 'space-between',
		// 		alignItems: 'center',
		// 		flexDirection: 'row',
		// 		// paddingHorizontal: wp('4.88%'),
		// 		// paddingVertical: hp('2.6%'),
		// 	},
		// 	headerTitle: {
		// 		// fontSize: wp('2.9%'),
		// 		color: 'black',
		// 	},
		// 	content: {
		// 		flex: 5,
		// 		// paddingHorizontal: wp('4.88%'),
		// 		// paddingVertical: hp('2.6%'),
		// 	},
		// 	closeIcon: {
		// 		transform: [{scaleX: -1}],
		// 		fontWeight: 'bold',
		// 	},
		// 	closeText: {
		// 		fontWeight: 'bold',
		// 		// fontSize: wp('5.9%'),
		// 	},
		// },
		// imagePairing: {
		// 	body: {
		// 		flex: 1,
		// 	},
		// },
		floatingButtonPanel: {
			container: {
				display: 'flex',
				flex: 1,
				padding: '10px',
				position: 'relative'
			},
			floatingButtonItem: {
				padding: '0px',
				borderRadius: '100px',
				color: 'white',
				height: '50px',
				margin: '0px 5px'
			},
			floatingButtonIconTextContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				position: 'relative',
				justifyContent: 'flex-end',
				alignItems: 'center',
				height: '50px'
			},
			floatingButtonText: {
				fontSize: '12px'
			}
		},
		selectionPanel: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			gridContainer: {
				display: 'flex',
				flex: 1,
				width: '100vw',
				padding: '10px'
			},
			gridItemContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '10px'
			},
			gridItemImageContainer: {
				display: 'flex',
				flex: 3,
				position: 'relative'
			},
			gridItemImage: {
				width: '240px',
				height: '160px',
				border: '1px solid white',
				borderRadius: '20px'
			},
			gridItemDescription: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			},
			iconContainer: {
				position: 'absolute',
				width: '40px',
				height: '40px',
				right: -5,
				top: -5,
				padding: '4px',
				border: '1px solid white',
				borderRadius: '100px'
			}
		},
		interactionSelectionPanel: {
			container: {
				flex: 1,
				// paddingHorizontal: wp('1.37%'),
			},
			puzzleHeading: {
				// fontSize: wp('2.9%'),
			},
			puzzleDescription: {
				color: '#333',
			},
			row: {
				flexDirection: 'row',
			},
			puzzleTileContainer: {
				flex: 1,
				// paddingHorizontal: wp('0.98%'),
				// paddingBottom: hp('1.3%'),
			},
			puzzleImageContainer: {
				flex: 1,
			},
			puzzleImage: {
				// height: hp('22%'),
			},
			interactionImageStyle: {
				borderRadius: 10,
				resizeMode: 'contain',
			},
			puzzleDetailContainer: {
				flexDirection: 'row',
			},
			puzzleTitle: {
				flex: 1,
				textAlign: 'center',
				color: '#333',
			},
			puzzlePoints: {
				flex: 1,
				textAlign: 'right',
				color: '#333',
			},
			iconContainer: {
				position: 'absolute',
				// width: wp('5.86%'),
				// height: wp('5.86%'),
				// right: wp('0.59%'),
				// top: hp('0.78%'),
			},
			iconContainerFront: {
				flex: 1,
				// width: wp('5.86%'),
				// height: wp('5.86%'),
				// marginHorizontal: wp('0.2%'),
				// marginVertical: hp('0.26%'),
				justifyContent: 'center',
				alignContent: 'center',
				borderWidth: 2,
				// borderRadius: wp('2.93%'),
				borderColor: 'white',
			},
			icon: {
				color: '#fff',
			},
		},
		gameControlPanel: {
			container: {
				flex: 1,
				// paddingHorizontal: wp('1.95%'),
				// paddingVertical: hp('1.3%'),
				justifyContent: 'space-between',
				backgroundColor: 'rgba(246, 246, 246, 1)',
			},
			floatingButtonBuffer: {
				flex: 0.25,
				// marginBottom: hp('0.52%'),
			},
			pointsContainer: {
				flex: 1,
				justifyContent: 'center',
				// paddingTop: hp('0.52%'),
				borderBottomWidth: 1,
				borderColor: 'black',
				// marginVertical: hp('2.52%'),
			},
			buttonContainer: {
				flex: 3,
				justifyContent: 'flex-start',
			},
			buttonDimensions: {
				// height: hp('10.42%'),
			},
			teamPhotoContainer: {
				flex: 2,
			},
			teamPhoto: {
				flex: 1,
				resizeMode: 'cover',
			},
			teamPhotoTextField: {
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				// height: hp('3.91%'),
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(0,0,0,0.5)',
			},
			teamTextField: {
				// height: hp('3.91%'),
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(0,0,0,0.5)',
			},
			teamPhotoText: {
				color: 'white',
				textAlign: 'center',
			},
			stageTimerContainer: {
				flexDirection: 'row',
				alignItems: 'flex-end',
			},
			textContainer: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			text: {
				color: '#333',
				// fontSize: wp('1.6%'),
			},
		},
		hintsModal: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			hintsTitleContainer: {
				display: 'flex',
				flex: 1,
				maxHeight: '40px',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '4px 10px'
			},
			hintsTitle: {
				display: 'flex',
				flex: 1,
				color: 'black',
				fontSize: '16px',
				fontWeight: 'bold',
				margin: 2,
				padding: 0
			},
			hintsItem: {
				padding: '4px 10px'
			},
			hintsItemText: {
				display: 'flex',
				flex: 1,
				margin: 2,
				padding: 0
			},
			hintsItemButton: {
				borderRadius: '100px',
				width: '40px',
				height: '40px'
			},
			hintsItemIcon: {
				fontSize: '30px',
				color: 'white'
			}
		},
		notesModal: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				overflow: 'hidden'
			},
			topContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				maxHeight: '40px',
				padding: '4px 10px',
				borderBottom: '1px solid darkgrey'
			},
			notesContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row'
			},
			listContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				borderRight: '1px solid darkgrey',
				maxHeight: '100%',
				overflowY: 'scroll'
			},
			contentContainer: {
				display: 'flex',
				flex: 2
			},
			notesItem: {
				padding: '4px 10px'
			},
			notesItemText: {
				display: 'flex',
				flex: 1,
				margin: 2,
				padding: 0
			},
			noteCreationContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			newNoteTitleContainer: {
				display: 'flex',
				flex: 1,
				padding: '0px 10px'
			},
			newNoteTitleInput: {
				display: 'flex',
				flex: 1
			},
			newNoteContentInputContainer: {
				display: 'flex',
				flex: 3,
				padding: '0px 10px'
			},
			newNoteContentInput: {
				display: 'flex',
				flex: 1
			},
			newNoteButtonContainer: {
				display: 'flex',
				flex: 1,
				maxHeight: '40px',
				justifyContent: 'space-between',
				padding: '4px 10px',
				borderTop: '1px solid darkgrey'
			}
		},
		confirmationModal: {
			container: {
				flex: 1,
				// padding: wp('1.95%'),
			},
			headerContainer: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			headerText: {
				// fontSize: wp('2.6%'),
				fontWeight: 'bold',
				textAlign: 'center',
				color: 'black',
			},
			contentContainer: {
				flex: 5,
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				// paddingVertical: hp('2.6%'),
			},
			contentTextItem: {
				// fontSize: wp('2.4%'),
				fontWeight: 'bold',
				textAlign: 'left',
			},
			footerContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			confirmationButton: {
				flex: 1,
				// height: hp('10.42%'),
				// marginHorizontal: wp('0.49%'),
			},
			buttonStyle: {
				// fontSize: wp('2%'),
				color: 'white',
				textAlign: 'center',
			},
		},
		defaultExperiencePromptModal: {
			container: {
				flex: 1,
				// paddingHorizontal: wp('1.95%'),
				// paddingVertical: wp('1.30%'),
			},
			headerContainer: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			headerText: {
				// fontSize: wp('2.34%'),
				textAlign: 'center',
				color: 'black',
			},
			contentContainer: {
				flex: 2,
				justifyContent: 'center',
				alignItems: 'center',
			},
			contentFontStyling: {
				// fontSize: wp('2.73%'),
				fontWeight: 'bold',
				textAlign: 'center',
			},
			footerContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			},
			confirmationButton: {
				// height: hp('7.81%'),
				// width: wp('19.53%'),
				// marginHorizontal: wp('0.59%'),
			},
			buttonStyle: {
				// fontSize: wp('2%'),
				color: 'white',
				textAlign: 'center',
			},
			progressBarContainer: {
				flex: 2,
			},
			progressBar: {
				flex: 1,
			},
		},
		mapModal: {
			container: {
				flex: 1,
			},
			tabsContainer: {
				flex: 1,
			},
			tabView: {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
				// padding: wp('1.95%'),
			},
			photoView: {
				flex: 1,
				alignSelf: 'stretch',
			},
		},
		leaderboardModal: {
			container: {
				flex: 1,
			},
			topContainer: {
				flex: 1,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				// paddingLeft: wp('1.46%'),
				// paddingRight: wp('1.46%'),
			},
			bottomContainer: {
				flex: 5,
			},
			topTitle: {
				// fontSize: wp('2.9%'),
			},
			tableHeadRow: {
				flexDirection: 'row',
				// paddingLeft: wp('1.46%'),
				// paddingRight: wp('1.46%'),
			},
			tableHeading: {
				flex: 1,
				color: '#333',
				textAlign: 'center',
				textAlignVertical: 'center',
			},
			doubleLineBreak: {
				// marginLeft: wp('1.46%'),
				// marginRight: wp('1.46%'),
				height: 5,
				borderTopWidth: 1,
				borderBottomWidth: 1,
				borderColor: '#999',
			},
			tableText: {
				flex: 1,
				color: '#333',
				textAlign: 'center',
				textAlignVertical: 'center',
			},
		},
		instructionsModal: {
			container: {
				// width: wp('70%'),
				// height: hp('70%'),
				borderRadius: 5,
				backgroundColor: 'rgba(255,255,255,0.9)',
			},
		},
		infoModal: {
			container: {
				flex: 1,
			},
			text: {
				color: '#333',
			},
		},
		devConsole: {
			modalContainer: {
				// width: wp('60%'),
				// height: hp('20%'),
				// top: hp('1.3%'),
				// left: wp('0.98%'),
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
			modalContainerTall: {
				// height: hp('60%'),
			},
			container: {
				flex: 1,
				// paddingHorizontal: wp('0.39%'),
			},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				borderBottomWidth: 0.5,
				borderColor: 'lawngreen',
			},
			flatlist: {
				// paddingBottom: hp('0.52%'),
			},
			row: {
				flex: 1,
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignContent: 'center',
			},
			text: {
				color: 'lawngreen',
				// fontSize: wp('1.17%'),
			},
			closeIcon: {
				color: 'lawngreen',
				// fontSize: wp('1.17%'),
			},
			exitButton: {
				justifyContent: 'flex-end',
				// height: hp('2.6%'),
				// width: hp('2.6%'),
				// marginHorizontal: wp('0.39%'),
			},
		},
		puzzleAnswerModal: {
			container: {
				flex: 1,
			},
			topContainer: {
				flex: 10,
				borderRadius: 5,
				backgroundColor: 'rgba(255,255,255,0.9)',
			},
			bottomContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-around',
				alignItems: 'center',
				backgroundColor: 'transparent',
				// paddingVertical: hp('2.6%'),
			},
			buttonDimentions: {
				// height: hp('6.51%'),
				// width: wp('14.65%'),
			},
		},
		goToLocationModal: {
			container: {
				flex: 1,
			},
			topContainer: {
				flex: 10,
				borderRadius: 5,
				backgroundColor: 'rgba(255,255,255,0.9)'
			},
			bottomContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-around',
				alignItems: 'center',
				backgroundColor: 'transparent'
				// paddingVertical: hp('2.6%'),
			},
			buttonDimensions: {
				// height: hp('10.42%'),
				// width: wp('23.44%'),
			},
			buttonIcon: {
				color: 'white'
			},
			buttonFont: {
				color: 'white'
			}
		},
		customNumberPad: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				height: '50vh'
			},
			header: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'stretch'
			},
			titleContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				alignItems: 'stretch',
				justifyContent: 'center',
				height: '5vh'
			},
			titleContentContainer: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			},
			title: {
				fontSize: 20,
				textAlign: 'center',
				textAlignVertical: 'center'
			},
			inputFieldContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-around',
				alignItems: 'center',
				padding: '10px 20px'
			},
			inputNumberField: {
				justifyContent: 'flex-end',
				alignItems: 'center',
				backgroundColor: 'grey',
				margin: '20px 0 px',
				padding: '5px',
				border: '1px solid black',
				maxHeight: '30px'
			},
			passcodeItemContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center'
			},
			passcodeItem: {
				display: 'flex',
				flex: 1,
				alignSelf: 'center',
				height: '30px',
				maxWidth: '30px',
				borderRadius: '100%',
				border: '2px solid black'
			},
			passcodeBackground: {
				display: 'flex',
				flex: 1
				// borderRadius: wp('1.46%'),
			},
			passcodeTextContainer: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			},
			passcodeText: {
				textAlign: 'center',
				textAlignVertical: 'center',
				fontSize: 20,
				color: 'black'
			},
			keyPadContainer: {
				display: 'flex',
				flex: 4,
				flexDirection: 'column',
				justifyContent: 'stretch',
				alignItems: 'stretch',
				maxHeight: '40vh',
				padding: 10
			},
			rowContentItems: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'stretch',
				alignItems: 'stretch'
			},
			keypadButtonContainer: {
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
				padding: 10
			},
			keypadButton: {
				width: '60px',
				height: '60px',
				borderRadius: '100%'
			},
			keypadButtonText: {
				fontSize: 30,
				color: 'white'
			},
			exitButton: {
				height: 20,
				width: 20
			}
		},
		prevAnswersModal: {
			container: {
				display: 'flex',
				flex: 1
			},
			listContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			listTitleContainer: {
				display: 'flex',
				flex: 1,
				maxHeight: '40px',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '4px 10px',
				borderBottom: '1px solid darkgrey',
				backgroundColor: 'whitesmoke'
			},
			listTitle: {
				display: 'flex',
				flex: 1,
				color: 'black',
				fontSize: '16px',
				fontWeight: 'bold',
				padding: 0
			},
			listHeaders: {
				display: 'flex',
				flex: 1,
				maxHeight: '40px',
				alignItems: 'center',
				padding: '4px 10px'
			},
			listSection: {
				flexDirection: 'column'
			},
			listItem: {
				display: 'flex',
				flex: 1,
				maxHeight: '40px',
				padding: '4px 10px'
			},
			listItemText: {
				display: 'flex',
				flex: 1,
				margin: 0,
				padding: 0
			}
		},
		customTabBar: {
			tab: {
				flex: 1,
			},
			tabContainer: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			tabs: {
				// height: hp('6.51%'),
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'stretch',
			},
			tabUnderlineStyle: {
				position: 'absolute',
				height: 8,
				top: 0,
			},
			buttonContainer: {
				// height: hp('6.51%'),
				// width: wp('4.88%'),
				justifyContent: 'center',
				alignItems: 'center',
			},
			closeButton: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'center',
			},
		},
		progressBar: {
			container: {
				flex: 1,
				// paddingVertical: hp('1.3%'),
				// paddingRight: wp('0.98%'),
			},
			barStyling: {
				// height: hp('2.6%'),
			},
		},
		networkingModal: {
			container: {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
				// padding: wp('1.95%'),
			},
			icon: {
				// fontSize: wp('7.8%'),
				// marginBottom: hp('2.6%'),
			},
			text: {
				// fontSize: wp('2%'),
				textAlign: 'center',
			},
		},
		feedbackModal: {
			container: {
				flex: 1,
			},
			topContainer: {
				flex: 10,
				borderRadius: 5,
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
			},
			bottomContainer: {
				flex: 1,
				// paddingVertical: hp('2.6%'),
				flexDirection: 'row',
				justifyContent: 'center',
				alignContent: 'center',
			},
			buttonDimensions: {
				// height: hp('10.42%'),
				// width: wp('23.44%'),
			},
		},
		textInput: {
			container: {
				flex: 1,
			},
			label: {
				color: '#666',
				// fontSize: wp('2.4%'),
				fontWeight: 'normal',
				top: 6,
			},
			input: {
				color: '#fff',
				fontWeight: 'normal',
			},
			borderInput: {
				borderColor: '#ddd',
				borderWidth: 1,
				fontWeight: 'normal',
			},
			dropShadowGradient: {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				// height: hp('1.56%'),
				backgroundColor: 'transparent',
			},
		},
		surveyPanel: {
			container: {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
				// paddingVertical: hp('2.6%'),
			},
			radioLabel: {
				color: '#666',
			},
			lineBreakLeft: {
				flex: 1,
				borderTopWidth: 3,
				borderColor: 'black',
				// paddingHorizontal: wp('1.96%'),
				opacity: 0.4,
			},
			lineBreakRight: {
				flex: 1,
				borderTopWidth: 3,
				borderColor: 'black',
				// paddingHorizontal: wp('1.96%'),
				opacity: 0.4,
			},
			answerContainer: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				marginLeft: 0,
				paddingRight: 0,
				borderBottomWidth: 0,
			},
			radioContainer: {
				flex: 1,
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			},
			radioButton: {
				flex: 1,
				flexDirection: 'row',
				alignItems: 'center',
			},
		},
		userNotesInput: {
			userNotesHeading: {
				color: '#333',
				// fontSize: wp('2%'),
			},
			notesTextInput: {
				textAlign: 'auto',
				textAlignVertical: 'top',
				color: '#333',
				// fontSize: wp('2%'),
				borderWidth: 1,
				borderColor: 'grey',
			},
		},
		videoPlayer: {
			container: {
				flex: 1,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				// width: wp('50%'),
				// height: hp('50%'),
			},
			videoStyle: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			},
			animatedVideoStyle: {
				position: 'absolute',
				top: 0,
				backgroundColor: 'rgba(255,255,255,0.6)',
				justifyContent: 'center',
				alignItems: 'flex-end',
				// paddingRight: wp('0.98%'),
			},
			videoOverlay: {
				flex: 1,
				alignSelf: 'stretch',
				backgroundColor: 'rgba(0,0,0,0.4)',
			},
			visible: {
				opacity: 1,
			},
			invisible: {
				opacity: 0,
			},
			button: {
				flex: 5,
				alignSelf: 'stretch',
				alignItems: 'center',
				justifyContent: 'center',
			},
			buttonIcon: {
				// fontSize: wp('9.8%'),
				color: '#666',
			},
			progressBar: {
				position: 'absolute',
				right: 0,
				bottom: 0,
				left: 0,
			},
			progress: {
				flex: 1,
				flexDirection: 'row',
				overflow: 'hidden',
			},
			innerProgressCompleted: {
				height: 8,
				backgroundColor: '#cccccc',
			},
			innerProgressRemaining: {
				height: 8,
				backgroundColor: '#2c2c2c'
			},
			expandIcon: {
				flex: 1,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-end'
			},
			expandButton: {
				// width: wp('11.72%'),
				// height: hp('3.75%'),
			},
			expandBtnFont: {
				// fontSize: wp('1.56%'),
				color: 'black'
			},
			expandBtnIcon: {
				// fontSize: wp('2.03%'),
				color: 'black'
			}
		},
		wysiwygRenderer: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			wysiwygBackground: {
				display: 'flex',
				flex: 1,
			  backgroundPosition: 'center',
			  backgroundSize: 'cover',
			  backgroundRepeat: 'no-repeat',
			  // minHeight: '100vh'
			},
			wysiwygHTML: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				padding: '10px',
				overflow: 'scroll',
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
				backgroundSize: 'cover'
			},
			thumbnailDimensions: {
				maxWidth: '360px',
				maxHeight: '240px',
				padding: '5px',
				border: '1px solid black'
			}
		},
		// lightboxRenderer: {
		// 	container: {
		// 		flex: 1,
		// 		borderBottomRightRadius: 5,
		// 		borderBottomLeftRadius: 5,
		// 		backgroundColor: 'rgba(255,255,255,0.85)',
		// 		overflow: 'hidden'
		// 	},
		// 	backgroundView: {
		// 		position: 'absolute',
		// 		width: null,
		// 		height: null,
		// 		top: 0,
		// 		bottom: 0,
		// 		left: 0,
		// 		right: 0,
		// 		borderBottomRightRadius: 5,
		// 		borderBottomLeftRadius: 5,
		// 		backgroundColor: 'black'
		// 	},
		// 	photoView: {
		// 		flex: 1,
		// 		// padding: wp('0.49%'),
		// 		alignSelf: 'stretch'
		// 	}
		// },
		// lightboxOverlay: {
		// 	background: {
		// 		position: 'absolute',
		// 		top: 0,
		// 		left: 0,
		// 		// width: wp('100%'),
		// 		// height: hp('100%'),
		// 	},
		// 	open: {
		// 		position: 'absolute',
		// 		flex: 1,
		// 		justifyContent: 'center',
		// 		// Android pan handlers crash without this declaration:
		// 		backgroundColor: 'transparent',
		// 	},
		// 	header: {
		// 		position: 'absolute',
		// 		top: 0,
		// 		left: 0,
		// 		// width: wp('100%'),
		// 		backgroundColor: 'transparent',
		// 	},
		// 	closeButton: {
		// 		// fontSize: wp('3.4%'),
		// 		color: 'white',
		// 		// lineHeight: hp('5.21%'),
		// 		// width: wp('3.91%'),
		// 		textAlign: 'center',
		// 		shadowOffset: {
		// 			width: 0,
		// 			height: 0,
		// 		},
		// 		shadowRadius: 1.5,
		// 		shadowColor: 'black',
		// 		shadowOpacity: 0.8,
		// 	},
		// },
		notesComponent: {
			container: {
				flex: 1,
			},
			centerItems: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			windowHeader: {
				flex: 1,
				flexDirection: 'row',
				backgroundColor: '#dddddd',
				borderRadius: 5,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
			windowContent: {
				flex: 10,
				flexDirection: 'row',
			},
			notesList: {
				flex: 1,
				backgroundColor: 'white',
				borderBottomLeftRadius: 5,
			},
			emptyNotesListView: {
				flex: 1,
				// height: hp('6.51%'),
				justifyContent: 'center',
				alignItems: 'center',
				borderBottomLeftRadius: 5,
				borderBottomRightRadius: 5,
			},
			noteContentDisplay: {
				flex: 2,
				backgroundColor: 'white',
				borderBottomRightRadius: 5,
			},
			noteContentBorderRight: {
				flex: 1,
				borderLeftWidth: 2,
				borderColor: '#dddddd',
				// padding: wp('1.95%'),
			},
			noteListItemContainer: {
				flex: 1,
				// height: hp('10.42%'),
			},
			notesListOrderBar: {
				backgroundColor: '#dddddd',
				// height: hp('5.21%'),
				borderWidth: 2,
			},
			notesListOrderButtons: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				// marginHorizontal: wp('0.98%'),
			},
			noteListItem: {
				flex: 1,
				backgroundColor: 'transparent',
				borderColor: '#dddddd',
				borderBottomWidth: 1,
				alignItems: 'flex-start',
				justifyContent: 'center',
				// marginHorizontal: wp('1.95%'),
			},
			noteTitleFont: {
				fontWeight: 'bold',
				// fontSize: wp('2%'),
			},
		},
		notificationModule: {
			container: {
				position: 'absolute',
				alignSelf: 'center',
				top: 0,
				// width: wp('39.06%'),
				flex: 1,
				backgroundColor: 'transparent',
				zIndex: 10,
			},
			notificationObjectContainer: {
				flex: 1,
				// height: hp('11.72%'),
				marginBottom: 5,
				alignItems: 'center',
				borderWidth: 2,
				borderColor: 'white',
			},
			notificationObjectContent: {
				flex: 1,
				// height: hp('11.72%'),
				// paddingLeft: wp('1.95%'),
				justifyContent: 'space-between',
				flexDirection: 'row',
				alignItems: 'center'
			},
			notificationElement: {
				flex: 6,
				flexDirection: 'row',
				alignItems: 'center'
			},
			notificationTitleFont: {
				// fontSize: wp('2%'),
				fontWeight: 'bold',
				textAlign: 'left',
				color: 'white'
			},
			exitButton: {
				flex: 1,
				flexDirection: 'row',
				alignSelf: 'flex-end',
				paddingRight: 5,
				// height: hp('5.21%')
			}
		},
		modalWindow: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			windowHeader: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				maxHeight: '5vh',
				backgroundColor: '#dddddd',
				borderTopLeftRadius: 5,
				borderTopRightRadius: 5
			},
			windowTitle: {
				display: 'flex',
				flex: 1,
				justifyContent: 'flex-start',
				alignItems: 'center',
				paddingLeft: 10
			},
			titleFont: {
				// fontSize: wp('2.4%'),
				fontWeight: 'bold'
			},
			closeContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignContent: 'center'
			},
			exitButton: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'flex-end'
				// minWidth: wp('9.77%'),
			},
			contentContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignSelf: 'stretch',
				backgroundColor: 'white'
			}
		},
		universalModal: {
			container: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				padding: 10
			},
			modalContainer: {
				display: 'flex',
				flex: 1,
				backgroundColor: 'white',
				borderRadius: '5px',
				minHeight: '50vh',
				maxHeight: '50vh',
				height: '50vh',
				maxWidth: '90vw'
			}
		},
		results: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: 'white'
			},
			gridSquareItems: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			gridSquareComponent: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			}
		}
	},
	scenes: {
		loginEntry: {
			background: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			  backgroundPosition: 'center',
			  backgroundSize: 'cover',
			  backgroundRepeat: 'no-repeat',
			  minHeight: '100vh',
			  padding: '20px'
			},
			loginContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				maxHeight: '30vh',
				maxWidth: '50vw',
				justifyContent: 'space-around',
				alignItems: 'center',
				padding: '20px',
				backgroundColor: 'rgba(255, 255, 255, 0.6)',
				borderRadius: '10px'
			},
			loginText: {
				textAlign: 'center'
			},
			loginInput: {
				display: 'flex',
				flex: 1,
				maxHeight: '50px',
				justifyContent: 'center',
				alignItems: 'center'
			},
			loginButton: {
				display: 'flex',
				flex: 1,
				maxHeight: '50px',
				color: 'white',
				borderRadius: '10px'
			}
		},
		preExperience: {
			background: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			  backgroundPosition: 'center',
			  backgroundSize: 'cover',
			  backgroundRepeat: 'no-repeat',
			  minHeight: '100vh'
			},
			backgroundLogo: {
				alignSelf: 'center',
				position: 'absolute',
				// top: hp('25%'),
				// width: wp('60%'),
				// height: hp('50%'),
				resizeMode: 'center',
				zIndex: 1
				// padding: wp('0.98%'),
			},
			introOutroContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			},
			preExperienceTitle: {
				textAlign: 'center',
				color: 'white'
			}
		},
		loading: {
			container: {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'flex-end',
				alignSelf: 'stretch'
			},
			loadingText: {
				// paddingBottom: hp('1.3%'),
			},
			homeButton: {
				position: 'absolute',
				top: 0,
				left: 0,
				// width: wp('7.81%'),
				// height: wp('7.81%'),
				justifyContent: 'center',
				alignItems: 'center',
			},
			titleContainer: {
				position: 'absolute',
				top: 0,
				left: 0,
				// width: wp('100%'),
				// height: hp('13.02%'),
				justifyContent: 'center',
			},
			title: {
				// fontSize: wp('2.93%'),
				textAlign: 'center',
			},
			loadingTitle: {
				// fontSize: wp('3.91%'),
			}
		},
		registration: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 10
			},
			regoContentContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				maxWidth: '90%',
				padding: 10,
				justifyContent: 'space-between',
				alignItems: 'center',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderRadius: 5
			},
			teamMembersListContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'space-between',
				minHeight: 300,
				padding: 10,
				marginBottom: 10,
				border: '1px solid black'
			},
			teamMembersListHeader: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-between'
			},
			title: {
				// fontSize: wp('2.9%'),
				textAlign: 'center'
			},
			teamMembersListItem: {
				display: 'flex',
				flex: 1,
				border: '1px solid lightgrey'
			},
			termsDisagreeanceModal: {
				// width: wp('40%'),
				// height: hp('50%'),
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: 5
			},
			t_c_reject_Container: {
				flex: 1,
				justifyContent: 'space-around'
			},
			t_c_wysiwygContainer: {
				display: 'flex',
				flex: 1,
				borderBottom: '1px solid black'
			},
			t_c_wysiwygContentContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			t_c_buttonContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'space-between'
			},
			t_c_button: {
				display: 'flex',
				flex: 1,
				margin: 10
			},
			t_c_icon_style: {
				// fontSize: wp('2.93%'),
			},
			t_c_font_style: {
				// fontSize: wp('1.76%'),
				color: 'black',
			}
		},
		// Experience Scene Styling
		expScene: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			  backgroundPosition: 'center',
			  backgroundSize: 'cover',
			  backgroundRepeat: 'no-repeat',
			  height: '100vh'
			}
		},
		topSceneContainer: {
			container: {
				display: 'flex',
				flex: 2,
				flexDirection: 'column',
				width: '100vw',
				backgroundColor: 'rgba(0, 0, 0, 0.8)'
			},
			headerContainer: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				maxHeight: '20px'
			},
			headerTitleText: {
				textAlign: 'center',
				textVerticalAlign: 'center',
				fontSize: '10px'
			},
			topContent: {
				display: 'flex',
				flex: 3,
				flexDirection: 'row',
				justifyContent: 'flex-start',
				maxHeight: '50px',
				padding: '5px 10px'
			},
			menuContainer: {
				display: 'flex',
				flex: 1
			},
			drawerContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				width: '50vw'
			},
			drawerLogoContainer: {
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				padding: '10px'
			},
			drawerLogo: {
				display: 'flex',
				flex: 1,
				maxWidth: '90%',
				height: 'auto'
			},
			drawerListContainer: {
				display: 'flex',
				flex: 3
			},
			drawerList: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			drawerTeamContainer: {
				display: 'flex',
				flex: 1,
				padding: '10px'
			},
			menuIconParent: {
				padding: '0px',
				borderRadius: '0px'
			},
			menuIcon: {
				fontSize: '50px'
			},
			informationContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				padding: '5px 10px'
			},
			titleContainer: {
				display: 'flex',
				flex: 2,
				justifyContent: 'center',
				alignItems: 'center',
			},
			mainTitleText: {
				textVerticalAlign: 'center',
				textAlign: 'center',
				color: 'white',
				margin: '0px'
			},
			titleContainerLeft: {
				display: 'flex',
				flex: 2,
			},
			infoTopContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row'
			},
			secondaryTimerContainer: {
				display: 'flex',
				flex: 1
			},
			primaryTimerContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				borderLeft: '1px solid white',
				borderRight: '1px solid white'
			},
			pointsContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				alignItems: 'flex-end'
			},
			pointsValueText: {
				fontSize: 20,
				color: 'white',
				margin: '0px'
			},
			pointsTypeText: {
				fontSize: 12,
				color: 'white',
				margin: '0px'
			},
			logoContainerCenter: {
				// display: 'flex',
				// flex: 1,
				// justifyContent: 'flex-start',
				// alignContent: 'center',
				maxHeight: '100px',
				maxWidth: '100px',
				backgroundPosition: 'center',
			  backgroundSize: 'fit',
			  backgroundRepeat: 'no-repeat'
			},
			logoContainerRight: {
				display: 'flex',
				flex: 0.5,
				justifyContent: 'flex-start',
				alignContent: 'center'
			},
			logoImage: {
				display: 'flex',
				flex: 1
				// maxHeight: hp('23.44%'),
			}
			// bottomGradient: {
			// 	position: 'absolute',
			// 	bottom: 0,
			// 	left: 0,
			// 	right: 0,
			// 	// height: hp('5%'),
			// 	backgroundColor: 'transparent',
			// },
		},
		middleSceneContainer: {
			container: {
				display: 'flex',
				flex: 8,
				flexDirection: 'column',
				width: '100vw',
				overflowY: 'scroll'
			},
			leftContainer: {
				flex: 1,
				borderRightWidth: 1,
				borderColor: '#ddd'
			},
			rightContainer: {
				flex: 3
			},
			rightScrollView: {
				flex: 1
			},
			wysiwygContainer: {
				flex: 1
				// paddingTop: hp('1.3%'),
				// paddingHorizontal: wp('1.95%'),
			}
		},
		bottomSceneContainer: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				width: '100vw'
			},
			footerContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				maxHeight: '100px',
				padding: '10px',
				backgroundColor: 'rgba(0, 0, 0, 0.8)'
			},
			footerBottomContainer: {
				display: 'flex',
				flex: 1,
				maxHeight: '60px',
				justifyContent: 'space-between'
			},
			footerBottomButtonContainer: {
				display: 'flex',
				flex: 2,
				justifyContent: 'flex-start',
				alignItems: 'center'
			},
			footerButtonBase: {
				height: '50px',
				color: 'white',
				margin: '0px 5px'
			},
			footerBottomAttemptsContainer: {
				display: 'flex',
				flex: 1,
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '0px 10px'
			},
			footerTextInput: {
				display: 'flex',
				flex: 1,
				backgroundColor: 'rgba(179, 183, 183, 1)',
				padding: '0px 10px',
				borderTopLeftRadius: '5px',
				borderTopRightRadius: '5px',
				maxHeight: '30px'
			}
		},
		interactionPanel: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column'
			},
			interactionScrollContainer: {
				// flex: 15,
				flex: 5,
				borderBottomWidth: 0.5,
				borderBottomColor: '#ddd'
			},
			interactionContentContainer: {
				flex: 1,
				// padding: wp('1.95%'),
			},
			answerInputContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				alignItems: 'center',
				border: '1px solid yellow'
				// paddingHorizontal: wp('1.95%'),
			},
			attemptsContainer: {
				flex: 1.4,
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				// height: hp('7.81%'),
				// marginRight: wp('0.98%'),
			},
			attemptsText: {
				flex: 1,
				color: 'darkgrey',
				textAlign: 'right',
				// fontSize: wp('2%'),
			},
			attemptsNum: {
				color: 'dimgrey',
				// fontSize: wp('4.4%'),
				fontWeight: 'bold',
				justifyContent: 'center',
				textAlign: 'center',
				// marginHorizontal: wp('0.49%'),
			},
			title: {
				// fontSize: wp('2.9%'),
			},
			textInputParent: {
				flex: 3,
				alignItems: 'stretch',
				justifyContent: 'center',
				// height: hp('6.51%'),
				// marginRight: wp('1.95%'),
			},
			textInputContainer: {
				flex: 1,
				// marginVertical: hp('1.3%'),
				justifyContent: 'center',
			},
			checkAnswerButtonContainer: {
				flex: 1.4,
				justifyContent: 'center',
				// height: hp('6.51%'),
			},
			buttonDimentions: {
				flex: 1,
				// marginVertical: hp('1.3%'),
				// height: hp('6.25%'),
			},
			buttonText: {
				color: 'white',
				// fontSize: wp('2%'),
				textAlign: 'center',
				textAlignVertical: 'center',
			},
			interactionStatsContainer: {
				flex: 1,
				flexDirection: 'row',
				// paddingHorizontal: wp('1.95%'),
				// paddingTop: hp('1.3%'),
			},
			statsContainer: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
			},
			statsSplit: {
				// marginHorizontal: wp('0.98%'),
				color: 'rgba(0, 0, 0, 0.4)',
			},
			stats: {
				color: 'rgba(0, 0, 0, 0.4)',
			}
		},
		imageSelection: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				overflow: 'scroll'
			},
			imageSelectionContainer: {
				display: 'flex',
				flex: 1,
				padding: '10px'
			},
			imageSelectionItemContainer: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '10px'
			},
			imageSelectionImageContainer: {
				display: 'flex',
				flex: 3,
				position: 'relative'
			},
			imageSelectionImage: {
				width: '180px',
				height: '100px',
				border: '1px solid white',
				borderRadius: '20px'
			},
			iconContainer: {
				position: 'absolute',
				width: '40px',
				height: '40px',
				right: -5,
				top: -5,
				padding: '4px',
				border: '1px solid white',
				borderRadius: '100px'
			}
		},
		summary: {
			container: {
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			  backgroundPosition: 'center',
			  backgroundSize: 'cover',
			  backgroundRepeat: 'no-repeat',
			  height: '100vh'
			},
			backgroundImage: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0
			},
			backgroundLogo: {
				alignSelf: 'center',
				position: 'absolute',
				// top: hp('25%'),
				// width: wp('60%'),
				// height: hp('50%'),
				resizeMode: 'center'
				// padding: wp('0.98%'),
			}
		}
	}
};
