import React, { Component } from 'react';

// custom components
import Styles from '../lib/Styles';

const styles = Styles.components.pagination;

export default class PaginationDot extends Component {
	render() {
		const { active, activeStyle } = this.props;

		let styleDot;

		if (active) {
			styleDot = Object.assign({}, styles.dot, { backgroundColor: activeStyle });
		} else {
			styleDot = styles.dot;
		}

		return (
			<div style={styleDot} />
		);
	}
}
