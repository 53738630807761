/* import action types */
import {
	ASSIGN_GAME_MAP,
	START_MASTER_STAGE,
	START_STAGE,
	SET_STAGE_INDEX,
	START_INTERACTION,
	SET_INTERACTION_INDEX,
	SUBMIT_LOCK,
	UPDATE_INTERACTION_VALUES,
	GIVE_GAME_FEEDBACK,
	PROCESS_GAME_ACTION,
	ADD_PREV_ANSWER,
	ADD_PREV_ANSWER_STICKY_HEADER,
	UPDATE_PREV_ANSWER,
	ASSIGN_FLOATING_BUTTONS
} from '../actions/Experience';

import {
	SET_WINDOW_STATE,
	SET_GAME_OVER
} from '../actions/Game';

/* reducers */
export function windowState(state = 'loading', action) {
	switch (action.type) {
	case SET_WINDOW_STATE:
	case START_MASTER_STAGE:
	case START_STAGE:
	case START_INTERACTION:
		state = action.windowState;
		return state;
	default:
		return state;
	}
}

export function currentMasterStageIndex(state = null, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		state = action.index;
		return state;
	default:
		return state;
	}
}

export function currentStageIndex(state = null, action) {
	switch (action.type) {
	case START_STAGE:
	case SET_STAGE_INDEX:
		state = action.index;
		return state;
	default:
		return state;
	}
}

export function currentInteractionIndex(state = null, action) {
	switch (action.type) {
	case START_INTERACTION:
	case SET_INTERACTION_INDEX:
		state = action.index;
		return state;
	default:
		return state;
	}
}

export function topContainerName(state = '', action) {
	switch (action.type) {
	case SET_WINDOW_STATE:
		switch (action.windowState) {
		case 'loading':
		case 'introduction':
			state = 'Welcome';
			break;
		case 'registration':
			state = 'Registration';
			break;
		case 'outroduction':
			state = 'Welcome';
			break;
		default:
			state = '';
			return state;
		}
		return state;
	case START_MASTER_STAGE:
		state = action.masterStageTitle;
		return state;
	case START_STAGE:
		state = action.stageTitle;
		return state;
	case SET_GAME_OVER:
		state = 'Summary';
		return state;
	default:
		return state;
	}
}

export function gameFeedback(state = { status: null, feedback: '' }, action) {
	switch (action.type) {
	case GIVE_GAME_FEEDBACK:
		state = {
			status: action.status,
			feedback: action.feedback
		};

		return state;
	case PROCESS_GAME_ACTION:
		state.status = null;
		return state;
	default:
		return state;
	}
}

export function showSelectionFeedback(state = false, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
	case START_STAGE:
		state = action.selectionInfoVisibility;
		return state;
	default:
		return state;
	}
}

export function showBackButton(state = true, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
	case START_STAGE:
	case START_INTERACTION:
		state = action.backBtnVisibility;
		return state;
	default:
		return state;
	}
}

export function expSceneButtons(state = { mapButtonVis: false, infoButtonVis: false, notesButtonVis: false, hintsButtonVis: false }, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		state.mapButtonVis = action.mapVisibility;
		state.infoButtonVis = action.infoVisibility;
		state.notesButtonVis = action.noteVisibility;
		state.hintsButtonVis = action.hintsBtnVisibility;
		return state;
	case START_STAGE:
		state.mapButtonVis = action.mapVisibility;
		return state;
	case START_INTERACTION:
		state.hintsButtonVis = action.hintsBtnVisibility;
		return state;
	default:
		return state;
	}
}

export function currentMapImages(state = { gameMap: { tabIndex: null, title: 'Experience', imageAddress: '' }, masterStageMap: { tabIndex: null, title: 'Master Stage', imageAddress: '' }, stageMap: { tabIndex: null, title: 'Stage', imageAddress: '' } }, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		if (action.imageAddress.length > 0) {
			if (state.gameMap.imageAddress) {
				state.masterStageMap.tabIndex = 1;
			} else {
				state.masterStageMap.tabIndex = 0;
			}

			state.masterStageMap.imageAddress = action.imageAddress;
		} else {
			state.masterStageMap.tabIndex = null;
			state.masterStageMap.imageAddress = '';
		}

		if (state.stageMap.imageAddress) {
			state.stageMap.tabIndex = null;
			state.stageMap.imageAddress = '';
			state.stageMap.title = '';
		}
		return state;
	case START_STAGE:
		if (action.imageAddress.length > 0) {
			if (state.masterStageMap.imageAddress) {
				if (state.gameMap.imageAddress) {
					state.stageMap.tabIndex = 2;
				} else {
					state.stageMap.tabIndex = 1;
				}
			} else {
				if (state.gameMap.imageAddress) {
					state.stageMap.tabIndex = 1;
				} else {
					state.stageMap.tabIndex = 0;
				}
			}

			state.stageMap.imageAddress = action.imageAddress;
			state.stageMap.title = action.titleType ? action.titleType : 'Stage';
		} else {
			state.stageMap.tabIndex = null;
			state.stageMap.imageAddress = '';
			state.stageMap.title = '';
		}
		return state;
	case ASSIGN_GAME_MAP:
		state.gameMap.tabIndex = 0;
		state.gameMap.imageAddress = action.imageAddress;
		return state;
	default:
		return state;
	}
}

export function submitLocked(state = false, action) {
	switch (action.type) {
	case SUBMIT_LOCK:
		state = action.locked;

		return state;
	 case START_INTERACTION:
		state = false;
		return state;
	default:
		return state;
	}
}

export function stageProgressBar(state = { intervalValue: 0, currentProgress: 0 }, action) {
	switch (action.type) {
	case START_STAGE:
		state.intervalValue = action.progressInterval;
		state.currentProgress = action.currentProgress;
		return state;
	case UPDATE_INTERACTION_VALUES:
		state.currentProgress += state.intervalValue;
		return state;
	case START_MASTER_STAGE:
		state.intervalValue = 0;
		state.currentProgress = 0;
		return state;
	default:
		return state;
	}
}

/************************ START Game Control Panel **********************************/
export function floatingButtons(state = [], action) {
	switch (action.type) {
		case ASSIGN_FLOATING_BUTTONS:
			state = action.floatingButtons;
			return state;
		default:
			return state;
	}
}

export function showProgressBar(state = false, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		state = false;
		return state;
	case START_STAGE:
		state = action.enableProgressBar;
		return state;
	default:
		return state;
	}
}

export function showRewardLabel(state = { visible: false, value: '' }, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
	case START_STAGE:
		state = { visible: false, value: '' };
		return state;
	case START_INTERACTION:
		state = {
			visible: action.showReward,
			value: action.rewardValue
		};
		return state;
	default:
		return state;
	}
}

export function showYourPointsButton(state = false, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		state = false;
		return state;
	case START_STAGE:
		state = action.showYourPoints;
		return state;
	default:
		return state;
	}
}

export function showInstructionsButton(state = false, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		state = false;
		return state;
	case START_STAGE:
		state = action.showInstructions;
		return state;
	default:
		return state;
	}
}

export function showBottomExtraButtons(state = false, action) {
	switch (action.type) {
	case START_MASTER_STAGE:
		state = false;
		return state;
	case START_STAGE:
	case START_INTERACTION:
		state = true;
		return state;
	default:
		return state;
	}
}

/**	********************** END Game Control Panel **********************************/

// Interaction settings
export function interactionSettings(state = { type: '', submitButtonText: 'SUBMIT', showTextInputField: false, content: '', hintsButtonVis: false}, action) {
	switch (action.type) {
	case START_INTERACTION:
		state = {
			type: action.interactionType,
			submitButtonText: action.submitButtonText,
			showTextInputField: action.showTextInputField,
			userNoteAllowed: action.userNoteAllowed,
			content: action.interactionContent,
			hintsButtonVis: action.hintsButtonVis
		};
		return state;
	default:
		return state;
	}
}

export function currentHints(state = [], action) {
	switch (action.type) {
	case START_INTERACTION:
		state = action.hints;

		return state;
	case START_MASTER_STAGE:
	case START_STAGE:
		state = [];
		return state;
	default:
		return state;
	}
}

export function currentAnswers(state = { prevAnswers: [], headers: [], stickyIndices: [0] }, action) {
	switch (action.type) {
	case ADD_PREV_ANSWER:
		let newStickyIndices = [];

		state.prevAnswers.push(action.lastAnswer);

		state.prevAnswers.sort(function (a, b) {
			if (a.location.mStg === b.location.mStg) {
				if (a.location.stg === b.location.stg) {
					return (a.location.int < b.location.int) ? -1 : (a.location.int > b.location.int) ? 1 : 0;
				} else {
					return (a.location.stg < b.location.stg) ? -1 : 1;
				}
			} else {
				return (a.location.mStg < b.location.mStg) ? -1 : 1;
			}
		});

		for (let i = 0; i < state.prevAnswers.length; i++) {
			if (state.prevAnswers[i].id.includes('_Sticky')) {
				newStickyIndices.push(state.prevAnswers[i].id);
			}
		}

		state.stickyIndices = newStickyIndices;

		return state;
	case ADD_PREV_ANSWER_STICKY_HEADER:
		state.prevAnswers.push(action.header);
		state.headers.push(action.header); // only needed so we don't add multiples of the same stage title down
		state.stickyIndices.push(action.index);

		return state;
	case UPDATE_PREV_ANSWER:
		state.prevAnswers[action.index] = action.lastAnswer;
		return state;
	default:
		return state;
	}
}
