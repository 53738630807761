import GLOBAL from '../lib/Globals';

import * as gameActions from './Game';
import * as experienceActions from './Experience';
import * as timerActions from './Timers';
import * as uniModalActions from './UniversalModal';
import * as notificationActions from './Notifications';

/* action types */
export const INIT_USER_DATA = 'INIT_USER_DATA';
export const SET_REGISTRATION_STATE = 'SET_REGISTRATION_STATE';
export const SET_TEAM_NAME_TEXT = 'SET_TEAM_NAME_TEXT';
export const SET_TEAM_NAME = 'SET_TEAM_NAME';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_AGE = 'SET_AGE';
export const SET_GENDER = 'SET_GENDER';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const EDIT_TEAM_MEMBER = 'EDIT_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const SELECTED_MEMBER_INDEX = 'SELECTED_MEMBER_INDEX';
export const SET_TEAM_PHOTO = 'SET_TEAM_PHOTO';
export const USER_START_TIME = 'USER_START_TIME';
export const SET_USER_NOTE_TEXT = 'SET_USER_NOTE_TEXT';
export const CREATE_NOTE = 'CREATE_NOTE';
export const GIVE_REWARD = 'GIVE_REWARD';
export const ADD_POINTS = 'ADD_POINTS';
export const UNLOCK_HINT = 'UNLOCK_HINT';
export const PROCESS_RESULTS = 'PROCESS_RESULTS';
export const PROCESS_USERDATA = 'PROCESS_USERDATA';
export const UPDATE_PROCESSED_USERDATA = 'UPDATE_PROCESSED_USERDATA';
export const RETRIEVE_PREV_USERDATA = 'RETRIEVE_PREV_USERDATA';
export const RESUME_PREVIOUS_PROGRESS = 'RESUME_PREVIOUS_PROGRESS';
export const SET_RESULTS_SLIDES = 'SET_RESULTS_SLIDES';

/* action creators */

// initialise user data
export function initialiseUserData() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		// non state variables
		let userGameTimer = 0;
		let userData = {};
		let master_stage = [];
		let userDetails = {
			tsAndCs: '',
			teamName: '',
			teamPhoto: '',
			teamPhotoURL: '',
			teamMembers: []
		};

		// check if there is a time restriction applied to the app, if there is, set the game timer then start it
		if (gameData.extra_settings.includes('time_restriction')) {
			let tempIndex = gameData.extra_settings.indexOf('time_restriction');
			let gameTimerIndex = tempIndex + 1;
			userGameTimer = gameData.extra_settings[gameTimerIndex] * 60;
		}

		let pointsIndex = 1;
		// if there are points defined at the start of the game
		if (gameData.extra_settings.includes('initial_points')) {
			let tempIndex = gameData.extra_settings.indexOf('initial_points');
			pointsIndex = tempIndex + 1;
		}

		let full_LB_URL = gameData.leaderboard.url_path;
		let lbSessionURL = full_LB_URL.replace(/(.*?com\/)/g, "");

		for (let i = 0; i < gameData.master_stages.length; i++) {
			let stageResult = [];
			let masterStage = gameData.master_stages[i];
			// there are stages
			if (masterStage.stages !== undefined) {
				let stages = masterStage.stages;
				for (let j = 0; j < stages.length; j++) {
					let stageInteractions = [];
					let stage = stages[j];
					// there are puzzles
					if (stage.puzzles !== undefined) {
						let interactions = stage.puzzles;
						for (let k = 0; k < interactions.length; k++) {
							let interactionHints = [];
							let interaction = interactions[k];
							if (interaction.hints) {
								for (let l = 0; l < interaction.hints.length; l++) {
									interactionHints.push({
										purchased: false,
									});
								}
							}

							let lockInteraction = interaction.locked_interaction;
							let interactionLocked = false;
							let stageName = stage.display_title ? stage.display_title : stage.title;
							let interactionName = interaction.display_title ? interaction.display_title : interaction.title;

							// Do some conditional checking here for initial value.
							if ((stage.structure === 'Linear' && k === 0) || (stage.structure === 'Non-Linear' && !lockInteraction)) {
								interactionLocked = false;
							} else {
								interactionLocked = true;
							}

							stageInteractions.push({
								type: interaction.type,
								locked: interactionLocked,
								completed: false,
								puzzleHints: interactionHints,
								title: interaction.title,
								stageName: stageName,
								puzzleName: interactionName,
								puzzlePoints: 0,
								answer: '',
								correct: false,
								timeSpent: 0,
								attempts: 0
							});
						}
					}

					let lockedStage = stage.locked_stage;
					let stageTime = stage.time_restriction === '' ? 0 : stage.time_restriction * 60;
					stageResult.push({
						locked: lockedStage,
						completed: false,
						completionPoints: 0,
						puzzle: stageInteractions,
						stageTimeLeft: stageTime,
						expired: false,
						stageTotalPoints: 0
					});
				}
				let masterStageTime = masterStage.time_restriction === '' ? 0 : masterStage.time_restriction * 60;
				master_stage.push({
					completed: false,
					completionPoints: 0,
					completedStages: [],
					stage: stageResult,
					masterStageTimeLeft: masterStageTime,
					expired: false,
					masterStageTotalPoints: 0
				});
			}
		}

		let randomNum = Math.floor(Math.random() * 1000000) + 1;
		let newResultsID = randomNum.toString() + Date.now(); // used for leaderboard result identification for a specific team, session and experience

		userData = {
			adminID: 1,
			gameName: gameData.name,
			completed: false,
			integrity: 'default',
			gameID: gameData.id,
			resultsID: newResultsID,
			session: {
				id: 0,
				otp: 0,
				start: 0,
				finished: 0
			},
			playerInitilized: false,
			lastUpdate: 0,
			startTime: 0,
			finishTime: 0,
			displayStartTime: 0,
			userDetails,
			gameTimer: userGameTimer,
			totalPoints: gameData.extra_settings[pointsIndex] !== '' ? parseInt(gameData.extra_settings[pointsIndex]) : 0,
			deviceID: 'web',
			appBuild: 'tempValue',
			master_stage,
			inventory: [],
			notes: [],
			position: {
				window_state: 'loading',
				scene: 'pre-experience',
				current: {
					master_stage: null,
					stage: null,
					interaction: null
				},
				previous: {
					master_stage: null,
					stage: null,
					interaction: null
				}
			},
			testMode: false,
			mediaDir: null,
			crashTriggered: false,
			dataExports: allDataObjs(gameData),
			hints_used: 0,
			lbSessionURL
		};

		if (gameData.extra_settings.includes('send_to_leaderboard')) {
			userData['sentToLB'] = false;
		}

		dispatch({
			type: INIT_USER_DATA,
			userData: userData
		});

		// determines the next action to be performed
		dispatch(gameActions.progressPreExperience());
	};
}

function allDataObjs(gameData) {
	let dataArray = [];
	let dataTypes = [
		'allData',
		'adminPanel',
		'leaderboard',
		'mailchimp',
		'userMedia'
	];

	let CommonDataObj = function (type, sent, attempts, finishedAttempts, lastAttempt, reason) {
		return {
			type: type,
			sent: sent,
			exportState: 'pending',
			attempts: attempts,
			finishedAttempts: finishedAttempts,
			lastAttempt: lastAttempt,
			reason: reason
		};
	};

	for (let i = 0; i < dataTypes.length; i++) {
		switch (dataTypes[i]) {
		case 'adminPanel':
			let dataAdminPanel = new CommonDataObj(dataTypes[i], false, 0, false, 'not yet attempted', 'N/A');
			dataArray.push(dataAdminPanel);
			dataArray[0].expectedExports.push(dataTypes[i]);
			break;
		case 'leaderboard':
			if (gameData.extra_settings.includes('send_to_leaderboard')) {
				let dataLeaderboard = new CommonDataObj(dataTypes[i], false, 0, false, 'not yet attempted', 'N/A');
				dataLeaderboard.successfulPushes = 0;
				dataArray.push(dataLeaderboard);
				dataArray[0].expectedExports.push(dataTypes[i]);
			}
			break;
		case 'mailchimp':
			if (gameData.extra_settings.includes('send_emails')) {
				let dataMailchimp = new CommonDataObj(dataTypes[i], false, 0, false, 'not yet attempted', 'N/A');
				dataMailchimp.photoSent = false;
				dataMailchimp.numMembers = 0;
				dataMailchimp.newAccounts = 0;
				dataMailchimp.subs = 0;
				dataMailchimp.nonSubs = 0;
				dataMailchimp.fails = 0;
				dataArray.push(dataMailchimp);
				dataArray[0].expectedExports.push(dataTypes[i]);
			}
			break;
		case 'userMedia':
			if (gameData.extra_settings.includes('send_user_media')) {
				let dataUserMedia = new CommonDataObj(dataTypes[i], false, 0, false, 'not yet attempted', 'N/A');
				dataUserMedia.numMedia = 0;
				dataUserMedia.successes = 0;
				dataUserMedia.fails = 0;
				dataArray.push(dataUserMedia);
				dataArray[0].expectedExports.push(dataTypes[i]);
			}
			break;
		default:
			let allData = new CommonDataObj(dataTypes[i], false, 0, false, 'not yet attempted', 'N/A');
			allData.successfulDataObjs = [];
			allData.failedDataObjs = [];
			allData.expectedExports = [];
			allData.processing = '';
			dataArray.push(allData);
			break;
		}
	}

	return dataArray;
}

/* ---------- Start User Registration logic ---------- */
export function setRegistrationState(direction) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let registrationState = getState().registrationState;

		switch (registrationState) {
		case 'team_name':
			if (direction == 'forward') {
				dispatch(setTeamName());
				if (gameData.extra_settings.includes('collect_details') || gameData.extra_settings.includes('single_person_rego')) {
					registrationState = 'add_team_member';
				} else {
					dispatch(gameActions.progressPreExperience());
				}
			}
			break;
		case 'add_team_member':
			if (direction == 'forward') {
				dispatch(addTeamMember());

				if (getState().registrationError.length == 0) {
					if (gameData.extra_settings.includes('single_person_rego')) {
						dispatch(gameActions.progressPreExperience());
						registrationState = 'team_name';
					} else {
						registrationState = 'team_list';
					}
				}
			} else {
				registrationState = 'team_name';
			}
			break;
		case 'team_list':
			if (direction == 'forward') {
				dispatch(gameActions.progressPreExperience());
			}

			registrationState = 'team_name';
			break;
		case 'edit_team_member':
			if (direction == 'forward') {
				dispatch(addTeamMember(true));

				if (getState().registrationError.length == 0) {
					registrationState = 'team_list';
				}
			} else {
				registrationState = 'team_name';
			}
			break;
		default:
			registrationState = 'team_name';
			break;
		}
		dispatch({
			type: SET_REGISTRATION_STATE,
			registrationState: registrationState
		});
	};
}

export function setTeamNameText(text) {
	return {
		type: SET_TEAM_NAME_TEXT,
		text: text
	};
}

export function setTeamName() {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TEAM_NAME,
			teamName: getState().teamName
		});
	};
}

export function setNewUserDetails(detailType, input) {
	return (dispatch) => {
		switch (detailType) {
		case 'firstName':
			dispatch({
				type: SET_FIRST_NAME,
				input: input
			});
			break;
		case 'lastName':
			dispatch({
				type: SET_LAST_NAME,
				input: input
			});
			break;
		case 'email':
			dispatch({
				type: SET_EMAIL,
				input: input
			});
			break;
		case 'age':
			dispatch({
				type: SET_AGE,
				input: input
			});
			break;
		case 'gender':
			dispatch({
				type: SET_GENDER,
				input: input
			});
			break;
		default:
			console.log('setNerUserDetails detail type: ' + detailType + ' is unknown');
			break;
		}
	};
}

export function addTeamMember(edit) {
	return (dispatch, getState) => {
		let selectedIndex = getState().selectedMemberIndex;
		let teamMember = {
			firstName: getState().newUserFirstName,
			lastName: getState().newUserLastName,
			email: getState().newUserEmail,
			age: getState().newUserAge,
			gender: getState().newUserGender
		};
		let errorPresent = false;

		// now we need to check if anything is missing and if it is, prompt user to fix the issue
		// cycle through each kvp in the object and make sure the Value fits the required parameters
		for (let key in teamMember) {
			switch (key) {
			case 'email':
				// specific logic for check if email address is valid
				let validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(teamMember[key]); // makes sure no special characters are added

				if (!validEmail) {
					errorPresent = true;
					dispatch(notificationActions.addNotification({
						id: key,
						message: 'Your email address is invalid',
						notificationType: 'error'
					}));
				}
				break;
			case 'firstName':
			case 'lastName':
				if (teamMember[key] !== '') {
					let invalidCharacters = /[.,:?!]/.test(teamMember[key]);

					if (invalidCharacters) {
						errorPresent = true;
						dispatch(notificationActions.addNotification({
							id: key,
							message: 'Names cannot contain illegal characters',
							notificationType: 'error'
						}));
					}
				} else {
					errorPresent = true;
					dispatch(notificationActions.addNotification({
						id: key,
						message: 'You need to type in a name in the field',
						notificationType: 'error'
					}));
				}
				break;
			default:
				if (teamMember[key] === '') {
					errorPresent = true;
					dispatch(notificationActions.addNotification({
						id: key,
						message: 'You need to fill out all of the fields',
						notificationType: 'error'
					}));
				}
				break;
			}
		}

		if (!errorPresent) {
			if (edit) {
				dispatch({
					type: EDIT_TEAM_MEMBER,
					index: selectedIndex,
					member: teamMember
				});
			} else {
				dispatch({
					type: ADD_TEAM_MEMBER,
					newMember: teamMember
				});
			}
		}
	};
}

export function showSelectedTeamMemberDetails(index) {
	return (dispatch, getState) => {
		let currentTeam = getState().userData.userDetails.teamMembers;
		let selectedMember = currentTeam[index];

		dispatch({
			type: SELECTED_MEMBER_INDEX,
			index: index
		});

		for (let key in selectedMember) {
			dispatch(setNewUserDetails(key, selectedMember[key]));
		}
		dispatch(uniModalActions.openModal('edit_rego_member'));
	};
}

export function removedSelectedTeamMember(index) {
	return {
		type: REMOVE_TEAM_MEMBER,
		index: index
	};
}

/* ---------- End User Registration logic ---------- */

export function setStartTime() {
	return (dispatch) => {
		// non state variables
		let startTime = Date.now();
		let displayStartTime = GLOBAL.formatDateTime(startTime);

		dispatch({
			type: USER_START_TIME,
			startTime: startTime,
			displayStartTime: displayStartTime,
			scene: 'experience'
		});
	};
}

// set text note input
export function setUserNoteText(text) {
	return {
		type: SET_USER_NOTE_TEXT,
		note: text
	};
}

export function createNote(title, content) {
	return (dispatch) => {
		// non state variables
		let newNote = {
			content: {
				type: 'notes',
				title: title,
				notes: content
			}
		};

		dispatch({
			type: CREATE_NOTE,
			newNote: newNote
		});
	};
}

export function giveReward(answerIndex) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let interactionRewards = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT].rewards;
		let answerRewards = 0;
		let notesCount = 0; // Counter for notification objects

		// for image selection combine selected image answers
		if (Array.isArray(answerIndex)) {
			answerRewards = answerIndex;
		} else {
			answerRewards = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT].resolutions[answerIndex].rewards;
		}

		if (interactionRewards !== undefined) {
			for (let i = 0; i < interactionRewards.length; i++) {
				for (let j = 0; j < answerRewards.length; j++) {
					if (interactionRewards[i] !== undefined && interactionRewards[i] !== null && interactionRewards[i].title === answerRewards[j]) {
						let reward = interactionRewards[i];
						switch (reward.type) {
						case 'points':
							let points = parseInt(reward.points);
							dispatch(addPoints(points));
							break;
						case 'notes':
							dispatch(createNote(reward.title, reward.notes));
							notesCount++;
							break;
						default:
							break;
						}
					}
				}
			}

			if (notesCount > 0) {
				let receivedMsg = "You've recieved " + notesCount + " note" + (notesCount > 1 ? "s. " : ". ");
				let instructionMsg = "Open Notes to view.";
				let notificationMsg = receivedMsg + "\n" + instructionMsg;

				dispatch(notificationActions.addNotification({ id: Date.now() + 1, message: notificationMsg, notificationType: 'info' }));
			}
		}
	};
}

export function addPoints(value) {
	return {
		type: ADD_POINTS,
		value: value
	};
}

export function	purchaseHint(index, item) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let stageTimerCount = getState().stageTimerCount;
		let masterStageTimerCount = getState().masterStageTimerCount;
		let gameTimerCount = getState().gameTimerCount;


		// non state variables
		let showRejectionMessage = true;
		let timerType = '';
		let confirmTitle = 'Oh no!';
		let confirmMsg = 'You cannot afford this hint';
		let confirmBtnText = 'Ok';

		switch (item.penalty_type) {
		case 'points':
			if ((userData.totalPoints - item.penalty_value) >= 0) {
				let penalty_text = 'no cost';
				let pointsPreface = gameData.points_preface.length > 0 ? ' ' + gameData.points_preface : '';
				let pointsSuffix = gameData.points_singular.length > 0 ? ' ' + gameData.points_singular : ' point';

				if (parseInt(item.penalty_value) > 1) {
					pointsSuffix = gameData.points_plural.length > 0 ? ' ' + gameData.points_plural : ' points';
				}

				penalty_text = pointsPreface + item.penalty_value + pointsSuffix;

				showRejectionMessage = false;
				confirmTitle = 'Unlock Hint';
				confirmMsg = `Would you like to unlock this hint for ${penalty_text}?`;
				confirmBtnText = 'Unlock';
			}
			break;
		case 'time':
			let timePenalty = item.penalty_value * 60;

			// compare the timers
			if ((stageTimerCount - timePenalty) > 0) {
				timerType = 'stgTimer';
				showRejectionMessage = false;
			} else if ((masterStageTimerCount - timePenalty) > 0) {
				timerType = 'mStgTimer';
				showRejectionMessage = false;
			} else if ((gameTimerCount - timePenalty) > 0) {
				timerType = 'gameTimer';
				showRejectionMessage = false;
			}

			if (!showRejectionMessage) {
				confirmTitle = 'Unlock Hint';
				confirmMsg = `Would you like to spend ${timePenalty} minutes to unlock this hint?`;
				confirmBtnText = 'Unlock';
			}
			break;
		default:
			showRejectionMessage = false;
			confirmTitle = 'Unlock Hint';
			confirmMsg = `Would you like to unlock this hint for free?`;
			confirmBtnText = 'Unlock';
			break;
		}

		// work out if we can afford the hint
		if (showRejectionMessage) {
			dispatch(uniModalActions.assignConfirmationModalProps(confirmTitle, confirmMsg, confirmBtnText, () => {
				dispatch(uniModalActions.closeConfirmationModal());
			}));
		} else {
			dispatch(uniModalActions.assignConfirmationModalProps(confirmTitle, confirmMsg, confirmBtnText, () => {
				dispatch(unlockHint(index, timerType));
			}));
		}
	};
}

function findTimerToBuyFrom(timePenalty) {
	let timerObj = {
		timerType: '',
		showRejectionMessage: true
	};

	// compare the timers
	if ((this.props.stageTimerCount - timePenalty) > 0) {
		timerObj.timerType = 'stgTimer';
		timerObj.showRejectionMessage = false;
	} else if ((this.props.masterStageTimerCount - timePenalty) > 0) {
		timerObj.timerType = 'mStgTimer';
		timerObj.showRejectionMessage = false;
	} else if ((this.props.gameTimerCount - timePenalty) > 0) {
		timerObj.timerType = 'gameTimer';
		timerObj.showRejectionMessage = false;
	}

	return timerObj;
}

export function unlockHint(hintIndex, timerToCheck) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let hint = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT].hints[hintIndex];
		let indexObj = {
			mStgIndex: cMSI,
			stgIndex: cSI
		};

		dispatch({
			type: UNLOCK_HINT,
			hintIndex: hintIndex
		});

		switch (hint.penalty_type) {
		case 'time':
			let newTimeValue = parseFloat(-hint.penalty_value) * 60;

			if (isNaN(newTimeValue)) {
				newTimeValue = 0;
			}

			dispatch(timerActions.modifyTimerValue(timerToCheck, newTimeValue, indexObj));
			break;
		case 'points':
			dispatch(addPoints(-hint.penalty_value));
			break;
		default:
			break;
		}
	};
}

/* ----- End of experience functions ----- */
export function processResults() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;

		// non state variables
		let answerArray = [];
		let hintArray = [];
		let puzzleObjs = [];
		let results = {
			allPuzzles: 0,
			allPoints: 0,
			allHints: 0,
			totalGameTime: 0,
			solvedPuzzles: 0,
			perSolvedInt: 0,
			usedHints: 0,
			perHintsUsed: 0,
			timeSpent: 0,
			displayGameTime: 0,
			perTimeSpent: 0,
			perUserPoints: 0
		};

		// master stages
		for (let i = 0; i < gameData.master_stages.length; i++) {
			// stages
			for (let j = 0; j < gameData.master_stages[i].stages.length; j++) {
				results.allPuzzles += gameData.master_stages[i].stages[j].puzzles.length;
				puzzleObjs = gameData.master_stages[i].stages[j].puzzles; // game puzzles
				answerArray = userData.master_stage[i].stage[j].puzzle; // user answers
				// puzzles
				for (let k = 0; k < gameData.master_stages[i].stages[j].puzzles.length; k++) {
					// if points defined add them to the total
					if (puzzleObjs[k].points !== undefined && puzzleObjs[k].points !== '' && puzzleObjs[k].points !== null) {
						results.allPoints += parseInt(puzzleObjs[k].points);
					} else {
						results.allPoints += 0;
					}
					// if it was correct add to the solved interactions
					if (answerArray[k].correct === true) {
						results.solvedPuzzles++;
					}
					hintArray = userData.master_stage[i].stage[j].puzzle[k].puzzleHints;
					if (gameData.master_stages[i].stages[j].puzzles[k].hints !== undefined) {
						// hints
						for (let l = 0; l < gameData.master_stages[i].stages[j].puzzles[k].hints.length; l++) {
							results.allHints++;
							// check if the user has purchased a hint
							if (hintArray[l].purchased === true) {
								results.usedHints++;
							}
						}
					}
				}
			}
		}

		if (gameData.extra_settings.includes('time_restriction')) {
			let tempIndex = gameData.extra_settings.indexOf('time_restriction');
			let gameTimerIndex = tempIndex + 1;
			let totalGameTime = parseInt(gameData.extra_settings[gameTimerIndex]) * 60;
			let totalSeconds = totalGameTime - userData.gameTimer;

			results.totalGameTime = totalGameTime;
			results.displayGameTime = GLOBAL.formatTimeHHMMSS(totalGameTime);
			results.timeSpent = GLOBAL.formatTimeHHMMSS(totalSeconds);
			results.perTimeSpent = Math.round((totalSeconds / results.totalGameTime) * 100);
		}

		results.perSolvedInt = Math.round((results.solvedPuzzles / results.allPuzzles) * 100);
		results.perHintsUsed = Math.round((results.usedHints / results.allHints) * 100);
		results.perUserPoints = Math.round((userData.totalPoints / results.allPoints) * 100);

		// results validation for mailchimp
		for (let prop in results) {
			let result = results[prop];
			if (result !== results.timeSpent && result !== results.displayGameTime) {
				let notNumber = isNaN(result);
				if (notNumber === true || !isFinite(result)) {
					results[prop] = 0;
				}
			}
		}

		results.processed = false;

		dispatch({
			type: PROCESS_RESULTS,
			endResults: results
		});

		// dispatch(gameActions.assignMasterStageBreakdown());

		// processes User data before executing function sendToAdminPortal
		dispatch(processUserData());
	};
}

export function processUserData() {
	return (dispatch, getState) => {
		let userData = getState().userData;
		let results = getState().endResults;

		let processedUserData = {};
		let duration = userData.finishTime - userData.startTime;

		// process duration of experience
		let startTime = GLOBAL.formatDateTime(userData.startTime);
		// process finish time of experience
		let finishTime = GLOBAL.formatDateTime(userData.finishTime);
		// process team member details
		let participantDetails = [];
		let teamMembers = userData.userDetails.teamMembers;

		for (let i = 0; i < teamMembers.length; i++) {
			let participant = {};
			participant.first_name = teamMembers[i].firstName;
			participant.last_name = teamMembers[i].lastName;
			participant.email = teamMembers[i].email;
			participant.gender = teamMembers[i].gender;
			participant.age = teamMembers[i].age;

			participantDetails.push(participant);
		}

		// populate processedUserData object
		processedUserData.user_id = userData.adminID;
		processedUserData.experience_id = userData.gameID;
		processedUserData.device_id = 'web_platform';
		processedUserData.results_id = userData.resultsID;
		processedUserData.test_mode = userData.testMode ? 1 : 0;
		processedUserData.start_time = startTime;
		processedUserData.finish_time = finishTime;
		processedUserData.duration = duration;
		processedUserData.team_name = userData.userDetails.teamName;
		processedUserData.total_points = userData.totalPoints;
		processedUserData.hints_used = results.usedHints;
		processedUserData.interactions_completed = results.solvedPuzzles;
		processedUserData.leaderboard_session_id = userData.lbSessionURL;
		processedUserData.number_of_registered_participants = userData.userDetails.teamMembers.length;
		processedUserData.device_name = 'web_platform';
		processedUserData.apk_version = userData.appBuild;
		processedUserData.crash_log_trigger = userData.crashTriggered ? 1 : 0;
		processedUserData.participants_detail = JSON.stringify(participantDetails);

		// this also updates the state for allProcessedUserData
		dispatch({
			type: PROCESS_USERDATA,
			processedUserData: processedUserData
		});
	};
}

export function updateProcessedUserData(results_id, sent) {
	return {
		type: UPDATE_PROCESSED_USERDATA,
		results_id: results_id,
		sent: sent
	};
}

export function resumePreviousProgress() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		// non state variables
		let prevUserDataArray = GLOBAL.getStoredData('USER_DATA');
		let lastUserData = prevUserDataArray[prevUserDataArray.length - 1];
		let cMSI = lastUserData.position.current.master_stage;
		let cSI = lastUserData.position.current.stage;
		let cINT = lastUserData.position.current.interaction;

		dispatch({
			type: RESUME_PREVIOUS_PROGRESS,
			userData: lastUserData
		});

		dispatch(timerActions.startGameTimer());

		if (gameData.map.length > 0) {
			dispatch(experienceActions.assignGameMap());
		}

		// go to last position
		dispatch(experienceActions.setUserLocation(cMSI, cSI, cINT));
	};
}

export function deleteAllUserData() {
	return () => {
		GLOBAL.deleteStoredData('all');
	};
}

export function retrievePrevUserdata() {
	return {
		type: RETRIEVE_PREV_USERDATA
	};
}
