import EventRegister from '../lib/Event-Listener';

// action types
import {
	EVENT_EMITTER,
	REMOVE_EMITTER,
	RESET_ALL_EMITTERS,
	ADD_STAGE_COMPLETION,
	ADD_INTERACTION_COMPLETION,
	REMOVE_STAGE_COMPLETION,
	REMOVE_INTERACTION_COMPLETION,
	ADD_GAME_TIMER_TRIGGER,
	ADD_MASTER_STAGE_TIMER_TRIGGER,
	ADD_STAGE_TIMER_TRIGGER,
	REMOVE_GAME_TIMER_TRIGGER,
	REMOVE_MASTER_STAGE_TIMER_TRIGGER,
	REMOVE_STAGE_TIMER_TRIGGER,
	ADD_TO_TRIGGER_QUEUE,
	REMOVE_FROM_TRIGGER_QUEUE,
	TOGGLE_TRIGGER_QUEUE
} from '../actions/Event';

/*
 * reducers
 */

export function eventEmitters(state = [], action) {
	switch (action.type) {
	case EVENT_EMITTER:
		state.push(action.emitter);
		return state;
	case REMOVE_EMITTER:
		if (action.index !== -1) {
			state.splice(action.index, 1);
		}

		return state;
	case RESET_ALL_EMITTERS:
		state = [];
		EventRegister.removeAllEventListeners();
		return state;
	default:
		return state;
	}
}

export function completionEventTriggers(state = { stageTriggers: [], interactionTriggers: [] }, action) {
	switch (action.type) {
	case ADD_STAGE_COMPLETION:
		state.stageTriggers.push(action.trigger);
		return state;
	case ADD_INTERACTION_COMPLETION:
		state.interactionTriggers.push(action.trigger);
		return state;
	case REMOVE_STAGE_COMPLETION:
		state.stageTriggers.splice(action.index, 1);
		return state;
	case REMOVE_INTERACTION_COMPLETION:
		state.interactionTriggers.splice(action.index, 1);
		return state;
	case RESET_ALL_EMITTERS:
		state = { stageTriggers: [], interactionTriggers: [] };
		return state;
	default:
		return state;
	}
}

export function timerEventTriggers(state = { gameTimeTriggers: [], masterStageTimeTriggers: [], stageTimeTriggers: [] }, action) {
	switch (action.type) {
	case ADD_GAME_TIMER_TRIGGER:
		state.gameTimeTriggers.push(action.trigger);
		return state;
	case ADD_MASTER_STAGE_TIMER_TRIGGER:
		state.masterStageTimeTriggers.push(action.trigger);
		return state;
	case ADD_STAGE_TIMER_TRIGGER:
		state.stageTimeTriggers.push(action.trigger);
		return state;
	case REMOVE_GAME_TIMER_TRIGGER:
		state.gameTimeTriggers.splice(action.index, 1);
		return state;
	case REMOVE_MASTER_STAGE_TIMER_TRIGGER:
		state.masterStageTimeTriggers.splice(action.index, 1);
		return state;
	case REMOVE_STAGE_TIMER_TRIGGER:
		state.stageTimeTriggers.splice(action.index, 1);
		return state;
	case RESET_ALL_EMITTERS:
		state = { gameTimeTriggers: [], masterStageTimeTriggers: [], stageTimeTriggers: [] };
		return state;
	default:
		return state;
	}
}

export function triggerQueue(state = { triggers: [], runQueue: false }, action) {
	switch (action.type) {
	case ADD_TO_TRIGGER_QUEUE:
		state.triggers.push(action.newTrigger);
		return state;
	case REMOVE_FROM_TRIGGER_QUEUE:
		state.triggers.shift();
		return state;
	case TOGGLE_TRIGGER_QUEUE:
		state.runQueue = action.runQueue;
		return state;
	default:
		return state;
	}
}
