import React, { Component } from 'react';
import '../App.css';

// third party resources
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// custom components
import Styles from '../lib/Styles';
import Header from '../components/experience_scene/TopContainer';
import Body from '../components/experience_scene/MiddleContainer';
import Footer from '../components/experience_scene/BottomContainer';
import UniversalModal from '../components/UniversalModal';
import ConfirmationModal from '../components/ConfirmationModal';
import Notification from '../components/Notification';
import FloatingButtonPanel from '../components/FloatingButtonPanel';

// declare local variables
const styles = Styles.scenes.expScene;

class Experience extends Component {
	render() {
		const { gameStyles, screenDimensions, windowState } = this.props;

		return (
			<div style={Object.assign({}, styles.container, { backgroundImage: `url(${gameStyles.background})` })}>
				<Header {...this.props} />
				<Body {...this.props} />
				{screenDimensions.size === 'small' && windowState !== 'master_stage' ? <Footer {...this.props} /> : <div />}
				<UniversalModal {...this.props} />
				<ConfirmationModal {...this.props} />
				<FloatingButtonPanel {...this.props} />
				<Notification {...this.props} />
			</div>
		);
	}
}

/*
 * Redux: hooks the state in the default the container, so all the
 * children components can access them as props.
 */
const mapStateToProps = (state) => {
	return {
		historyState: state.historyState,
		screenDimensions: state.screenDimensions,
		gameData: state.gameData,
		gameStyles: state.gameStyles,
		userData: state.userData,
		windowState: state.windowState,
		currentMasterStageIndex: state.currentMasterStageIndex,
		currentStageIndex: state.currentStageIndex,
		currentInteractionIndex: state.currentInteractionIndex,
		gameOver: state.gameOver,
		topContainerName: state.topContainerName,
		gameFeedback: state.gameFeedback,
		showBackButton: state.showBackButton,
		currentMapImages: state.currentMapImages,
		showProgressBar: state.showProgressBar,
		stageProgressBar: state.stageProgressBar,

		// timer specific
		mountedTimers: state.mountedTimers,
		timerIntervals: state.timerIntervals,
		gameTimerCount: state.gameTimerCount,
		masterStageTimerCount: state.masterStageTimerCount, // reducers/Timers.js
		stageTimerCount: state.stageTimerCount, // reducers/Timers.js
		primaryTimerProps: state.primaryTimerProps, // reducers/Timers.js
		secondaryTimerProps: state.secondaryTimerProps, // reducers/Timers.js
		allTimersPaused: state.allTimersPaused,
		timerExpired: state.timerExpired,
		interactionTimerCount: state.interactionTimerCount,

		// interaction specific
		interactionSettings: state.interactionSettings,
		interactionAttempts: state.interactionAttempts,
		submitLocked: state.submitLocked,
		ignoreSubmitLock: state.ignoreSubmitLock,
		showRewardLabel: state.showRewardLabel,
		currentHints: state.currentHints,
		interactionImageSelection: state.interactionImageSelection,
		surveyOptions: state.surveyOptions,
		interactionSurveySelection: state.interactionSurveySelection,
		currentAnswers: state.currentAnswers,

		// user specific
		userNotesText: state.userNotesText,

		// network
		leaderboard: state.leaderboard,

		// modal
	  	showModal: state.showModal,
	  	modalProps: state.modalProps,
	  	universalModalHeader: state.universalModalHeader,
	  	modalTabIndex: state.modalTabIndex,
	  	modalTabLabels: state.modalTabLabels,

	  	// confirmation modal
	  	showConfirmationModal: state.showConfirmationModal,
	  	confirmationModalProps: state.confirmationModalProps,

	  	// notification
	  	notificationProps: state.notificationProps,
	  	notificationArray: state.notificationArray,
	  	showNotification: state.showNotification,
	  	// floatingBtnPanel
	  	floatingBtnPanelProps: state.floatingBtnPanelProps,
	  	floatingButtons: state.floatingButtons,

	  	// customNumberpad
	  	storedNumbers: state.storedNumbers,
	  	keypadMode: state.keypadMode,
	  	pincode: state.pincode
	};
};

export default withRouter(connect(mapStateToProps)(Experience));
