import React, { Component } from 'react';

// third party resources imports
import {
	Button,
	List,
	ListItem,
	ListItemText,
	TextField
} from '@material-ui/core';
import {
	Add as AddIcon,
	ArrowBack as BackIcon
} from '@material-ui/icons';

// custom components
import WYSIWYGRenderer from './WYSIWYGRenderer';
import Styles from '../lib/Styles';

const styles = Styles.components.notesModal;

export default class NotesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			noteState: 'none',
			noteContent: '',
			userNoteTitle: '',
			userNotesText: ''
		};
	}

	renderNoteCreationView() {
		const { gameStyles } = this.props;

		return (
			<div style={styles.noteCreationContainer}>
				<div style={styles.newNoteTitleContainer}>
					<TextField
						style={styles.newNoteTitleInput}
						label='Title'
						placeholder='Title'
						onChange={userNoteTitle =>
							this.setState({ userNoteTitle: userNoteTitle.target.value })
						}
						value={this.state.userNoteTitle}
					/>
				</div>
				<div style={styles.newNoteContentInputContainer}>
					<TextField
						style={styles.newNoteContentInput}
						label='Notes'
						placeholder='Notes'
						onChange={userNotesText =>
							this.setState({ userNotesText: userNotesText.target.value })
						}
						value={this.state.userNotesText}
						multiline
						rowsMax={10}
					/>
				</div>
				<div style={styles.newNoteButtonContainer}>
					<Button
						style={{ backgroundColor: 'lightgrey' }}
						variant='contained'
						onClick={() => {
							this.setState({
								noteState: 'none',
								userNoteTitle: '',
								userNotesText: '',
								userNotesLength: this.state.userNotesLength > 0 ? this.state.userNotesLength - 1 : 0,
							});
						}}
					>
						CANCEL
					</Button>
					<Button
						style={{ backgroundColor: gameStyles.secondary.color }}
						variant='contained'
						onClick={() => {
							if (this.state.userNoteTitle.length > 0 && this.state.userNotesText.length > 0) {
								this.props.createNote(this.state.userNoteTitle, this.state.userNotesText, true);

								this.setState({
									noteState: 'none',
									userNoteTitle: '',
									userNotesText: '',
									userNotesLength: this.state.userNotesLength + 1
								});
							}
						}}
					>
						CREATE
					</Button>
				</div>
			</div>
		);
	}

	renderNotesList() {
		const { data } = this.props;

		let noteContent = <div />;

		if (data.length > 0) {
			noteContent = data.map((item, index) => {
				return (
					<ListItem button style={Object.assign({}, styles.notesItem, { backgroundColor: (index % 2 ? 'white' : 'whitesmoke') })} key={index} onClick={() => {
						this.setState({
							noteState: 'display',
							noteContent: item.content.notes
						});
					}}>
						<ListItemText style={Object.assign({}, styles.notesItemText, { flex: 2 })} primary={item.content.title} />
					</ListItem>
				);
			});
		}

		return noteContent;
	}

	renderListContent() {
		let listContent = <div style={{ display: 'flex', flex: 0 }} />;

		if (this.state.noteState !== 'create') {
			listContent = (
				<List style={Object.assign({}, styles.listContainer, this.props.style)}>
					{this.renderNotesList()}
				</List>
			);
		}

		return listContent;
	}

	renderNoteContent() {
		switch (this.state.noteState) {
		case 'create':
			return this.renderNoteCreationView();
		case 'display':
			return <WYSIWYGRenderer style={styles.container} html={this.state.noteContent} />;
		default:
			return <WYSIWYGRenderer style={styles.container} html={''} />;
		}
	}

	render() {
		return (
			<div style={styles.container}>
				<div style={styles.topContainer}>
					<Button
						variant='contained'
						color='secondary'
						startIcon={<AddIcon />}
						onClick={() => {
							this.setState({	noteState: 'create'	});
						}}
					>
						New Note
					</Button>
				</div>
				<div style={styles.notesContainer}>
					{this.renderListContent()}
					<div style={styles.contentContainer}>
						{this.renderNoteContent()}
					</div>
				</div>
			</div>
		);
	}
}
