import React, { Component } from 'react';

// third party resources imports
import InnerImageZoom from 'react-inner-image-zoom';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

export default class ZoomableImage extends Component {
	render() {
		return (
			<div style={Object.assign({}, { display: 'flex', flex: 1 }, this.props.style)}>
				<InnerImageZoom style={this.props.imgStyle} src={this.props.imgSrc} />
			</div>
		);
	}
}
