// action types
import {
	SET_KEYPAD_NUMBERS
} from '../actions/CustomNumberpad';

import {
	UPDATE_MODAL_PROPS,
	RESET_MODAL_PROPS
} from '../actions/UniversalModal';

/*
 * reducers
 */

export function storedNumbers(state = [], action) {
	switch (action.type) {
	case SET_KEYPAD_NUMBERS:
		state = action.newStoredNumbers;
		return state;
	case RESET_MODAL_PROPS:
		state = [];
		return state;
	default:
		return state;
	}
}

/* ----- Start values assigned when Universal Modal is updated to use keypad ----- */
export function keypadMode(state = '', action) {
	switch (action.type) {
	case UPDATE_MODAL_PROPS:
		if (action.screenType === 'keypad') {
			state = action.data.keypadMode;
		}
		return state;
	default:
		return state;
	}
}

export function pincode(state = '', action) {
	switch (action.type) {
	case UPDATE_MODAL_PROPS:
		if (action.data.keypadMode === 'passcode') {
			state = action.data.unlockPin;
		}
		return state;
	default:
		return state;
	}
}

/* ----- End values assigned when Universal Modal is updated to use keypad ----- */
