import GLOBAL from '../lib/Globals';
/* import action types */
import {
	INIT_USER_DATA,
	SET_REGISTRATION_STATE,
	SET_TEAM_NAME_TEXT,
	SET_TEAM_NAME,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_EMAIL,
	SET_AGE,
	SET_GENDER,
	ADD_TEAM_MEMBER,
	EDIT_TEAM_MEMBER,
	REMOVE_TEAM_MEMBER,
	SET_REGO_ERROR,
	SELECTED_MEMBER_INDEX,
	SET_TEAM_PHOTO,
	USER_START_TIME,
	CREATE_NOTE,
	ADD_POINTS,
	UNLOCK_HINT,
	SET_USER_NOTE_TEXT,
	PROCESS_USERDATA,
	UPDATE_PROCESSED_USERDATA,
	PROCESS_RESULTS,
	RETRIEVE_PREV_USERDATA,
	RESUME_PREVIOUS_PROGRESS
} from '../actions/User';

import {
	START_MASTER_STAGE,
	START_STAGE,
	START_INTERACTION,
	UPDATE_INTERACTION_VALUES,
	UNLOCK_INTERACTION,
	UPDATE_STAGE_VALUES,
	UNLOCK_STAGE,
	UPDATE_MASTER_STAGE_VALUES
} from '../actions/Experience';

import {
	SET_WINDOW_STATE,
	SET_GAME_OVER
} from '../actions/Game';

import {
	PROCESS_INTERACTION_ANSWER
} from '../actions/Interaction';

import {
	GAME_TIMER_RUN,
	MASTER_STAGE_TIMER_RUN,
	STAGE_TIMER_RUN,
	INTERACTION_TIMER_STOP,
	MODIFY_TIMER_VALUE,
	PROCESS_TIMER_EXPIRY
} from '../actions/Timers';

import {
	EVENT_UNLOCK
} from '../actions/Event';

import {
	SUBSCRIBE_USER
} from '../actions/Network';

import {
	RESET_MODAL_PROPS
} from '../actions/UniversalModal';

/* reducers */

export function userData(state = {}, action) {
	// saving userdata to storage
	switch (action.type) {
	case INIT_USER_DATA:
		state = action.userData;
		updateStoredUserData(state);
		return state;
	case SET_TEAM_NAME:
		state.userDetails.teamName = action.teamName;
		updateStoredUserData(state);
		return state;
	case ADD_TEAM_MEMBER:
		let currentTeam = state.userDetails.teamMembers;

		currentTeam.push(action.newMember);
		state.userDetails.teamMembers = currentTeam;
		updateStoredUserData(state);
		return state;
	case EDIT_TEAM_MEMBER:
		let updatedTeam = state.userDetails.teamMembers;

		updatedTeam.[action.index] = action.member;
		state.userDetails.teamMembers = updatedTeam;
		updateStoredUserData(state);
		return state;
	case REMOVE_TEAM_MEMBER:
		let team = state.userDetails.teamMembers.filter((item, index) => index !== action.index);
		// this is the way Redux expects to be used. Because Redux only does shallow comparrisons, we need to provide a new copy of the userData object when we update any part of it to trigger a re-render
		let newState = {
	    ...state,
	    userDetails: {
	      ...state.userDetails,
	      teamMembers: team
	    }
	  };

		return newState;
	case SET_TEAM_PHOTO:
		state.userDetails.teamPhoto = action.teamPhotoLocation;
		updateStoredUserData(state);
		return state;
	case SET_WINDOW_STATE:
		state.position.window_state = action.windowState;

		if (state.position.scene !== 'pre-experience') {
			updateStoredUserData(state);
		}
		return state;
	case USER_START_TIME:
		state.startTime = action.startTime;
		state.displayStartTime = action.displayStartTime;
		state.position.scene = action.scene;
		updateStoredUserData(state);
		return state;
	case START_MASTER_STAGE:
	case START_STAGE:
	case START_INTERACTION:
		state.position.window_state = action.windowState;
		state.position.previous = action.prevPos;
		state.position.current = action.currentPos;

		if (action.scene) {
			state.position.scene = action.scene;
		}

		updateStoredUserData(state);
		return state;
	case PROCESS_INTERACTION_ANSWER:
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[state.position.current.interaction].correct = action.correct;
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[state.position.current.interaction].answer = action.answer;
		updateStoredUserData(state);
		return state;
	case UPDATE_INTERACTION_VALUES:
		state.master_stage[action.loc.mStg].stage[action.loc.stg].puzzle[action.loc.int].completed = action.completed;
		state.master_stage[action.loc.mStg].stage[action.loc.stg].puzzle[action.loc.int].locked = action.locked;
		state.master_stage[action.loc.mStg].stage[action.loc.stg].puzzle[action.loc.int].attempts = action.attempts;
		state.master_stage[action.loc.mStg].stage[action.loc.stg].completionPoints = action.interactionsCompleted;
		updateStoredUserData(state);
		return state;
	case UNLOCK_INTERACTION:
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[action.interactionIndex].locked = false;
		updateStoredUserData(state);
		return state;
	case UPDATE_STAGE_VALUES:
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].completed = action.completed;
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].locked = action.locked;
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].completionPoints = action.stgCompletionPoints;
		state.master_stage[state.position.current.master_stage].completionPoints = action.mStgCompletionPoints;
		updateStoredUserData(state);
		return state;
	case UNLOCK_STAGE:
		state.master_stage[state.position.current.master_stage].stage[action.stageIndex].locked = false;
		updateStoredUserData(state);
		return state;
	case UPDATE_MASTER_STAGE_VALUES:
		state.master_stage[state.position.current.master_stage].completionPoints = action.completionPoints;
		state.master_stage[state.position.current.master_stage].completed = action.completed;

		updateStoredUserData(state);
		return state;
	case INTERACTION_TIMER_STOP:
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[state.position.current.interaction].timeSpent += action.time;
		updateStoredUserData(state);
		return state;
	case GAME_TIMER_RUN:
		state.gameTimer = action.time;
		return state;
	case MASTER_STAGE_TIMER_RUN:
		state.master_stage[state.position.current.master_stage].masterStageTimeLeft = action.time;
		return state;
	case STAGE_TIMER_RUN:
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].stageTimeLeft = action.time;
		return state;
	case PROCESS_TIMER_EXPIRY:
		if (action.timer === 'stageTimer') {
			state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].expired = true;
		}
		if (action.timer === 'masterStageTimer') {
			state.master_stage[state.position.current.master_stage].expired = true;
		}
		updateStoredUserData(state);
		return state;
	case CREATE_NOTE:
		let newNote = action.newNote;
		newNote.id = state.notes.length;

		state.notes.push(newNote);
		updateStoredUserData(state);
		return state;
	case ADD_POINTS:
		if (state.master_stage[state.position.current.master_stage].stage[state.position.current.stage] !== undefined) {
			state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].stageTotalPoints += action.value;

			if (state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[state.position.current.interaction] !== undefined) {
				state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[state.position.current.interaction].puzzlePoints += action.value;
			}
		}

		state.master_stage[state.position.current.master_stage].masterStageTotalPoints += action.value;
		state.totalPoints += action.value;
		updateStoredUserData(state);
		return state;
	case UNLOCK_HINT:
		state.master_stage[state.position.current.master_stage].stage[state.position.current.stage].puzzle[state.position.current.interaction].puzzleHints[action.hintIndex].purchased = true;
		state.hints_used++;
		return state;
	case MODIFY_TIMER_VALUE:
		switch (action.timerType) {
		case 'gameTimer':
			state.gameTimer = action.value;
			break;
		case 'mStgTimer':
			state.master_stage[action.loc.mStgIndex].masterStageTimeLeft = action.value;
			break;
		case 'stgTimer':
			state.master_stage[action.loc.mStgIndex].stage[action.loc.stgIndex].stageTimeLeft = action.value;
			break;
		default:
			console.log('MODIFY_TIMER_VALUE userData - ' + action.timerType + ' does not exist');
			break;
		}

		return state;
	case RETRIEVE_PREV_USERDATA:
		let storedUserData = GLOBAL.getStoredData('USER_DATA');
		state = storedUserData[storedUserData.length - 1];
		return state;
	case RESUME_PREVIOUS_PROGRESS:
		state = action.userData;
		return state;
	case EVENT_UNLOCK:
		if (action.area === 'stage') {
			state.master_stage[action.loc.mStgIndex].stage[action.loc.stgIndex].locked = false;
		} else {
			state.master_stage[action.loc.mStgIndex].stage[action.loc.stgIndex].puzzle[action.loc.intIndex].locked = false;
		}
		return state;
	case SUBSCRIBE_USER:
		state.userDetails.teamMembers[action.userIndex].subscribed = true;
		return state;
	case SET_GAME_OVER:
		state.finishTime = Date.now();
		return state;
	default:
		return state;
	}
}

function updateStoredUserData(newData) {
	let storedUserDataArray = GLOBAL.getStoredData('USER_DATA');
	let userDataArray = storedUserDataArray ? storedUserDataArray : [];
	let newDataToStore = true;

	for (let i = 0; i < userDataArray.length; i++) {
		if (userDataArray[i].resultsID === newData.resultsID) {
			userDataArray[i] = newData;
			newDataToStore = false;
			break;
		}
	}

	if (newDataToStore) {
		userDataArray.push(newData);
	}

	GLOBAL.storeData('USER_DATA', userDataArray);
}

export function registrationState(state = 'team_name', action) {
	switch (action.type) {
	case SET_REGISTRATION_STATE:
		state = action.registrationState;

		return state;
	default:
		return state;
	};
}

export function teamName(state = '', action) {
	switch (action.type) {
	case SET_TEAM_NAME_TEXT:
		state = action.text;
		return state;
	default:
		return state;
	}
}

export function newUserFirstName(state = '', action) {
	switch (action.type) {
	case SET_FIRST_NAME:
		state = action.input;
		return state;
	case ADD_TEAM_MEMBER:
	case EDIT_TEAM_MEMBER:
	case RESET_MODAL_PROPS:
		state = '';
		return state;
	default:
		return state;
	}
}

export function newUserLastName(state = '', action) {
	switch (action.type) {
	case SET_LAST_NAME:
		state = action.input;
		return state;
	case ADD_TEAM_MEMBER:
	case EDIT_TEAM_MEMBER:
	case RESET_MODAL_PROPS:
		state = '';
		return state;
	default:
		return state;
	}
}

export function newUserEmail(state = '', action) {
	switch (action.type) {
	case SET_EMAIL:
		state = action.input;
		return state;
	case ADD_TEAM_MEMBER:
	case EDIT_TEAM_MEMBER:
	case RESET_MODAL_PROPS:
		state = '';
		return state;
	default:
		return state;
	}
}

export function newUserAge(state = '', action) {
	switch (action.type) {
	case SET_AGE:
		state = action.input;
		return state;
	case ADD_TEAM_MEMBER:
	case EDIT_TEAM_MEMBER:
	case RESET_MODAL_PROPS:
		state = '';
		return state;
	default:
		return state;
	}
}

export function newUserGender(state = '', action) {
	switch (action.type) {
	case SET_GENDER:
		state = action.input;
		return state;
	case ADD_TEAM_MEMBER:
	case EDIT_TEAM_MEMBER:
	case RESET_MODAL_PROPS:
		state = '';
		return state;
	default:
		return state;
	}
}

export function selectedMemberIndex(state = null, action) {
	switch (action.type) {
	case SELECTED_MEMBER_INDEX:
		state = action.index;
		return state;
	case ADD_TEAM_MEMBER:
	case EDIT_TEAM_MEMBER:
	case RESET_MODAL_PROPS:
		state = null;
		return state;
	default:
		return state;
	}
}

export function userNotesText(state = '', action) {
	switch (action.type) {
	case SET_USER_NOTE_TEXT:
		state = action.note;
		return state;
	case CREATE_NOTE:
		state = '';
		return state;
	default:
		return state;
	}
}

export function processedUserData(state = {}, action) {
	switch (action.type) {
	case PROCESS_USERDATA:
		state = action.processedUserData;
		return state;
	case UPDATE_PROCESSED_USERDATA:
		state.sent = action.sent;
		return state;
	default:
		return state;
	}
}

export function endResults(state = { perSolvedInt: 0, perHintsUsed: 0, perTimeSpent: 0, perUserPoints: 0 }, action) {
	switch (action.type) {
	case PROCESS_RESULTS:
		state = action.endResults;
		return state;
	default:
		return state;
	}
}

export function currentAnswers(state = { prevAnswers: [], headers: [], stickyIndices: [0] }, action) {
	switch (action.type) {
	case UPDATE_INTERACTION_VALUES:
		let newStickyIndices = [];
		let storedAnswers = state.prevAnswers;
		let currentHeaders = state.headers;
		let headerExists = false;
		let userAnswer = {
			id: action.loc.mStg + '_' + action.loc.stg + '_' + action.loc.int + '_' + 'answer',
			location: action.loc,
			puzzleName: action.interactionName,
			puzzlePoints: action.interactionPoints,
			answer: action.answer,
			correct: action.correct
		};

		if (Array.isArray(action.answer)) {
			userAnswer.answer = action.answer.join(', ');
		}

		// handle sticky header assignment here
		for (let i = 0; i < currentHeaders.length; i++) {
			if (currentHeaders[i].stageName === action.stageName) {
				headerExists = true;
				break;
			}
		}

		if (!headerExists) {
			let newIndex = storedAnswers.length;
			let headerObj = {
				id: newIndex + '_Sticky',
				location: { mStg: action.loc.mStg, stg: action.loc.stg, int: -1 },
				stageName: action.stageName
			};

			state.prevAnswers.push(headerObj);
			state.headers.push(headerObj); // only needed so we don't add multiples of the same stage title down
			state.stickyIndices.push(newIndex);
		}

		let prevAnswerIndex = storedAnswers.findIndex(prevAnswer => prevAnswer.id === userAnswer.id);

		if (prevAnswerIndex === -1) {
			state.prevAnswers.push(userAnswer);

			state.prevAnswers.sort(function (a, b) {
				if (a.location.mStg === b.location.mStg) {
					if (a.location.stg === b.location.stg) {
						return (a.location.int < b.location.int) ? -1 : (a.location.int > b.location.int) ? 1 : 0;
					} else {
						return (a.location.stg < b.location.stg) ? -1 : 1;
					}
				} else {
					return (a.location.mStg < b.location.mStg) ? -1 : 1;
				}
			});

			for (let i = 0; i < storedAnswers.length; i++) {
				if (storedAnswers[i].id.includes('_Sticky')) {
					newStickyIndices.push(storedAnswers[i].id);
				}
			}

			state.stickyIndices = newStickyIndices;
		} else {
			state.prevAnswers[action.index] = action.lastAnswer;
		}
		return state;
	default:
		return state;
	}
}
