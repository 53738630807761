import React, { Component } from 'react';

// custom components
import Styles from '../lib/Styles';

const styles = Styles.components.wysiwygRenderer;

export default class WYSIWYGRenderer extends Component {
	renderWYSIWYGWithBGImage() {
		return (
			<div style={Object.assign({}, styles.wysiwygBackground, this.props.bgStyle, { backgroundImage: `url(${this.props.bgImage}` })}>
				{this.props.html && this.props.html.length > 0 ? this.renderHTML() : <div />}
			</div>
		);
	}

	renderHTML() {
		return (
			this.props.html && this.props.html.length > 0 ? <div style={Object.assign({}, styles.wysiwygHTML, this.props.htmlStyle)} dangerouslySetInnerHTML={{ __html: this.props.html }} /> : <div />
		);
	}

	render() {
		return (
			<div style={Object.assign({}, styles.container, this.props.containerStyle)}>
				{this.props.bgImage && this.props.bgImage.length > 0 ? this.renderWYSIWYGWithBGImage() : this.renderHTML()}
			</div>
		);
	}
}
