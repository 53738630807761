// third party resources imports
import * as gameActions from './Game';
import * as experienceActions from './Experience';
import * as userActions from './User';
import * as eventsActions from './Event';
import * as uniModalActions from './UniversalModal';

export const TIMER_MOUNTED = 'TIMER_MOUNTED';
export const TIMER_UNMOUNTED = 'TIMER_UNMOUNTED';
export const GAME_TIMER_START = 'GAME_TIMER_START';
export const GAME_TIMER_STOP = 'GAME_TIMER_STOP';
export const MASTER_STAGE_TIMER_START = 'MASTER_STAGE_TIMER_START';
export const MASTER_STAGE_TIMER_STOP = 'MASTER_STAGE_TIMER_STOP';
export const STAGE_TIMER_START = 'STAGE_TIMER_START';
export const STAGE_TIMER_STOP = 'STAGE_TIMER_STOP';
export const SET_GAME_TIMER = 'SET_GAME_TIMER';
export const GAME_TIMER_RUN = 'GAME_TIMER_RUN';
export const SET_MASTER_STAGE_TIMER = 'SET_MASTER_STAGE_TIMER';
export const MASTER_STAGE_TIMER_RUN = 'MASTER_STAGE_TIMER_RUN';
export const SET_STAGE_TIMER = 'SET_STAGE_TIMER';
export const STAGE_TIMER_RUN = 'STAGE_TIMER_RUN';
export const ASSIGN_PRIMARY_TIMER_PROPS = 'ASSIGN_PRIMARY_TIMER_PROPS';
export const ASSIGN_SECONDARY_TIMER_PROPS = 'ASSIGN_SECONDARY_TIMER_PROPS';
export const PAUSE_ALL_TIMERS = 'PAUSE_ALL_TIMERS';
export const PROCESS_TIMER_EXPIRY = 'PROCESS_TIMER_EXPIRY';
export const INTERACTION_TIMER_START = 'INTERACTION_TIMER_START';
export const INTERACTION_TIMER_STOP = 'INTERACTION_TIMER_STOP';
export const INTERACTION_TIMER_RUN = 'INTERACTION_TIMER_RUN';
export const MODIFY_TIMER_VALUE = 'MODIFY_TIMER_VALUE';

// called when experience is started
export function startGameTimer() {
	return (dispatch, getState) => {
		// get the game time and assign it to the game timer value
		if (getState().gameData.extra_settings.includes('time_restriction')) {
			if (!getState().mountedTimers.gameTimer) {
				dispatch(timerMounted('gameTimer'));
			}

			dispatch(setGameTimer(getState().userData.gameTimer));
			dispatch(runGameTimer());
		}
	};
}

export function pauseAllTimers(bool) {
	return (dispatch, getState) => {
		if (bool) {
			dispatch(stopAllTimers());
		} else {
			dispatch(startGameTimer());
			dispatch(updateMasterStageTimer());
			dispatch(updateStageTimer());

			if (getState().windowState === 'interaction') {
				dispatch(runInteractionTimer());
			}
		}

		dispatch({
			type: PAUSE_ALL_TIMERS,
			bool: bool
		});
	};
}

export function updateMasterStageTimer() {
	return (dispatch, getState) => {
		let userData = getState().userData;

		// non state variables
		let masterStageTime = userData.master_stage[getState().currentMasterStageIndex].masterStageTimeLeft;

		if (masterStageTime > 0) {
			if (getState().mountedTimers.gameTimer) {
				if (masterStageTime > getState().userData.gameTimer) {
					masterStageTime = getState().userData.gameTimer - 1;
				}
			}

			dispatch(setMasterStageTimer(masterStageTime));
			dispatch(runMasterStageTimer());

			if (!getState().mountedTimers.msTimer) {
				dispatch(timerMounted('msTimer'));
			}
		} else {
			if (getState().mountedTimers.msTimer) {
				dispatch(stopMasterStageTimer());
				dispatch(timerUnmounted('msTimer'));
			}
		}
	};
}

export function updateStageTimer() {
	return (dispatch, getState) => {
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;

		if (cSI !== null) {
			let stageTimerRestriction = userData.master_stage[cMSI].stage[cSI].stageTimeLeft;

			if (stageTimerRestriction > 0) {
				if (getState().mountedTimers.gameTimer) {
					if (stageTimerRestriction > userData.gameTimer) {
						stageTimerRestriction = userData.gameTimer - 1;
					}
				}

				if (getState().mountedTimers.msTimer) {
					if (stageTimerRestriction > userData.master_stage[cMSI].masterStageTimeLeft) {
						stageTimerRestriction = userData.master_stage[cMSI].masterStageTimeLeft - 1;
					}
				}

				dispatch(setStageTimer(stageTimerRestriction));
				dispatch(timerMounted('sTimer'));
				dispatch(runStageTimer());
			} else {
				if (getState().mountedTimers.sTimer) {
					dispatch(stopStageTimer());
					dispatch(timerUnmounted('sTimer'));
				}
			}
		} else {
			if (getState().mountedTimers.sTimer) {
				dispatch(stopStageTimer());
				dispatch(timerUnmounted('sTimer'));
			}
		}
	};
}

export function timerMounted(timer) {
	return (dispatch, getState) => {
		// non state variables
		let newMountedTimers = getState().mountedTimers;

		newMountedTimers[timer] = true;

		dispatch({
			type: TIMER_MOUNTED,
			mountedTimer: newMountedTimers
		});

		dispatch(updateTimerBoxes(timer));
	};
}

export function timerUnmounted(timer) {
	return (dispatch, getState) => {
		let mountedTimers = getState().mountedTimers;

		// non state variables
		let newUnmountedTimers = mountedTimers;
		newUnmountedTimers[timer] = false;

		dispatch({
			type: TIMER_UNMOUNTED,
			unmountedTimer: newUnmountedTimers
		});

		dispatch(updateTimerBoxes(timer));
	};
}

export function setGameTimer(time) {
	return {
		type: SET_GAME_TIMER,
		time: time
	};
}

export function runGameTimer() {
	return (dispatch, getState) => {
		if (getState().timerIntervals.gameInterval === null) {
			const gameTimerCounter = setInterval(() => {
				dispatch({
					type: GAME_TIMER_RUN,
					time: getState().gameTimerCount - 1
				});

				if (getState().gameTimerCount <= 0) {
					dispatch(stopGameTimer());
					dispatch(processTimerExpiry('gameTimer'));
				}

				if (getState().timerEventTriggers.gameTimeTriggers) {
					dispatch(eventsActions.checkTimeTriggerValues('gameTime', getState().gameTimerCount));
				}
			}, 1000);

			dispatch({
				type: GAME_TIMER_START,
				interval: gameTimerCounter
			});
		}
	};
}

export function stopGameTimer() {
	return (dispatch, getState) => {
		clearInterval(getState().timerIntervals.gameInterval);
		dispatch({ type: GAME_TIMER_STOP });
	};
}

export function setMasterStageTimer(time) {
	return {
		type: SET_MASTER_STAGE_TIMER,
		time: time
	};
}

export function runMasterStageTimer() {
	return (dispatch, getState) => {
		if (getState().timerIntervals.masterStageInterval === null) {
			const masterStageTimerCounter = setInterval(() => {
				dispatch({
					type: MASTER_STAGE_TIMER_RUN,
					time: getState().masterStageTimerCount - 1
				});

				if (getState().masterStageTimerCount <= 0) {
					dispatch(processTimerExpiry('masterStageTimer'));
					dispatch(stopMasterStageTimer());
				}

				if (getState().timerEventTriggers.masterStageTimeTriggers) {
					dispatch(eventsActions.checkTimeTriggerValues('msTimer', getState().masterStageTimerCount));
				}
			}, 1000);

			dispatch({
				type: MASTER_STAGE_TIMER_START,
				interval: masterStageTimerCounter
			});
		}
	};
}

export function stopMasterStageTimer() {
	return (dispatch, getState) => {
		clearInterval(getState().timerIntervals.masterStageInterval);
		dispatch({ type: MASTER_STAGE_TIMER_STOP });
	};
}

export function setStageTimer(time) {
	return {
		type: SET_STAGE_TIMER,
		time: time
	};
}

export function runStageTimer() {
	return (dispatch, getState) => {
		if (getState().timerIntervals.stageInterval === null) {
			const stageTimerCounter = setInterval(() => {
				dispatch({
					type: STAGE_TIMER_RUN,
					time: getState().stageTimerCount - 1
				});

				if (getState().stageTimerCount <= 0) {
					dispatch(processTimerExpiry('stageTimer'));
					dispatch(stopStageTimer());
				}

				if (getState().timerEventTriggers.stageTimeTriggers) {
					dispatch(eventsActions.checkTimeTriggerValues('sTimer', getState().stageTimerCount));
				}
			}, 1000);

			dispatch({
				type: STAGE_TIMER_START,
				interval: stageTimerCounter
			});
		}
	};
}

export function stopStageTimer() {
	return (dispatch, getState) => {
		clearInterval(getState().timerIntervals.stageInterval);
		dispatch({ type: STAGE_TIMER_STOP });
	};
}

export function updateTimerBoxes(timer) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let mountedTimers = getState().mountedTimers;
		let primaryTimerProps = getState().primaryTimerProps;
		let secondaryTimerProps = getState().secondaryTimerProps;

		// non state variables
		let primaryTimerTitle = primaryTimerProps.title;
		let primaryTimerParent = primaryTimerProps.timer;
		let secondaryTimerTitle = secondaryTimerProps.title;
		let secondaryTimerParent = secondaryTimerProps.timer;

		switch (timer) {
		case 'gameTimer':
			let i = gameData.extra_settings.indexOf('time_restriction');
			let gameTimerIndex = i + 2;
			let gameTimerTitle = gameData.extra_settings[gameTimerIndex];

			primaryTimerTitle = gameTimerTitle;
			primaryTimerParent = 'gameTimer';
			break;
		case 'msTimer':
			if (parseInt(gameData.master_stages[cMSI].time_restriction) >= 0) {
				let msTimerTitle = gameData.master_stages[cMSI].time_restriction_title;

				if (mountedTimers.gameTimer) {
					secondaryTimerTitle = msTimerTitle;
					secondaryTimerParent = 'msTimer';
				} else {
					primaryTimerTitle = msTimerTitle;
					primaryTimerParent = 'msTimer';
				}
			} else {
				if (primaryTimerProps.timer === 'msTimer') {
					primaryTimerTitle = '';
					primaryTimerParent = null;
				}

				if (secondaryTimerProps.timer === 'msTimer') {
					secondaryTimerTitle = '';
					secondaryTimerParent = null;
				}
			}
			break;
		case 'sTimer':
			if (mountedTimers.sTimer) {
				let stageTimerRestriction = gameData.master_stages[cMSI].stages[cSI].time_restriction;

				if (stageTimerRestriction.length > 0 && parseInt(stageTimerRestriction) >= 0) {
					let stageTimerTitle = gameData.master_stages[cMSI].stages[cSI].time_restriction_title;

					if (mountedTimers.msTimer || mountedTimers.gameTimer) {
						secondaryTimerTitle = stageTimerTitle;
						secondaryTimerParent = 'sTimer';
					} else {
						primaryTimerTitle = stageTimerTitle;
						primaryTimerParent = 'sTimer';
					}
				} else {
					if (primaryTimerProps.timer === 'sTimer') {
						primaryTimerTitle = '';
						primaryTimerParent = null;
					}

					if (secondaryTimerProps.timer === 'sTimer') {
						secondaryTimerTitle = '';
						secondaryTimerParent = null;
					}
				}
			} else {
				if (primaryTimerProps.timer === 'sTimer') {
					primaryTimerTitle = '';
					primaryTimerParent = null;
				}

				if (secondaryTimerProps.timer === 'sTimer') {
					secondaryTimerTitle = '';
					secondaryTimerParent = null;
				}
			}
			break;
		default:
			break;
		}

		if (primaryTimerParent !== primaryTimerProps.timer) {
			dispatch(assignPrimaryTimerProps(primaryTimerTitle, primaryTimerParent));
		}

		if (secondaryTimerParent !== secondaryTimerProps.timer) {
			dispatch(assignSecondaryTimerProps(secondaryTimerTitle, secondaryTimerParent));
		}
	};
}

export function assignPrimaryTimerProps(title, timer) {
	return (dispatch) => {
		dispatch({
			type: ASSIGN_PRIMARY_TIMER_PROPS,
			title: title,
			timer: timer
		});
	};
}

export function assignSecondaryTimerProps(title, timer) {
	return (dispatch) => {
		dispatch({
			type: ASSIGN_SECONDARY_TIMER_PROPS,
			title: title,
			timer: timer
		});
	};
}

// action for timer expiry that happens prior to the feedBackModal being opened
export function processTimerExpiry(timer) {
	return (dispatch, getState) => {
		// let modalProps = getState().modalProps;

		// non state variables

		// dispatch(gameActions.lockSubmitButton(true)); // stop the user from being able to submit their answer when this function is called
		dispatch({
			type: PROCESS_TIMER_EXPIRY,
			timer: timer
		});

		// check required for game over as master stage timer can expire after game timer preventing the app from performing the game over logic if left unchecked
		if (!getState().gameOver) {
			// close any modal that was open last
			if (getState().showModal) {
				dispatch(uniModalActions.closeModal());
			}

			switch (timer) {
			case 'stageTimer':
				dispatch(experienceActions.updateStageValues(false, 'processTimerExpiry'));
				break;
			case 'masterStageTimer':
				dispatch(experienceActions.updateMasterStageValues());
				break;
			case 'gameTimer':
				dispatch(experienceActions.giveGameFeedback('gameOver'));
				break;
			default:
				break;
			}
		}

		// check if last modal was supposed to be showing interaction results, if it was process the interaction
		// if (modalProps.screenType === 'puzzleResult') {
			// dispatch(updateInteractionValues());
		// }
	};
}

export function runInteractionTimer() {
	return (dispatch, getState) => {
		if (getState().timerIntervals.interactionInterval === null) {
			const interactionTimerCounter = setInterval(() => {
				dispatch({
					type: INTERACTION_TIMER_RUN,
					time: getState().interactionTimerCount + 1
				});
			}, 1000);

			dispatch({
				type: INTERACTION_TIMER_START,
				interval: interactionTimerCounter
			});
		}
	};
}

export function stopInteractionTimer() {
	return (dispatch, getState) => {
		clearInterval(getState().timerIntervals.interactionInterval);
		dispatch({
			type: INTERACTION_TIMER_STOP,
			time: getState().interactionTimerCount
		});
	};
}

export function stopAllTimers() {
	return (dispatch, getState) => {
		if (getState().timerIntervals.gameInterval !== null) {
			dispatch(stopGameTimer());
		}

		if (getState().timerIntervals.masterStageInterval !== null) {
			dispatch(stopMasterStageTimer());
		}

		if (getState().timerIntervals.stageInterval !== null) {
			dispatch(stopStageTimer());
		}

		if (getState().timerIntervals.interactionInterval !== null) {
			dispatch(stopInteractionTimer());
		}
	};
}

/* --- Timer modification functions --- */
// eg: (gameTimer, 500), (mStgTimer, 600, {mStg: 1}, true), (stgTimer, 30, {mStg: 2, stg: 0}). If we're not setting a new time, leave the 4th parameter as blank
export function modifyTimerValue(type, value, loc, newValue) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		// non state variable
		let timerType = type;
		let timerValue = value;

		if (!newValue) {
			switch (type) {
			case 'gameTimer':
				timerValue = getState().gameTimerCount + value;
				dispatch(setGameTimer(timerValue));
				break;
			case 'mStgTimer':
				timerValue = getState().masterStageTimerCount + value;

				// check to current loc against parsed loc. If the same update the master stage timer
				if (loc.mStgIndex === getState().currentMasterStageIndex) {
					dispatch(setMasterStageTimer(timerValue));
				}
				break;
			case 'stgTimer':
				timerValue = getState().stageTimerCount + value;
				// check to current loc against parsed loc. If the same update the master stage timer
				if (loc.mStgIndex === getState().currentMasterStageIndex && loc.stgIndex === getState().currentStageIndex) {
					dispatch(setStageTimer(timerValue));
				}
				break;
			case 'reset_gameTimer':
				let gameTimerIndex = gameData.extra_settings.indexOf('time_restriction') + 1;

				timerValue = gameData.extra_settings[gameTimerIndex] * 60;
				timerType = 'gameTimer';
				dispatch(setGameTimer(timerValue));
				break;
			case 'reset_mStgTimer':
				timerValue = gameData.master_stages[loc.mStgIndex].time_restriction * 60;
				timerType = 'mStgTimer';
				// check to current loc against parsed loc. If the same update the master stage timer
				if (loc.mStgIndex === getState().currentMasterStageIndex) {
					dispatch(setMasterStageTimer(timerValue));
				}
				break;
			case 'reset_stgTimer':
				timerValue = gameData.master_stages[loc.mStgIndex].stages[loc.stgIndex].time_restriction * 60;
				timerType = 'stgTimer';
				if (loc.mStgIndex === getState().currentMasterStageIndex && loc.stgIndex === getState().currentStageIndex) {
					dispatch(setStageTimer(timerValue));
				}
				break;
			default:
				// console.log('modifyTimerValue - ' + type + ' does not exist');
				break;
			}
		}

		dispatch({
			type: MODIFY_TIMER_VALUE,
			timerType: timerType,
			value: timerValue,
			loc: loc
		});
	};
}

