// react resources imports
import React, { Component } from 'react';

// third party resources imports
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

// custom resources imports
import Styles from '../lib/Styles';
import Address from '../lib/Addresses';

// declares local variables
const styles = Styles.components.selectionPanel;

export default class SelectionPanel extends Component {
	isLocked(size, index) {
		const { userData, currentMasterStageIndex, currentStageIndex } = this.props;

		let locked = false;
		let objState = null;

		switch (this.props.selectionType) {
		case 'stage':
			objState = userData.master_stage[currentMasterStageIndex].stage[index];
			break;
		case 'interaction':
			objState = userData.master_stage[currentMasterStageIndex].stage[currentStageIndex].puzzle[index];
			break;
		default:
			console.log('isLocked() Selection type: ', this.props.selectionType, ' is not supported with SelectionPanel');
			break;
		}

		if (index + 1 <= size && objState) {
			if (objState.completed) {
				locked = true;
			}

			if (objState.locked) {
				locked = true;
			}
		}

		return locked;
	}

	itemAction(index) {
		const { selectionType, currentMasterStageIndex, currentStageIndex } = this.props;

		switch (selectionType) {
		case 'stage':
			this.props.setUserLocation(currentMasterStageIndex, index);
			break;
		case 'interaction':
			this.props.setUserLocation(currentMasterStageIndex, currentStageIndex, index);
			break;
		default:
			console.log('itemAction(); Selection type: ', selectionType, ' is not supported with SelectionPanel');
			break;
		}
	}

	// The top right icon to display whether item is locked, completed or unlocked
	renderStatusIcon(index) {
		const { selectionType, currentMasterStageIndex, currentStageIndex, userData } = this.props;

		let objState = null;
		let icondiv = <div />;

		switch (selectionType) {
		case 'stage':
			objState = userData.master_stage[currentMasterStageIndex].stage[index];
			break;
		case 'interaction':
			objState = userData.master_stage[currentMasterStageIndex].stage[currentStageIndex].puzzle[index];
			break;
		default:
			console.log('renderStatusIcon() Selection type: ', selectionType, ' is not supported with SelectionPanel');
			break;
		}

		if (objState) {
			if (objState.locked) {
				icondiv = this.renderIcon('rgba(0, 0, 0, 1)', 'lock', 40);
			}

			if (objState.completed) {
				icondiv = this.renderIcon('rgba(21, 184, 0, 1)', 'check', 40);
			}
		}

		return icondiv;
	}

	renderIcon(bgColor, iconType, iconSize) {
		return (
			<div style={Object.assign({}, styles.iconContainer, { backgroundColor: bgColor })}>
				<Icon style={{ fontSize: iconSize, color: 'white' }}>{iconType}</Icon>
			</div>
		);
	}

	renderStageSelectionButtons(selectionArray) {
		const { gameStyles, xs, sm, md } = this.props;

		let buttonArray = [];
		let size = selectionArray.length;

		for (let i = 0; i < selectionArray.length; i++) {
			let buttonImage = selectionArray[i].selection_image ? Address.PARENT + selectionArray[i].selection_image : '/assets/dote-placeholder.png';

			buttonArray.push(
				<Grid item xs={xs ? xs : 12} sm={sm ? sm : 6} md={md ? md : 4}>
					<div style={styles.gridItemContainer}>
						<div style={styles.gridItemImageContainer}>
							<img
								src={buttonImage}
								style={Object.assign({}, styles.gridItemImage, this.props.selectionImageStyle)}
								onClick={() => {
									if (i < size) {
										if (!this.isLocked(size, i)) {
											this.itemAction(i);
										}
									}
								}}
						 	/>
					 		{this.renderStatusIcon(i)}
					 	</div>
						<div style={styles.gridItemDescription}>
							<p style={Object.assign({}, gameStyles.tertiary, { textAlign: 'center', color: 'white' })}>{selectionArray[i].display_title}</p>
						</div>
					</div>
				</Grid>
			);
		}

		return buttonArray;
	}

	render() {
		const { dataArray } = this.props;

		return (
			<div style={styles.container}>
				<div style={styles.gridContainer}>
					<Grid container spacing={1}>
						{this.renderStageSelectionButtons(dataArray)}
					</Grid>
				</div>
			</div>
		);
	}
}
