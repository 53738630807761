import React, { Component } from 'react';
import '../App.css';

// third party resources
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// custom components
import Styles from '../lib/Styles';
import Header from '../components/experience_scene/TopContainer';
import Swiper from '../components/CustomSwiper';
import UniversalModal from '../components/UniversalModal';
import Registration from '../components/pre_experience_scene/Registration';
import Notification from '../components/Notification';

const styles = Styles.scenes.preExperience;

class PreExperience extends Component {
	renderLoading() {
		return (
			<div style={{ display: 'flex', flex: 1 }}>
				<h1>Loading</h1>
			</div>
		);
	}

	renderIntroduction() {
		return (
			<div style={styles.introOutroContainer}>
				<Swiper swiperData={this.props.gameData.intro_screens} {...this.props} />
				<Button variant='contained' color='primary' onClick={() => {
					this.props.progressPreExperience();
				}}>Next</Button>
			</div>
		);
	}

	renderOutroduction() {
		return (
			<div style={styles.introOutroContainer}>
				{this.props.gameData.outro_screens.length > 0 ? <Swiper swiperData={this.props.gameData.outro_screens} {...this.props} /> : <div style={{ display: 'flex', flex: 1 }} />}
				{this.props.gameData.start_pin_code.length === 0 ? <Button style={{ margin: 10 }} variant='contained' color='primary' onClick={() => {
					this.props.startGame();
				}}>Start</Button>
					: <Button variant='contained' color='primary' onClick={() => {
						this.props.openModal('keypad_locked_start');
					}}>Enter Pin Code</Button>
				}
			</div>
		);
	}

	renderWindowState() {
		switch (this.props.windowState) {
		case 'introduction':
			return this.renderIntroduction();
		case 'registration':
			return <Registration {...this.props} />;
		case 'outroduction':
			return this.renderOutroduction();
		default:
			return this.renderLoading();
		}
	}

	// render scene based on window state
	render() {
		const { gameStyles } = this.props;

		return (
			<div style={Object.assign({}, styles.background, { backgroundImage: `url(${gameStyles.background})` })}>
				<Header {...this.props} />
				<div style={{ display: 'flex', flex: 8 }}>
					{this.renderWindowState()}
				</div>
				<UniversalModal {...this.props} />
				<Notification {...this.props} />
			</div>
		);
	}
}

/*
 * Redux: hooks the state in the default the container, so all the
 * children components can access them as props.
 */
const mapStateToProps = (state) => {
	return {
		historyState: state.historyState,
		screenDimensions: state.screenDimensions,
	  	gameData: state.gameData,
	  	gameStyles: state.gameStyles,
	  	userData: state.userData,
	  	windowState: state.windowState,
	  	topContainerName: state.topContainerName,
	  	// network
	  	mailchimpDetails: state.mailchimpDetails,
	  	// registration
	  	registrationState: state.registrationState,
	  	registrationProps: state.registrationProps,
	  	teamName: state.teamName,
	  	newUserFirstName: state.newUserFirstName,
	  	newUserLastName: state.newUserLastName,
	  	newUserEmail: state.newUserEmail,
	  	newUserAge: state.newUserAge,
	  	selectedMemberIndex: state.selectedMemberIndex,
	  	registrationError: state.registrationError,
	  	// modal
	  	showModal: state.showModal,
	  	modalProps: state.modalProps,
	  	universalModalHeader: state.universalModalHeader,
	  	// notification
	  	showNotification: state.showNotification,
	  	notificationProps: state.notificationProps,
	  	notificationArray: state.notificationArray,
	  	// events
	  	eventEmitters: state.eventEmitters,
	  	completionEventTriggers: state.completionEventTriggers,
	  	timerEventTriggers: state.timerEventTriggers,
	  	triggerQueue: state.triggerQueue,
	  	// customNumberpad
	  	storedNumbers: state.storedNumbers,
	  	keypadMode: state.keypadMode,
	  	pincode: state.pincode,
	  	// menu
	  	floatingButtons: state.floatingButtons
	};
};

export default withRouter(connect(mapStateToProps)(PreExperience));
