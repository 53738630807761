// action types
import {
	TIMER_MOUNTED,
	TIMER_UNMOUNTED,
	GAME_TIMER_START,
	GAME_TIMER_STOP,
	MASTER_STAGE_TIMER_START,
	MASTER_STAGE_TIMER_STOP,
	STAGE_TIMER_START,
	STAGE_TIMER_STOP,
	SET_GAME_TIMER,
	GAME_TIMER_RUN,
	SET_MASTER_STAGE_TIMER,
	MASTER_STAGE_TIMER_RUN,
	SET_STAGE_TIMER,
	STAGE_TIMER_RUN,
	ASSIGN_PRIMARY_TIMER_PROPS,
	ASSIGN_SECONDARY_TIMER_PROPS,
	PAUSE_ALL_TIMERS,
	INTERACTION_TIMER_START,
	INTERACTION_TIMER_STOP,
	INTERACTION_TIMER_RUN
} from '../actions/Timers';

/* reducers */

// holds values if timers are mounted or not
export function mountedTimers(state = { gameTimer: false, msTimer: false, sTimer: false }, action) {
	switch (action.type) {
	case TIMER_MOUNTED:
		state = action.mountedTimer;
		return state;
	case TIMER_UNMOUNTED:
		state = action.unmountedTimer;
		return state;
	default:
		return state;
	}
}

// holds the interval objects in redux state for access when we need to stop a timer permanently
export function timerIntervals(state = { gameInterval: null, masterStageInterval: null, stageInterval: null, interactionInterval: null }, action) {
	switch (action.type) {
	case GAME_TIMER_START:
		state.gameInterval = action.interval;
		return state;
	case GAME_TIMER_STOP:
		state.gameInterval = null;
		return state;
	case MASTER_STAGE_TIMER_START:
		state.masterStageInterval = action.interval;
		return state;
	case MASTER_STAGE_TIMER_STOP:
		state.masterStageInterval = null;
		return state;
	case STAGE_TIMER_START:
		state.stageInterval = action.interval;
		return state;
	case STAGE_TIMER_STOP:
		state.stageInterval = null;
		return state;
	case INTERACTION_TIMER_START:
		state.interactionInterval = action.interval;
		return state;
	case INTERACTION_TIMER_STOP:
		state.interactionInterval = null;
		return state;
	default:
		return state;
	}
}

export function gameTimerCount(state = 0, action) {
	switch (action.type) {
	case SET_GAME_TIMER:
		state = action.time;
		return state;
	case GAME_TIMER_RUN:
		state--;
		return state;
	default:
		return state;
	}
}

export function masterStageTimerCount(state = 0, action) {
	switch (action.type) {
	case SET_MASTER_STAGE_TIMER:
		state = action.time;
		return state;
	case MASTER_STAGE_TIMER_RUN:
		state--;
		return state;
	default:
		return state;
	}
}

export function stageTimerCount(state = 0, action) {
	switch (action.type) {
	case SET_STAGE_TIMER:
		state = action.time;
		return state;
	case STAGE_TIMER_RUN:
		state--;
		return state;
	default:
		return state;
	}
}

export function primaryTimerProps(state = { title: '', timer: null }, action) {
	switch (action.type) {
	case ASSIGN_PRIMARY_TIMER_PROPS:
		state = {
			title: action.title,
			timer: action.timer
		};

		return state;
	default:
		return state;
	}
}

export function secondaryTimerProps(state = { title: '', timer: null }, action) {
	switch (action.type) {
	case ASSIGN_SECONDARY_TIMER_PROPS:
		state = {
			title: action.title,
			timer: action.timer
		};
		
		return state;
	default:
		return state;
	}
}

export function allTimersPaused(state = false, action) {
	switch (action.type) {
	case PAUSE_ALL_TIMERS:
		state = action.bool;
		return state;
	default:
		return state;
	}
}

export function interactionTimerCount(state = 0, action) {
	switch (action.type) {
	case INTERACTION_TIMER_START:
		state = 0;
		return state;
	case INTERACTION_TIMER_RUN:
		state++;

		return state;
	default:
		return state;
	}
}
