import React, { Component } from 'react';

// third party resources imports
import {
	Button,
	IconButton,
	Grid,
	Popover
} from '@material-ui/core';

import {
	Info as InfoIcon,
	Map as MapIcon,
	Notes as NotesIcon,
	Replay as PastAnswersIcon,
	Help as HelpIcon
} from '@material-ui/icons';

import { FaTrophy } from 'react-icons/fa';

import Styles from '../lib/Styles';

const styles = Styles.components.floatingButtonPanel;

export default class FloatingButtonPanel extends Component {
	renderIcon(type) {
		switch (type) {
		case 'Leader':
			return <FaTrophy />;
		case 'Notes':
			return <NotesIcon />;
		case 'Maps':
			return <MapIcon />;
		case 'Info':
			return <InfoIcon />;
		case 'Answ':
			return <PastAnswersIcon />;
		case 'Help':
			return <HelpIcon />;
		default:
			return <HelpIcon />;
		}
	}

	renderFloatingButtons(selectionArray) {
		const { gameStyles } = this.props;

		let buttonArray = [];

		for (let i = 0; i < selectionArray.length; i++) {
			if (selectionArray[i].owner === 'all' || selectionArray[i].owner === 'panel_btm') {
				buttonArray.push(
					<Grid
						item
						id={selectionArray[i].id}>
						<IconButton
							style={Object.assign({}, styles.floatingButtonItem, { width: '50px', backgroundColor: gameStyles.secondary.color })}
							variant='contained'
							onClick={() => {
								selectionArray[i].callback();
							}}>
							<div style={styles.floatingButtonIconTextContainer}>
								<div style={{ position: 'absolute', top: 4 }}>
									{this.renderIcon(selectionArray[i].floatingTitle)}
								</div>
								<div style={{ position: 'absolute', top: 16 }}>
									<p style={styles.floatingButtonText}>{selectionArray[i].floatingTitle}</p>
								</div>
							</div>
						</IconButton>
					</Grid>
				);
			}
		}

		return buttonArray;
	}

	render() {
		const { floatingBtnPanelProps } = this.props;
		return (
			<Popover
				style={{ height: '160px', width: '280px' }}
				open={floatingBtnPanelProps.isOpen}
				anchorEl={floatingBtnPanelProps.floatingPanelEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
			>
				<Grid
					style={Object.assign({}, styles.container, { backgroundColor: 'white' })}
					container
					spacing={1}>
					{this.renderFloatingButtons(this.props.floatingButtons)}
				</Grid>
				<Button
					style={{ position: 'absolute', top: 0, right: 0, width: '30px' }}
					onClick={() => {
						this.props.toggleFloatingBtnPanel(false, null);
					}}>X</Button>
			</Popover>
		);
	}
}
