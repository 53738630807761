let isValidJSON = (string) => {
	try {
		return (JSON.parse(string) && !!string);
	} catch (e) {
		return false;
	}
};

let removeAllSpecialCharacters = string => {
	let sanitizedSentence = '';
	// we remove special characters ? . , ! : - and any whitespaces
	sanitizedSentence = string.replace(/[\s'.,:?!-]/g, '');

	return sanitizedSentence;
};

let formatTimeHHMMSS = totalSeconds => {
	let hrs = ~~(totalSeconds / 3600);
	let min = ~~((totalSeconds % 3600) / 60);
	let sec = ~~totalSeconds % 60;
	let displayTime = ('0' + hrs).slice(-2) + ':' + ('0' + min).slice(-2) + ':' + ('0' + sec).slice(-2);

	return displayTime;
};

// ensures that there is no nulls or blanks within an array
let cleanNullsFromArray = (array) => {
	let tempArray = array;

	for (let i = 0; i < tempArray.length; i++) {
		if (tempArray[i] === null || tempArray[i] === '') {
			tempArray.splice(i, 1);
			i--;
		}
	}

	return tempArray;
};

let formatDateTime = (time) => {
	let rawTime = new Date(time);
	let date = rawTime.getDate().toString();
	let month = (rawTime.getMonth() + 1).toString();
	let year = rawTime.getFullYear().toString();
	let hour = rawTime.getHours().toString().slice(-2);
	let minute = rawTime.getMinutes().toString().slice(-2);
	let seconds = rawTime.getSeconds().toString().slice(-2);
	let formattedTime = year + '/' + month + '/' + date + ' ' + hour + ':' + minute + ':' + seconds;

	return formattedTime;
};

let storeData = (type, data) => {
	let newData = JSON.stringify(data);

	localStorage.setItem(type, newData);
};

let getStoredData = (type) => {
	let storedData = JSON.parse(localStorage.getItem(type));

	return storedData;
};

let deleteStoredData = (type) => {
	if (type == 'all') {
		localStorage.clear();
	} else {
		localStorage.removeItem(type);
	}
};

let checkIfObjInArray = (arr, obj, prop) => {
	if (arr.some(arrObj => arrObj[prop] === obj[prop])) {
		return true;
	}

	return false;
};

let numberWithCommas = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

module.exports = {
	isValidJSON,
	removeAllSpecialCharacters,
	formatTimeHHMMSS,
	cleanNullsFromArray,
	formatDateTime,
	storeData,
	getStoredData,
	deleteStoredData,
	checkIfObjInArray,
	numberWithCommas
};
