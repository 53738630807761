import React, { Component } from 'react';

// third party resources imports
import { Button, IconButton, TextField } from '@material-ui/core';
import {
	ArrowBack as BackIcon,
	MoreHoriz as MoreIcon,
	WbIncandescent as HintsIcon
} from '@material-ui/icons';

// custom resources imports
import Styles from '../../lib/Styles';

// declares local variables
const styles = Styles.scenes.bottomSceneContainer;

export default class BottomContainer extends Component {
	renderTextInput() {
		const { gameStyles } = this.props;

		return (
			<TextField
				style={Object.assign({}, styles.footerTextInput, { borderBottom: `1px solid ${gameStyles.secondary.color}` })}
				placeholder='Enter answer'
				onChange={userInput => this.props.setInteractionText(userInput.target.value)}
				value={this.props.interactionTextInput}
				{...this.props}
			/>
		);
	}

	renderFooterComponent() {
		const { gameStyles, interactionAttempts, interactionSettings, windowState } = this.props;

		return (
			<div style={Object.assign({}, styles.footerContainer, { borderTop: `1px solid ${gameStyles.secondary.color}` })}>
				{windowState === 'interaction' && interactionSettings.showTextInputField ? <div style={{ display: 'flex', flex: 1, maxHeight: '40px' }}>
					{this.renderTextInput()}
				</div> : <div />}
				<div style={styles.footerBottomContainer}>
					<div style={styles.footerBottomButtonContainer}>
						{this.props.showBackButton ? <IconButton style={Object.assign({}, styles.footerButtonBase, { backgroundColor: gameStyles.secondary.color })} variant='contained'
							onClick={() => {
								this.props.backButtonPressed();
							}}>
							<BackIcon />
						</IconButton> : <div />}
						{windowState === 'interaction' && interactionSettings.hintsButtonVis ? <IconButton style= {Object.assign({}, styles.footerButtonBase, { width: '50px', backgroundColor: gameStyles.tertiary.color })} variant='contained'
							onClick={() => {
								this.props.openModal('hints');
							}}>
							<HintsIcon style={{ transform: 'scaleY(-1)' }} />
							<p style={{ fontSize: 10 }}>Hints</p>
						</IconButton> : <div />}
						<IconButton
							style={Object.assign({}, styles.footerButtonBase, { width: '50px', border: '1px solid white', borderRadius: '100px' })}
							variant='outlined'
							onClick={(event) => {
								this.props.toggleFloatingBtnPanel(true, event.target);
							}}>
							<MoreIcon />
							<p style={{ fontSize: 10 }}>More</p>
						</IconButton>
					</div>
					{windowState === 'interaction' && interactionAttempts.showAttempts ? <div style={styles.footerBottomAttemptsContainer}>
						<p style={{ flex: 1, color: 'white' }}>{'ATTEMPTS\nLEFT'}</p>
						<h1 style={{ flex: 1, color: 'white' }}>{interactionAttempts.attempts}</h1>
					</div> : <div />}
					{windowState === 'interaction' ? <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
						<Button
							style={{ display: 'flex', flex: 1, height: '50px', backgroundColor: gameStyles.primary.color, color: 'white', borderRadius: '10px' }}
							variant='contained'
							disabled={this.props.submitLocked}
							onClick={() => {
								if (!this.props.submitLocked) {
									if (!this.props.ignoreSubmitLock) {
										this.props.lockSubmitButton(true);
									}
									this.props.submitButtonPressed();
								}
							}}
						>
							<p>{interactionSettings.submitButtonText}</p>
						</Button>
					</div> : <div />}
				</div>
			</div>
		);
	}

	render() {
		const {	windowState	} = this.props;

		return (
			<div style={styles.container}>
				{(windowState === 'stage' || windowState === 'interaction') ? this.renderFooterComponent() : null}
			</div>
		);
	}
}
