import GLOBAL from '../lib/Globals';

// Redux actions
import * as timerActions from './Timers';
import * as userActions from './User';
import * as uniModalActions from './UniversalModal';
import * as notificationActions from './Notifications';

// third party resource imports
import EventRegister from '../lib/Event-Listener';

/*
 * action types
 */
export const EVENT_EMITTER = 'EVENT_EMITTER';
export const REMOVE_EMITTER = 'REMOVE_EMITTER';
export const RESET_ALL_EMITTERS = 'RESET_ALL_EMITTERS';
export const ADD_STAGE_COMPLETION = 'ADD_STAGE_COMPLETION';
export const ADD_INTERACTION_COMPLETION = 'ADD_INTERACTION_COMPLETION';
export const REMOVE_STAGE_COMPLETION = 'REMOVE_STAGE_COMPLETION';
export const REMOVE_INTERACTION_COMPLETION = 'REMOVE_INTERACTION_COMPLETION';
export const ADD_GAME_TIMER_TRIGGER = 'ADD_GAME_TIMER_TRIGGER';
export const ADD_MASTER_STAGE_TIMER_TRIGGER = 'ADD_MASTER_STAGE_TIMER_TRIGGER';
export const ADD_STAGE_TIMER_TRIGGER = 'ADD_STAGE_TIMER_TRIGGER';
export const REMOVE_GAME_TIMER_TRIGGER = 'REMOVE_GAME_TIMER_TRIGGER';
export const REMOVE_MASTER_STAGE_TIMER_TRIGGER = 'REMOVE_MASTER_STAGE_TIMER_TRIGGER';
export const REMOVE_STAGE_TIMER_TRIGGER = 'REMOVE_STAGE_TIMER_TRIGGER';
export const ADD_TO_TRIGGER_QUEUE = 'ADD_TO_TRIGGER_QUEUE';
export const REMOVE_FROM_TRIGGER_QUEUE = 'REMOVE_FROM_TRIGGER_QUEUE';
export const TOGGLE_TRIGGER_QUEUE = 'TOGGLE_TRIGGER_QUEUE';
export const EVENT_UNLOCK = 'EVENT_UNLOCK';

/*
 * action creators
 */

// loops through game data, extracts and assigns all of the event triggers within an experience
export function processExperienceEvents() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		for (let key in gameData) {
			switch (key) {
			case 'experience_events':
				for (let m = 0; m < gameData[key].length; m++) {
					gameData[key][m].level = 'experience';
				}

				dispatch(assignEvents('experience', 'experience_events', gameData));
				break;
			case 'master_stages':
				let mStgArray = gameData[key];

				for (let i = 0; i < mStgArray.length; i++) {
					if (mStgArray[i].mStgEvents !== undefined) {
						// assign the level parameter to each object within each events array <- should do this in the experience builder
						for (let n = 0; n < mStgArray[i].mStgEvents.length; n++) {
							mStgArray[i].mStgEvents[n].level = 'master_stage';
						}

						dispatch(assignEvents('master_stage', 'mStgEvents', mStgArray[i]));
					}

					if (mStgArray[i].stages) {
						let stagesArray = mStgArray[i].stages;

						for (let j = 0; j < stagesArray.length; j++) {
							if (stagesArray[j].stgEvents !== undefined) {
								// assign the level parameter to each object within each events array <- should do this in the experience builder
								for (let o = 0; o < stagesArray[j].stgEvents.length; o++) {
									stagesArray[j].stgEvents[o].level = 'stage';
								}

								dispatch(assignEvents('stage', 'stgEvents', stagesArray[j]));
							}
						}
					}
				}
				break;
			default:
				break;
			}
		}
	};
}

// Event assignment - Called in Loading.js before the Game Data finishes being processed. Loops through the event array, processes each event created and generates the event emitter necessary for all of this to work.
export function assignEvents(level, eventArray, parentObj) {
	return (dispatch, getState) => {
		// non state variables
		let currentEvents = parentObj[eventArray];

		for (let i = 0; i < currentEvents.length; i++) {
			let event = currentEvents[i];

			// send to relevant component an emitter obj for it to babysit until the event conditions have been satisfied
			event.id = EventRegister.addEventListener(event.title, (e) => {
				dispatch(triggerEventAction(e));
			});

			dispatch(createEventEmitter(event, event.trigger, parentObj));
		}
	};
}

// processes the values for each different event type
export function createEventEmitter(event, trigger, parentObj) {
	return (dispatch, getState) => {
		// non state variables
		let newEvent = event;

		if (trigger === 'Time') {
			if (newEvent.parameter === 'Elapsed') {
				if (typeof newEvent.parameterValue.value === 'string') {
					let elapseValue = parseInt(event.parameterValue.value * 60);
					let gameTime = 0;

					if (parentObj.extra_settings.includes('time_restriction')) {
						let tempIndex = parentObj.extra_settings.indexOf('time_restriction');
						let gameTimerIndex = tempIndex + 1;
						gameTime = parentObj.extra_settings[gameTimerIndex] * 60;
					}

					newEvent.parameterValue.elapsedValue = elapseValue; // <- required in case we need to update the trigger value in a timer script

					switch (event.parameterValue.parentTimer) {
					case 'gameTimer':
						newEvent.parameterValue.value = gameTime - elapseValue;
						break;
					case 'msTimer':
						let mStgTime = parentObj.master_stages[newEvent.parameterValue.timerIndex.mStgIndex].time_restriction;
						let msTimeValue = parseInt(mStgTime * 60);

						newEvent.parameterValue.value = msTimeValue - elapseValue;
						break;
					case 'sTimer':
						let mSI = newEvent.parameterValue.timerIndex.mStgIndex;
						let sI = newEvent.parameterValue.timerIndex.stgIndex;
						let stageTime = parentObj.master_stages[mSI].stages[sI].time_restriction;
						let sTimeValue = parseInt(stageTime * 60);

						newEvent.parameterValue.value = sTimeValue - elapseValue;
						break;
					default:
						break;
					}
				}
			} else { // remaining
				if (typeof newEvent.parameterValue.value === 'string') {
					newEvent.parameterValue.value = parseInt(event.parameterValue.value * 60);
				}
			}
		}

		if (trigger !== 'Beacon') {
			dispatch({
				type: EVENT_EMITTER,
				emitter: newEvent
			});
		}
	};
}

// set event triggers based on where the user is within the experience
export function setEventTriggers() {
	return (dispatch, getState) => {
		let eventEmitters = getState().eventEmitters;

		for (let i = 0; i < eventEmitters.length; i++) {
			switch (eventEmitters[i].trigger) {
			case 'Time':
				dispatch(addTimeTrigger(eventEmitters[i]));
				break;
			case 'Completed':
				dispatch(addCompletionTrigger(eventEmitters[i]));
				break;
			default:
				console.log('Trigger type: ' + eventEmitters[i].trigger + ' not recognised');
				break;
			}
		}
	};
}

export function removeEmitter(eventObj) {
	return (dispatch, getState) => {
		let events = getState().eventEmitters;

		// non state variables
		let index = events.findIndex(emitter => emitter.title === eventObj.title);

		// check if the event we're removing is a beacon event. if it is, remove it from the beacon event trigger array
		switch (eventObj.trigger) {
		case 'Time':
			dispatch(removeTimeTrigger(eventObj));
			break;
		case 'Completed':
			dispatch(removeCompletionTrigger(eventObj));
			break;
		default:
			console.log('Trigger type: ' + eventObj.trigger + ' not recognised');
			break;
		}

		EventRegister.removeEventListener(eventObj.id);

		dispatch({
			type: REMOVE_EMITTER,
			index: index
		});
	};
}

// resets all emitter arrays so they don't have unnecessary data
export function resetAllEmitters() {
	return {
		type: RESET_ALL_EMITTERS
	};
}

/* ----- Time triggered events ----- */
export function addTimeTrigger(triggerObj) {
	return (dispatch, getState) => {
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let gameTimeTriggers = getState().timerEventTriggers.gameTimeTriggers;
		let masterStageTimeTriggers = getState().timerEventTriggers.masterStageTimeTriggers;
		let stageTimeTriggers = getState().timerEventTriggers.stageTimeTriggers;

		// non state variable
		let present = false;
		let timerType = triggerObj.parameterValue.parentTimer;

		switch (timerType) {
		case 'msTimer':
			present = GLOBAL.checkIfObjInArray(masterStageTimeTriggers, triggerObj, 'id');

			if (!present && cMSI == triggerObj.parameterValue.timerIndex.mStgIndex) {
				dispatch({
					type: ADD_MASTER_STAGE_TIMER_TRIGGER,
					trigger: triggerObj
				});
			}
			break;
		case 'sTimer':
			present = GLOBAL.checkIfObjInArray(stageTimeTriggers, triggerObj, 'id');

			if (!present && cMSI == triggerObj.parameterValue.timerIndex.mStgIndex && cSI == triggerObj.parameterValue.timerIndex.stgIndex) {
				dispatch({
					type: ADD_STAGE_TIMER_TRIGGER,
					trigger: triggerObj
				});
			}
			break;
		default:
			present = GLOBAL.checkIfObjInArray(gameTimeTriggers, triggerObj, 'id');

			if (!present) {
				dispatch({
					type: ADD_GAME_TIMER_TRIGGER,
					trigger: triggerObj
				});
			}
			break;
		}
	};
}

// update the values of timer triggers based on the new time value that a timer has been assigned
export function checkTimeTriggerValues(timer, newTimeValue) {
	return (dispatch, getState) => {
		let gameTimeTriggers = getState().timerEventTriggers.gameTimeTriggers;
		let masterStageTimeTriggers = getState().timerEventTriggers.masterStageTimeTriggers;
		let stageTimeTriggers = getState().timerEventTriggers.stageTimeTriggers;

		// non state variables
		let timeTriggerArray = [];

		switch (timer) {
		case 'msTimer':
			timeTriggerArray = masterStageTimeTriggers;
			break;
		case 'sTimer':
			timeTriggerArray = stageTimeTriggers;
			break;
		default:
			timeTriggerArray = gameTimeTriggers;
			break;
		}

		for (let i = 0; i < timeTriggerArray.length; i++) {
			if (timeTriggerArray[i].parameterValue.value === newTimeValue) {
				dispatch(addToTriggerQueue(timeTriggerArray[i]));
			}
		}
	};
}

export function removeTimeTrigger(triggerObj) {
	return (dispatch, getState) => {
		let gameTimeTriggers = getState().timerEventTriggers.gameTimeTriggers;
		let masterStageTimeTriggers = getState().timerEventTriggers.masterStageTimeTriggers;
		let stageTimeTriggers = getState().timerEventTriggers.stageTimeTriggers;

		// non state variables
		let index = -1;

		switch (triggerObj.parameterValue.parentTimer) {
		case 'msTimer':
			index = masterStageTimeTriggers.findIndex(emitter => emitter.id === triggerObj.id);
			dispatch({
				type: REMOVE_MASTER_STAGE_TIMER_TRIGGER,
				index: index
			});
			break;
		case 'sTimer':
			index = stageTimeTriggers.findIndex(emitter => emitter.id === triggerObj.id);
			dispatch({
				type: REMOVE_STAGE_TIMER_TRIGGER,
				index: index
			});
			break;
		default:
			index = gameTimeTriggers.findIndex(emitter => emitter.id === triggerObj.id);
			dispatch({
				type: REMOVE_GAME_TIMER_TRIGGER,
				index: index
			});
			break;
		}
	};
}

/* ----- Completion triggered events ----- */
// assigns the completion triggers associated with a master stage/stage to an array for triggering at a later date
export function addCompletionTrigger(triggerObj) {
	return (dispatch, getState) => {
		let stageTriggers = getState().completionEventTriggers.stageTriggers;
		let interactionTriggers = getState().completionEventTriggers.interactionTriggers;

		// non state variables
		let present = false;

		switch (triggerObj.parameter) {
		case 'Stage':
			present = GLOBAL.checkIfObjInArray(stageTriggers, triggerObj, 'id');

			if (!present) {
				dispatch({
					type: ADD_STAGE_COMPLETION,
					trigger: triggerObj
				});
			}
			break;
		case 'Interaction':
			present = GLOBAL.checkIfObjInArray(interactionTriggers, triggerObj, 'id');

			if (!present) {
				dispatch({
					type: ADD_INTERACTION_COMPLETION,
					trigger: triggerObj
				});
			}
			break;
		default:
			console.log('actions/Events.js addCompletionTrigger - Error: Trying to create/update completion trigger for ' + triggerObj.parameter + ' that does not exist');
			break;
		}
	};
}

export function removeCompletionTrigger(triggerObj) {
	return (dispatch, getState) => {
		let stageCompletionTriggers = getState().completionEventTriggers.stageTriggers;
		let interactionCompletionTriggers = getState().completionEventTriggers.interactionTriggers;

		// non state variables
		let index = -1;

		switch (triggerObj.parameter) {
		case 'Stage':
			index = stageCompletionTriggers.findIndex(emitter => emitter.id === triggerObj.id);
			dispatch({
				type: REMOVE_STAGE_COMPLETION,
				index: index
			});
			break;
		case 'Interaction':
			index = interactionCompletionTriggers.findIndex(emitter => emitter.id === triggerObj.id);
			dispatch({
				type: REMOVE_INTERACTION_COMPLETION,
				index: index
			});
			break;
		default:
			console.log('actions/Events.js removeCompletionTrigger - Error: Trying to remove completion trigger for a level called ' + triggerObj.parameter + ', that does not exist');
			break;
		}
	};
}

/* ----- Event time trigger queue start ----- */
// Handles multiple time triggered events that may have their trigger conditions met at the same time

export function addToTriggerQueue(newTrigger) {
	return (dispatch, getState) => {
		dispatch({
			type: ADD_TO_TRIGGER_QUEUE,
			newTrigger: newTrigger
		});

		if (!getState().triggerQueue.runQueue) {
			dispatch(startTriggerQueue());
		}
	};
}

export function removeFromTriggerQueue() {
	return {
		type: REMOVE_FROM_TRIGGER_QUEUE
	};
}

export function startTriggerQueue() {
	return (dispatch, getState) => {
		if (getState().triggerQueue.triggers.length > 1) {
			dispatch({
				type: TOGGLE_TRIGGER_QUEUE,
				runQueue: true
			});

			let triggerQueueLoop = setInterval(() => {
				let eventToTrigger = getState().triggerQueue.triggers[0];
				dispatch(triggerTimedEvent(eventToTrigger));
				dispatch(removeFromTriggerQueue());

				if (getState().triggerQueue.triggers.length === 0) {
					dispatch({
						type: TOGGLE_TRIGGER_QUEUE,
						runQueue: false
					});

					clearInterval(triggerQueueLoop);
				}
			}, 1000);
		} else {
			let triggerObj = getState().triggerQueue.triggers[0];

			dispatch(triggerTimedEvent(triggerObj));
			dispatch(removeFromTriggerQueue());
		}
	};
}

/* ----- Event time trigger queue end ----- */

/* ----- Event trigger start ----- */
// handles the triggering of a timed event
export function triggerTimedEvent(triggerObj) {
	return () => {
		EventRegister.emit(triggerObj.title, triggerObj);
	};
}

// handles the event emitting when the player completes a stage or interaction
export function triggerCompletionEvent(level) {
	return (dispatch, getState) => {
		// non state variables
		let completionTriggerArray = [];
		let locIndex = null;

		switch (level) {
		case 'Stage':
			completionTriggerArray = getState().completionEventTriggers.stageTriggers;
			locIndex = getState().currentStageIndex;
			break;
		case 'Interaction':
			completionTriggerArray = getState().completionEventTriggers.interactionTriggers;
			locIndex = getState().currentInteractionIndex;
			break;
		default:
			console.log('actions/Events.js triggerCompletionEvent - Error: Trying to trigger completion for a level called ' + level + ', that does not exist');
			break;
		}

		if (completionTriggerArray.length > 0 && locIndex !== null) {
			for (let i = 0; i < completionTriggerArray.length; i++) {
				let tgtIndex = parseInt(completionTriggerArray[i].parameterValue.stgIndex);

				if (level === 'Interaction') {
					tgtIndex = parseInt(completionTriggerArray[i].parameterValue.interactionIndex);
				}

				if (locIndex === tgtIndex) {
					EventRegister.emit(completionTriggerArray[i].title, completionTriggerArray[i]);
				}
			}
		}
	};
}

/* ----- Event actions ----- */
export function triggerEventAction(event) {
	return (dispatch, getState) => {
		let userData = getState().userData;

		// non state variables
		let points = 0;
		let eventType = event.event_type;

		switch (eventType) {
		case 'display_message':
			dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			break;
		case 'take_points':
			points = parseInt(event.content);
			dispatch(userActions.addPoints(-points));
			dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			break;
		case 'give_points':
			points = parseInt(event.content);
			dispatch(userActions.addPoints(points));
			dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			break;
		case 'take_time':
			dispatch(eventTimerModification(-event.content.value, event.content.parentTimer, event.content.timerIndex));
			dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			break;
		case 'give_time':
			dispatch(eventTimerModification(event.content.value, event.content.parentTimer, event.content.timerIndex));
			dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			break;
		case 'go_to_masterStage':
			if (!userData.master_stage[event.content.mStgIndex].completed) {
				dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType, event.content));
			}
			break;
		case 'go_to_stage':
			if (!userData.master_stage[event.content.mStgIndex].stage[event.content.stgIndex].completed) {
				dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType, event.content));
			}
			break;
		case 'go_to_interaction':
			if (!userData.master_stage[event.content.mStgIndex].stage[event.content.stgIndex].puzzle[event.content.interactionIndex].completed) {
				dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType, event.content));
			}
			break;
		case 'give_note':
			dispatch(userActions.createNote(event.title, event.content));
			dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			break;
		case 'unlock_stage':
			if (userData.master_stage[event.content.mStgIndex].stage[event.content.stgIndex].locked) {
				dispatch({
					type: EVENT_UNLOCK,
					area: 'stage',
					loc: {
						mStgIndex: event.content.mStgIndex,
						stgIndex: event.content.stgIndex
					}
				});

				dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			}
			break;
		case 'unlock_interaction':
			if (userData.master_stage[event.content.mStgIndex].stage[event.content.stgIndex].puzzle[event.content.interactionIndex].locked) {
				dispatch({
					type: EVENT_UNLOCK,
					area: 'stage',
					loc: {
						mStgIndex: event.content.mStgIndex,
						stgIndex: event.content.stgIndex,
						intIndex: event.content.interactionIndex
					}
				});

				dispatch(displayEventFeedback(event.feedback_option, event.feedback_content, eventType));
			}
			break;
		default:
			console.log('Event type: ' + eventType + ' does not exist');
			break;
		}

		// destory non persistent event
		if (!event.persistent_event) {
			dispatch(removeEmitter(event));
		}
	};
}

export function displayEventFeedback(type, feedback, event_type, obj) {
	return (dispatch) => {
		switch (type) {
		case 'notification':
			dispatch(notificationActions.addNotification({ id: Date.now() + 1, message: feedback, notificationType: 'info' }));
			break;
		case 'pop_up_modal':
			// The help version of the info modal is used as it provides the ability to modify the content
			if (!event_type.includes('go_to')) {
				dispatch(uniModalActions.openModal('pop_up_modal'));
			} else {
				dispatch(uniModalActions.openModal('goToLocModal', { content: feedback, go_to: event_type, locObj: obj }));
			}
			break;
		default:
			console.log('Feedback type ' + type + ' does not exist');
			break;
		}
	};
}

// changes the value of a designated timer based on the action of a triggered event
export function eventTimerModification(value, parent, indexObj, modifier) {
	return (dispatch) => {
		// non state variables
		let adaptedValue = parseFloat(value) * 60;
		let newTime = false;

		if (isNaN(adaptedValue)) {
			adaptedValue = 0;
		}

		// need to add adaptive modifier so the when we update the different timers, it updates with the correct values
		if (modifier === 'new') {
			newTime = true;
		}

		if (parent === 'gameTimer') {
			indexObj = null;
		}

		dispatch(timerActions.modifyTimerValue(parent, adaptedValue, indexObj, newTime));
	};
}
