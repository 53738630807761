import * as GameActions from './Game';
import * as UserActions from './User';
import * as ExperienceActions from './Experience';
import * as EventActions from './Event';
import * as UniModalActions from './UniversalModal';
import * as TimersActions from './Timers';
import * as InteractionActions from './Interaction';
import * as CustomNumberpadActions from './CustomNumberpad';
import * as NetworkActions from './Network';
import * as NotificationActions from './Notifications';

export const ActionCreators = Object.assign({},
	GameActions,
	UserActions,
	ExperienceActions,
	EventActions,
	UniModalActions,
	TimersActions,
	InteractionActions,
	CustomNumberpadActions,
	NetworkActions,
	NotificationActions
);
