import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

const Emitter = {
	addEventListener: (event, fn) => eventEmitter.on(event, fn),
	removeEventListener: (event, fn) => eventEmitter.removeListener(event, fn),
	removeAllEventListeners: (event) => eventEmitter.removeAllListeners(event),
	emit: (event, payload) => eventEmitter.emit(event, payload)
};

Object.freeze(Emitter);

export default Emitter;
