import React, { Component } from 'react';

// third party resources imports
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

// custom resources imports
import Styles from '../lib/Styles';
import Address from '../lib/Addresses';

// declares local variables
const styles = Styles.scenes.imageSelection;

export default class ImageSelection extends Component {
	renderStatusIcon(index) {
		let icondiv = <Icon style={{ fontSize: '40px', color: 'white' }}>{'check'}</Icon>;

		if (index) {
			icondiv = <p style={{ fontSize: '40px', color: 'white' }}>{index}</p>;
		}

		return (
			<div style={Object.assign({}, styles.iconContainer, { backgroundColor: 'rgba(21, 184, 0, 1)' })}>
				{icondiv}
			</div>
		);
	}

	renderSelectionImages(selectionArray) {
		const {
			gameData,
			currentMasterStageIndex,
			currentStageIndex,
			currentInteractionIndex,
			interactionImageSelection,
			xs,
			sm,
			md
		} = this.props;

		let buttonArray = [];
		let interaction = gameData.master_stages[currentMasterStageIndex].stages[currentStageIndex].puzzles[currentInteractionIndex];

		for (let i = 0; i < selectionArray.length; i++) {
			let buttonImage = selectionArray[i] ? Address.PARENT + selectionArray[i] : '/assets/dote-placeholder.png';

			buttonArray.push(
				<Grid key={i} item xs={xs ? xs : 6} sm={sm ? sm : 6} md={md ? md : 4}>
					<div style={styles.imageSelectionItemContainer}>
						<div style={styles.imageSelectionImageContainer}>
							<img
								src={buttonImage}
								style={Object.assign({}, styles.imageSelectionImage, this.props.selectionImageStyle)}
								onClick={() => {
									this.props.selectImage(i);
								}}
						 	/>
					 		{interactionImageSelection.includes(i) ? interaction.answer.order_required === 'yes'
								? this.renderStatusIcon(this.props.interactionImageSelection.indexOf(i) + 1)
								: this.renderStatusIcon(null) : <div />}
					 	</div>
					</div>
				</Grid>
			);
		}

		return buttonArray;
	}

	render() {
		const { dataArray } = this.props;

		return (
			<div style={styles.container}>
				<div style={styles.imageSelectionContainer}>
					<Grid container spacing={1}>
						{this.renderSelectionImages(dataArray)}
					</Grid>
				</div>
			</div>
		);
	}
}
