import {
	ADD_TO_MODAL_DISPLAY_QUEUE,
	UPDATE_MODAL_PROPS,
	RESET_MODAL_PROPS,
	SET_MODAL_TAB,
	ASSIGN_MODAL_TAB_LABELS,
	TOGGLE_CONFIRMATION_MODAL,
	ASSIGN_CONFIRMATION_MODAL_PROPS,
	TOGGLE_FLOATING_BTN_PANEL,
	TOGGLE_NOTIFICATION,
	ASSIGN_NOTIFICATION_PROPS
} from '../actions/UniversalModal';

export function modalDisplayQueue(state = [], action) {
	switch (action.type) {
	case ADD_TO_MODAL_DISPLAY_QUEUE:
		state.push(action.modalObj);
		return state;
	case RESET_MODAL_PROPS:
		state.shift();
		return state;
	default:
		return state;
	}
}

export function showModal(state = false, action) {
	switch (action.type) {
	case UPDATE_MODAL_PROPS:
	case RESET_MODAL_PROPS:
		state = action.isOpen;
		return state;
	default:
		return state;
	}
}

export function modalReady(state = true, action) {
	switch (action.type) {
	case UPDATE_MODAL_PROPS:
	case RESET_MODAL_PROPS:
		state = action.isReady;
		return state;
	default:
		return state;
	}
}

export function modalProps(state = { data: {}, screenType: '' }, action) {
	switch (action.type) {
	case UPDATE_MODAL_PROPS:
		let newData = {
			data: action.data,
			screenType: action.screenType,
			modalType: action.modalType
		};
		state = newData;

		return state;
	case SET_MODAL_TAB:
		state.data.bg = action.mapImage;
		return state;
	case RESET_MODAL_PROPS:
		state = { data: {}, screenType: '' };
		return state;
	default:
		return state;
	}
}

export function universalModalHeader(state = { headerType: 'none', header: '', closeCallback: null }, action) {
	switch (action.type) {
	case UPDATE_MODAL_PROPS:
		state = {
			headerType: action.headerType,
			header: action.header,
			closeCallback: action.close
		};

		return state;
	case RESET_MODAL_PROPS:
		state = { headerType: 'none', header: '', closeCallback: null };
		return state;
	default:
		return state;
	}
}

export function modalTabIndex(state = 0, action) {
	switch (action.type) {
	case SET_MODAL_TAB:
		state = action.tab;
		return state;
	case RESET_MODAL_PROPS:
		state = 0;
		return state;
	default:
		return state;
	}
}

export function modalTabLabels(state = ['Default'], action) {
	switch (action.type) {
	case ASSIGN_MODAL_TAB_LABELS:
		state = action.tabs;
		return state;
	case RESET_MODAL_PROPS:
		state = ['Default'];
		return state;
	default:
		return state;
	}
}

export function showConfirmationModal(state = false, action) {
	switch (action.type) {
	case TOGGLE_CONFIRMATION_MODAL:
	case ASSIGN_CONFIRMATION_MODAL_PROPS:
		state = action.isOpen;
		return state;
	default:
		return state;
	}
}

export function confirmationModalProps(state = { title: 'Confirm action', content: 'Default text', confirmText: 'confirm', confirmFunc: null }, action) {
	switch (action.type) {
	case ASSIGN_CONFIRMATION_MODAL_PROPS:
		state.title = action.title;
		state.content = action.content;
		state.confirmText = action.confirmText;
		state.confirmFunc = action.confirmFunc;
		return state;
	default:
		return state;
	}
}

export function floatingBtnPanelProps(state = { isOpen: false, floatingPanelEl: null }, action) {
	switch (action.type) {
	case TOGGLE_FLOATING_BTN_PANEL:
		state.isOpen = action.isOpen;
		state.floatingPanelEl = action.floatingPanelEl;
		return state;
	default:
		return state;
	}
}
