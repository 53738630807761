import React, { Component } from 'react';
import '../App.css';

// third party resources
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';

// custom components
import Styles from '../lib/Styles';

// declare local variables
const styles = Styles.scenes.loginEntry;

class LoginEntry extends Component {
	constructor(props) {
		super(props);

		this.props.setHistoryState(props.history);
		this.state = {
			gameID: ''
		};
	}

	renderTextInput() {
		return (
			<TextField
				style={styles.loginInput}
				placeholder='Enter Game ID'
				onChange={userInput => this.setState({ gameID: userInput.target.value })}
				value={this.state.gameID}
				{...this.props}
			/>
		);
	}

	render() {
		return (
			<div style={Object.assign({}, styles.background, { background: 'url(/assets/BKGD_Main.jpg)' })}>
				<div style={styles.loginContainer}>
					<h1 style={styles.loginText}>Enter your game code</h1>
					{this.renderTextInput()}
					<Button
						style={styles.loginButton}
						variant='contained'
						color='primary'
						onClick={() => {
							if (this.state.gameID.length > 0 && !isNaN(parseInt(this.state.gameID))) {
								this.props.retrieveGameData(this.state.gameID);
							}
						}}
					>
						<p>Enter Code</p>
					</Button>
				</div>
				<div style={Object.assign({}, styles.loginContainer, { margin: '20px', justifyContent: 'space-around', border: '5px solid red' })}>
					<h1 style={styles.loginText}>Temp dev buttons</h1>
					<Button variant='contained' color='danger' onClick={() => {
						this.props.deleteAllUserData();
					}}>Delete Prev user data</Button>
					<Button variant='contained' color='primary' onClick={() => {
						this.props.resumePreviousProgress();
					}}>Retrieve Prev user data</Button>
					<Button variant='contained' color='secondary' onClick={() => {
						this.props.exportMailchimpUserData();
					}}>Send Mailchimp Data</Button>
				</div>
			</div>
		);
	}
}

/*
 * Redux: hooks the state in the default the container, so all the
 * children components can access them as props.
 */
const mapStateToProps = (state) => {
	return {
		historyState: state.historyState,
		screenDimensions: state.screenDimensions
	};
};

export default withRouter(connect(mapStateToProps)(LoginEntry));
