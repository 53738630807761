import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import HttpsRedirect from 'react-https-redirect';

import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';
import { Provider } from 'react-redux';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

let configureStore = (initialState) => {
	let enhancer = compose(applyMiddleware(thunkMiddleware));

	return createStore(reducer, initialState, enhancer);
};

const store = configureStore({});

ReactDOM.render(
	<React.StrictMode>
		<HttpsRedirect>
	  		<Provider store={store}>
		  		<Router history={history}>
		    		<App />
		    	</Router>
			</Provider>
		</HttpsRedirect>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
