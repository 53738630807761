/* import action types */
import {
	INIT_GAME_DATA,
	UPDATE_GAME_STYLES,
	UPDATE_DIMENSIONS,
	ASSIGN_REGO_PROPS,
	SELECT_EDIT_MEMBER,
	SET_GAME_OVER,
	SET_HISTORY,
	ASSIGN_MASTERSTAGE_BREAKDOWN,
	PROCESS_MASTERSTAGE_BREAKDOWN,
	FORCE_BROWSER_MOVEMENT_BACK
} from '../actions/Game';

import {
	START_MASTER_STAGE
} from '../actions/Experience';

/* reducers */

export function gameData(state = {}, action) {
	switch (action.type) {
	case INIT_GAME_DATA:
		state = action.gameData;

		return state;
	default:
		return state;
	}
}

export function gameStyles(state = {
	background: 'https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350',
	logo: '',
	primary: {
		color: 'blue',
		fontFamily: 'Roboto',
		fontSize: '40px'
		// fontSize: parseInt(wp('3.91%')),
	},
	secondary: {
		color: 'blue',
		fontFamily: 'Roboto',
		fontSize: '30px'
		// fontSize: parseInt(wp('2.93%')),
	},
	tertiary: {
		color: 'blue',
		fontFamily: 'Roboto',
		fontSize: '20px'
		// fontSize: parseInt(wp('1.95%')),
	}
}, action) {
	switch (action.type) {
	case UPDATE_GAME_STYLES:
		state = action.gameStyles;

		return state;
	default:
		return state;
	}
}

export function screenDimensions(state = { height: 0, width: 0, size: 'small' }, action) {
	switch (action.type) {
	case UPDATE_DIMENSIONS:
		state.width = action.width;
		state.height = action.height;

		let screenSize = 'small';

		if (action.width > 480 && action.width < 960) {
			screenSize = 'medium';
		}

		if (action.width > 960 && action.width < 1920) {
			screenSize = 'large';
		}

		if (action.width > 1920) {
			screenSize = 'x-large';
		}

		state.size = screenSize;
		return state;
	default:
		return state;
	}
}

export function registrationProps(state = { regoTeamPronoun: 'Enter', regoMemberInputState: 'create', regoMemberPronoun: '' }, action) {
	switch (action.type) {
	case ASSIGN_REGO_PROPS:
		state.regoTeamPronoun = action.teamNameRego;
		state.regoMemberPronoun = action.memberRego;

		return state;
	case SELECT_EDIT_MEMBER:
		state.regoMemberInputState = action.newRegoInputState;
		state.regoMemberPronoun = action.memberRego;
		return state;
	default:
		return state;
	}
}

export function gameOver(state = false, action) {
	switch (action.type) {
	case SET_GAME_OVER:
		state = action.gameOver;
		return state;
	default:
		return state;
	}
}

export function historyState(state = null, action) {
	switch (action.type) {
	case SET_HISTORY:
		if (action.history) {
			state = action.history;
		}

		return state;
	default:
		return state;
	}
}

export function masterStageBreakdown(state = { breakdown: [], stageStickyHeaderIndices: [0], activeBreakdownWindow: '0_Section', selectionDetails: [] }, action) {
	switch (action.type) {
	case PROCESS_MASTERSTAGE_BREAKDOWN:
		state.breakdown = action.masterStageBreakdown;
		state.stageStickyHeaderIndices = action.stageStickyHeaderIndices;
		state.activeBreakdownWindow = action.activeBreakdownWindow;
		return state;
	case ASSIGN_MASTERSTAGE_BREAKDOWN:
		state.selectionDetails = action.masterStageSelections;
		return state;
	default:
		return state;
	}
}

export function forceMovementBack(state = false, action) {
	switch (action.type) {
	case FORCE_BROWSER_MOVEMENT_BACK:
		state = !state;

		return state;
	case START_MASTER_STAGE:
		state = false;

		return state;
	default:
		return state;
	}
}
