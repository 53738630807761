// Redux actions
// third party resources imports
// custom resources

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const ASSIGN_NOTIFICATION_PROPS = 'ASSIGN_NOTIFICATION_PROPS';

export function addNotification(content) {
	return (dispatch, getState) => {
		let showNotification = getState().showNotification;

		dispatch({
			type: ADD_NOTIFICATION,
			content: content
		});	

		if (!showNotification) {
			dispatch(assignNotificationProps(content.id, content.message, content.notificationType));
		}	
	};
}

export function closeNotification(id) {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_NOTIFICATION,
			isOpen: false
		});

		dispatch(removeNotification(id));
	};
}

// Remove a single notification from the notification array.
export function removeNotification(id) {
	return (dispatch, getState) => {
		let notifications = getState().notificationArray;

		dispatch({
			type: REMOVE_NOTIFICATION,
			id: id
		});

		if (notifications.length > 0) {
			if (!getState().showNotification) {
				dispatch(assignNotificationProps(notifications[0].id, notifications[0].message, notifications[0].notificationType));
			}
		}
	};
}

export function assignNotificationProps(id, content, notificationType) {
	return {
		type: ASSIGN_NOTIFICATION_PROPS,
		isOpen: true,
		id: id,
		content: content,
		severity: notificationType
	};
}
