// Redux actions
import * as userActions from './User';

// third party resources imports

// custom resources
import Addresses from '../lib/Addresses';

export const ADD_TO_MODAL_DISPLAY_QUEUE = 'ADD_TO_MODAL_DISPLAY_QUEUE';
export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS';
export const RESET_MODAL_PROPS = 'RESET_MODAL_PROPS';
export const SET_MODAL_TAB = 'SET_MODAL_TAB';
export const ASSIGN_MODAL_TAB_LABELS = 'ASSIGN_MODAL_TAB_LABELS';
export const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL';
export const ASSIGN_CONFIRMATION_MODAL_PROPS = 'ASSIGN_CONFIRMATION_MODAL_PROPS';
export const ASSIGN_FLOATING_BUTTONS = 'ASSIGN_FLOATING_BUTTONS';
export const TOGGLE_FLOATING_BTN_PANEL = 'TOGGLE_FLOATING_BTN_PANEL';

export function openModal(modalOpened, extraData) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let modalDisplayArray = getState().modalDisplayQueue;

		// non state variables
		let modalType = 'content';
		let screenType = modalOpened;
		let modalObj = { modalType: modalType, screenType: screenType, data: {}, windowHeader: {} };
		let data = { positionInGame: getState().windowState, bottomButton: false };
		let windowHeader = { headerType: 'none', header: '', close: null };

		// assign the values required for the Window header obj
		switch (screenType) {
		case 'edit_rego_member':
			data.editMember = true;
			windowHeader.headerType = 'none';
			modalType = 'bespoke';
			break;
		case 'rego_member':
			data.editMember = false;
			windowHeader.headerType = 'none';
			modalType = 'bespoke';
			break;
		case 'map':
			windowHeader.headerType = 'tabbed';
			windowHeader.header = 'Map';
			modalType = 'bespoke';
			dispatch(assignModalTabLabels(getState().currentMapImages));
			data.html = '';
			data.bg = getMapImageByIndex(0, getState().currentMapImages);
			break;
		case 'info':
			windowHeader.headerType = 'windowed';
			windowHeader.header = 'Instructions';
			let assignContent = extraData;

			switch (getState().windowState) {
	      case 'master_stage':
	        assignContent = gameData.master_stages[cMSI].instruction;
	        break;
	      case 'stage':
	      case 'interaction':
	        assignContent = gameData.master_stages[cMSI].stages[cSI].instructions;
	        break;
	      default:
	        break;
	    }

			data.html = assignContent.html;
			data.bg = assignContent.bg;
			data.centered = true;
			data.bgImage = true;
			break;
		case 'previousAnswers':
			windowHeader.headerType = 'windowed';
			windowHeader.header = 'Your Answers';
			modalType = 'bespoke';
			data = getState().currentAnswers;
			break;
		case 'notes':
			windowHeader.headerType = 'windowed';
			windowHeader.header = 'Notes';
			modalType = 'bespoke';
			data.notes = getState().userData.notes;
			break;
		case 'hints':
			windowHeader.headerType = 'windowed';
			windowHeader.header = 'Hints';
			modalType = 'bespoke';
			data.currentHints = getState().currentHints;
			break;
		case 'help':
			windowHeader.headerType = 'windowed';
			windowHeader.header = 'Help';
			data.content = gameData.help_screen;
			break;
		case 'feedback':
			windowHeader.headerType = 'none';
			data.html = extraData.feedback.html;
			data.bg = extraData.feedback.bg;
			data.bottomButton = true;
			data.buttonText = extraData.buttonText;
			data.buttonIcon = extraData.feedback.buttonIcon;
			break;
		case 'lightbox':
			windowHeader.headerType = 'windowed';
			data.content = extraData.content;
			break;
		case 'confirmationModal':
			windowHeader.headerType = 'none';
			data.title = 'Resume previous progress?';
			break;
		case 'goToLocModal':
			windowHeader.headerType = 'none';
			data = extraData;
			break;
		case 'pop_up_modal':
			windowHeader.headerType = 'none';
			screenType = 'help';
			break;
		case 'keypad_interaction':
			data.keypadMode = 'interaction_number';
			data.passcodeType = screenType;
			screenType = 'keypad';
			modalType = 'bespoke';
			break;
		case 'keypad_locked_start':
			let startPinCode = gameData.start_pin_code.split(['',]).map(Number);

			data.keypadMode = 'passcode';
			data.passcodeType = screenType;
			data.responseType = 'pinCodeSuccess';
			data.unlockPin = startPinCode;
			screenType = 'keypad';
			modalType = 'bespoke';
			break;
		case 'keypad_locked_end':
			let endPinCode = gameData.summary_pin_code.split(['',]).map(Number);

			data.keypadMode = 'passcode';
			data.passcodeType = screenType;
			data.responseType = 'pinCodeSuccess';
			data.unlockPin = endPinCode;
			screenType = 'keypad';
			modalType = 'bespoke';
			break;
		case 'defaultExperiencePromptModal':
			break;
		case 'change_app_state':
			windowHeader.headerType = 'windowed';
			screenType = 'help';
			data.content = 'Data export in progress.\nPlease wait. If this window stays like this for longer than 60 seconds then close it and restart the app';
			break;
		case 'terms_and_cond':
			windowHeader.headerType = 'windowed';
			windowHeader.header = 'Terms & Conditions';
			data.html = gameData.terms_conditions;
			break;
		default:
			break;
		}

		modalObj.modalType = modalType;
		modalObj.screenType = screenType;
		modalObj.data = data;
		modalObj.windowHeader = windowHeader;

		// check modal queue to see if there is already a modal with the same content, if there is don't add it to the queue
		let modalExists = false;

		for (let i = 0; i < modalDisplayArray.length; i++) {
			if (modalDisplayArray[i].data === data) {
				modalExists = true;
				break;
			}
		}

		if (!modalExists) {
			dispatch({
				type: ADD_TO_MODAL_DISPLAY_QUEUE,
				modalObj: modalObj
			});
		}

		// we use a boolean to designate if a modal is ready. This is because this logic will run faster than the animations and events tied to the modal component will
		if (getState().modalReady) {
			dispatch(displayNextModal());
		}
	};
}

export function displayNextModal() {
	return (dispatch, getState) => {
		let modalDisplayArray = getState().modalDisplayQueue;

		if (modalDisplayArray.length > 0) {
			let modalDisplayObj = modalDisplayArray[0];
			let windowHeader = modalDisplayObj.windowHeader;

			dispatch({
				type: UPDATE_MODAL_PROPS,
				data: modalDisplayObj.data,
				modalType: modalDisplayObj.modalType,
				screenType: modalDisplayObj.screenType,
				headerType: windowHeader.headerType,
				header: windowHeader.header,
				close: windowHeader.close,
				isOpen: true,
				isReady: false
			});
		}
	};
}

export function closeModal() {
	return (dispatch) => {
		dispatch({
			type: RESET_MODAL_PROPS,
			isOpen: false,
			isReady: true
		});
	};
}

// Tab specific
export function assignModalTabLabels(objTabs) {
	return (dispatch, getState) => {
		// non state variables
		let tabLabels = [];

		for (const [key, value] of Object.entries(objTabs)) {
			if (value.imageAddress.length > 0) {
				tabLabels.push(value.title);
			}
		}

		dispatch({
			type: ASSIGN_MODAL_TAB_LABELS,
			tabs: tabLabels
		});
	};
}

export function setModalTab(index) {
	return (dispatch, getState) => {
		// non state variables

		dispatch({
			type: SET_MODAL_TAB,
			tab: index,
			mapImage: getMapImageByIndex(index, getState().currentMapImages)
		});
	};
}

function getMapImageByIndex(index, maps) {
	for (const [key, value] of Object.entries(maps)) {
		if (value.tabIndex === index) {
			let mapImageAddress = value.imageAddress.replace('/storage/', Addresses.RESOURCE_URL);

			return mapImageAddress;
		}
	}

	return '';
}

export function openConfirmationModal() {
	return {
		type: TOGGLE_CONFIRMATION_MODAL,
		isOpen: true
	};
}

export function closeConfirmationModal() {
	return {
		type: TOGGLE_CONFIRMATION_MODAL,
		isOpen: false
	};
}

export function assignConfirmationModalProps(title, content, confirmText, confirmFunc) {
	return {
		type: ASSIGN_CONFIRMATION_MODAL_PROPS,
		isOpen: true,
		title: title,
		content: content,
		confirmText: confirmText,
		confirmFunc: confirmFunc
	};
}

export function assignFloatingButtons() {
	return {
		type: ASSIGN_FLOATING_BUTTONS

	};
}

export function toggleFloatingBtnPanel(isOpen, floatingPanelEl) {
	return {
		type: TOGGLE_FLOATING_BTN_PANEL,
		isOpen: isOpen,
		floatingPanelEl: floatingPanelEl
	};
}
