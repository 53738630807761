import React, { Component } from 'react';

// third party resources imports
import {
	Button,
	InputLabel,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	RadioGroup,
	Radio,
	Select,
	TextField
} from '@material-ui/core';

// custom components
import Styles from '../../lib/Styles';

const styles = Styles.components.individualRegistration;

export default class IndividualRegistration extends Component {
	renderRegoButtons() {
		const { userData } = this.props;

		if (userData.userDetails.teamMembers.length > 0) {
			let submitText = this.props.editMember ? 'Update' : 'Add';

			return (
				<div style={styles.regoButtonContainer}>
					<Button variant='contained' onClick={() => {
						this.props.closeModal();
					}}>Cancel</Button>
					<Button variant='contained' color='primary' onClick={() => {
						this.props.addTeamMember(this.props.editMember);
						this.props.closeModal();
					}}>{submitText}</Button>
				</div>
			);
		}

		return (
			<Button variant='contained' color='primary' onClick={() => {
				this.props.setRegistrationState('forward');
			}}>Next</Button>
		);
	}

	render() {
		const { registrationProps } = this.props;

		return (
			<div style={styles.container}>
				<div style={styles.regoRowContainer}>
					<h1 style={styles.title}>{registrationProps.regoMemberPronoun}</h1>
				</div>
				<div style={styles.regoRowContainer}>
					<TextField
						style={styles.regoTextField}
						placeholder='First name'
						onChange={userInput => this.props.setNewUserDetails('firstName', userInput.target.value)}
						value={this.props.newUserFirstName}
						{...this.props}
					/>
					<TextField
						style={styles.regoTextField}
						placeholder='Last name'
						onChange={userInput => this.props.setNewUserDetails('lastName', userInput.target.value)}
						value={this.props.newUserLastName}
						{...this.props}
					/>
				</div>
				<div style={styles.regoRowContainer}>
					<TextField
						style={styles.regoTextField}
						placeholder='email@address.com'
						onChange={userInput => this.props.setNewUserDetails('email', userInput.target.value)}
						value={this.props.newUserEmail}
						{...this.props}
					/>
					<div style={styles.regoAgePickerParent}>
						<div style={styles.regoAgePickerTitleContainer}>
							<InputLabel id='label'>Age Group</InputLabel>
						</div>
						<div style={styles.regoAgePickerSelectParentContainer}>
							<Select style={styles.regoAgePickerSelectContainer} labelID='label' id='select' value={this.props.newUserAge} onChange={select => this.props.setNewUserDetails('age', select.target.value)}>
								<MenuItem value='u12'>Under 12</MenuItem>
								<MenuItem value='13-18'>13-18</MenuItem>
								<MenuItem value='19-24'>19-24</MenuItem>
								<MenuItem value='25-35'>25-35</MenuItem>
								<MenuItem value='36-45'>36-45</MenuItem>
								<MenuItem value='46-60'>46-60</MenuItem>
								<MenuItem value='60+'>60+</MenuItem>
							</Select>
						</div>
					</div>
				</div>
				<div style={styles.regoRowContainer}>
					<FormControl style={styles.regoGenderContainer} component='fieldset'>
					  <FormLabel component='legend'>Gender</FormLabel>
					  <RadioGroup style={styles.regoGenderRadioContainer}aria-label='gender' name='gender1' row value={this.props.newUserGender} onChange={select => this.props.setNewUserDetails('gender', select.target.value)}>
					    <FormControlLabel value='female' control={<Radio />} label='Female' />
					    <FormControlLabel value='male' control={<Radio />} label='Male' />
					    <FormControlLabel value='other' control={<Radio />} label='Other' />
					  </RadioGroup>
					</FormControl>
				</div>
				<div style={styles.regoRowContainer}>
					{this.renderRegoButtons()}
				</div>
				<div style={styles.regoRowContainer}>
					<p style={styles.disclaimer} >We respect your privacy. Your details will be kept securely in line with our Privacy Policy available on our website.</p>
				</div>
			</div>
		);
	}
}
