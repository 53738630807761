import React, { Component } from 'react';

// third party resources
import {
	Drawer,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@material-ui/core';
import {
	Info as InfoIcon,
	Map as MapIcon,
	Notes as NotesIcon,
	Replay as PastAnswersIcon,
	Help as HelpIcon,
	Menu as MenuIcon,
	ExitToApp as SignOutIcon
} from '@material-ui/icons';

import { FaTrophy } from 'react-icons/fa';

// custom resource imports
import GLOBAL from '../../lib/Globals';
import Styles from '../../lib/Styles';
import TimerBox from '../TimerBox';

// declares local variables
const styles = Styles.scenes.topSceneContainer;
const primaryTimerStyles = Styles.components.primaryTimer;
const secondaryTimerStyles = Styles.components.secondaryTimer;

export default class TopContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			drawerOpen: false
		};
	}

	renderIcon(type) {
		switch (type) {
		case 'Leader':
			return <FaTrophy />;
		case 'Notes':
			return <NotesIcon />;
		case 'Maps':
			return <MapIcon />;
		case 'Info':
			return <InfoIcon />;
		case 'Answ':
			return <PastAnswersIcon />;
		case 'Help':
			return <HelpIcon />;
		default:
			return <HelpIcon />;
		}
	}

	renderMenuButtons(selectionArray) {
		let buttonArray = [];

		for (let i = 0; i < selectionArray.length; i++) {
			if (selectionArray[i].owner === 'all') {
				buttonArray.push(
					<ListItem
						button
						key={selectionArray[i].id}
						onClick={() => {
							selectionArray[i].callback();
						}}>
						<ListItemIcon>{this.renderIcon(selectionArray[i].floatingTitle)}</ListItemIcon>
						<ListItemText primary={selectionArray[i].title} />
					</ListItem>
				);
			}
		}

		return buttonArray;
	}

	renderMenuDrawer() {
		const { gameStyles } = this.props;

		return (
			<Drawer
				anchor={'left'}
				open={this.state.drawerOpen}
				onClose={() => this.setState({ drawerOpen: false })}>
				<div style={styles.drawerContainer}>
					<div style={styles.drawerLogoContainer}>
						<img style={styles.drawerLogo} src={gameStyles.logo} />
					</div>
					<div style={styles.drawerListContainer}>
						<List style={styles.drawerList}>
							{this.renderMenuButtons(this.props.floatingButtons)}
							<ListItem
								button
								key={'SignOut'}
								onClick={() => {
									this.props.historyState.push('/');
								}}>
								<ListItemIcon><SignOutIcon /></ListItemIcon>
								<ListItemText primary={'Sign Out'} />
							</ListItem>
						</List>
					</div>
					<div style={styles.drawerTeamContainer}>
						{this.props.userData.userDetails.teamName ? <h2>{this.props.userData.userDetails.teamName}</h2> : <div />}
					</div>
				</div>
			</Drawer>
		);
	}

	renderPrimaryTimer() {
		const { gameStyles } = this.props;

		return (
			<TimerBox
				parentObj={this.props.primaryTimerProps.timer}
				style={primaryTimerStyles.container}
				timerTextStyle={Object.assign({}, gameStyles.secondary, primaryTimerStyles.timerText)}
				titleStyle={Object.assign({}, gameStyles.primary, primaryTimerStyles.titleText)}
				timerContainer={primaryTimerStyles.timerContainer}
				titleContainer={primaryTimerStyles.titleContainer}
				timerTitleText={this.props.primaryTimerProps.title}
				timeRemaining={(this.props.primaryTimerProps.timer === 'gameTimer' ? this.props.gameTimerCount : (this.props.primaryTimerProps.timer === 'msTimer' ? this.props.masterStageTimerCount : (this.props.primaryTimerProps.timer === 'sTimer' ? this.props.stageTimerCount : 0)))}
				{...this.props}
			/>
		);
	}

	renderSecondaryTimer() {
		const { gameStyles } = this.props;

		return (
			<TimerBox
				parentObj={this.props.secondaryTimerProps.timer}
				style={secondaryTimerStyles.container}
				timerTextStyle={Object.assign({}, gameStyles.secondary, secondaryTimerStyles.timerText)}
				titleStyle={Object.assign({}, gameStyles.primary, secondaryTimerStyles.titleText)}
				timerContainer={secondaryTimerStyles.timerContainer}
				titleContainer={secondaryTimerStyles.titleContainer}
				timerTitleText={this.props.secondaryTimerProps.title}
				timeRemaining={(this.props.secondaryTimerProps.timer === 'msTimer' ? this.props.masterStageTimerCount : (this.props.secondaryTimerProps.timer === 'sTimer' ? this.props.stageTimerCount : 0))}
				{...this.props}
			/>
		);
	}

	renderExperienceProgress() {
		return (
			<div style={{ flex: 1, width: '100vw' }}>
				<LinearProgress variant='determinate' value={this.props.stageProgressBar.currentProgress} />
			</div>
		);
	}

	renderPreExperienceComponents() {
		const {
			gameData,
			gameStyles,
			logoMedia,
			windowState,
			topSceneContainerTitle,
			userData
		} = this.props;

		return (
			<div style={Object.assign({}, styles.container, { borderBottom: `1px solid ${gameStyles.secondary.color}` })}>
				{this.renderMenuDrawer()}
				<div style={styles.headerContainer}>
					<h1 style={Object.assign({}, gameStyles.primary, styles.headerTitleText, { color: gameStyles.primary.color })}>{gameData.name}</h1>
				</div>
				<div style={styles.topContent}>
					<div style={styles.menuContainer}>
						<IconButton style={Object.assign({}, styles.menuIconParent, { color: gameStyles.primary.color })} onClick={() => {
							this.setState({ drawerOpen: true });
						}}>
							<MenuIcon style={styles.menuIcon}/>
						</IconButton>
					</div>
					<div style={styles.titleContainer}>
						<h1 style={Object.assign({}, gameStyles.secondary, styles.mainTitleText, { fontSize: gameStyles.secondary.fontSize })}>{this.props.topContainerName}</h1>
					</div>
					<div style={styles.menuContainer} />
				</div>
			</div>
		);
	}

	renderExperienceComponents() {
		const {
			gameData,
			gameStyles,
			logoMedia,
			windowState,
			topSceneContainerTitle,
			userData
		} = this.props;

		return (
			<div style={Object.assign({}, styles.container, { borderBottom: `1px solid ${gameStyles.secondary.color}` })}>
				{this.renderMenuDrawer()}
				<div style={styles.headerContainer}>
					<h1 style={Object.assign({}, gameStyles.primary, styles.headerTitleText, { color: gameStyles.primary.color })}>{gameData.name}</h1>
				</div>
				<div style={styles.topContent}>
					<div style={styles.menuContainer}>
						<IconButton style={Object.assign({}, styles.menuIconParent, { color: gameStyles.primary.color })} onClick={() => {
							this.setState({ drawerOpen: true });
						}}>
							<MenuIcon style={styles.menuIcon}/>
						</IconButton>
					</div>
					{this.props.primaryTimerProps.timer ?
						<div style={styles.primaryTimerContainer}>
							{this.renderPrimaryTimer()}
						</div>
						:
						<div style={{ display: 'flex', flex: 1 }} />}
					<div style={styles.pointsContainer}>
						<h1 style={styles.pointsValueText}>{((gameData.points_preface === '' || gameData.points_preface === null) ? '' : gameData.points_preface) + GLOBAL.numberWithCommas(userData.totalPoints)}</h1>
						<h2 style={styles.pointsTypeText}>{(gameData.points_plural === '' || gameData.points_plural === null) ? '' : gameData.points_plural}</h2>
					</div>
				</div>
				<div style={styles.informationContainer}>
					<div style={styles.infoTopContainer}>
						<div style={styles.titleContainerLeft}>
							<h1 style={Object.assign({}, gameStyles.secondary, styles.mainTitleText, { fontSize: gameStyles.secondary.fontSize })}>{this.props.topContainerName}</h1>
						</div>
						<div style={styles.secondaryTimerContainer}>
							{this.props.secondaryTimerProps.timer ? this.renderSecondaryTimer() : <div />}
						</div>
					</div>
					<div style={{ display: 'flex', flex: 1 }}>
						{(windowState === 'stage' || windowState === 'interaction') && this.props.showProgressBar ? this.renderExperienceProgress() : <div /> }
					</div>
				</div>
			</div>
		);
	}

	renderSceneState() {
		switch (this.props.userData.position.scene) {
		case 'pre-experience':
			return this.renderPreExperienceComponents();
		case 'experience':
			return this.renderExperienceComponents();
		default:
			return this.renderSummaryComponents();
		}
	}

	render() {
		return this.renderSceneState();
	}
}
