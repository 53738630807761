/* --- Admin portal url --- */
let prepend = 'https://app-admin.doextraordinary.com/'; /* --- Live --- */
// let prepend = 'https://staging.app-admin.doextraordinary.com/'; /* --- Staging --- */
// let prepend = 'http://192.168.0.11:1234/'; /* --- Dev local --- */
/* --- Storage url --- */
let resource = 'https://app-admin.doextraordinary.com/'; /* --- Live & staging --- */
// let resource = 'http://192.168.0.11:1234/'; /* --- Dev local --- */
/* --- Leaderboard plugin url --- */
let leaderboardAddress = 'https://leaderboard.doextraordinary.com/'; /* --- Live --- */
// let leaderboardAddress = 'https://staging.leaderboard.doextraordinary.com/'; /* --- Staging --- */
// let leaderboardAddress = 'http://192.168.0.11/wordpress/index.php/'; /* --- Dev local --- */
/* --- Dropbox token --- */
let dropbox_token = 'bWaJfgS03eAAAAAAAAAACiTOEIhMdlKj3wbfU7iGn_jgcQqtpNIHwyBNF2JPBDDH'; /* --- Live --- */
// let dropbox_token = '3wAWQAtP0YAAAAAAAAADgDlDLjS5LfW7dia2WmzSrx7SSfTO1qBuKrEQ3eM24Qpu'; /* --- Dev --- */

module.exports = {
	// ASSETS_PATH: RNFetchBlob.fs.dirs.DocumentDir + '/assets/',
	GAME_NUMBER: null,
	GAME_NAME: null,
	USER_ID: null,
	PARENT: prepend,
	REMOTE_URL: prepend + 'api/game/',
	RESOURCE_URL: resource + 'storage/',
	PORTAL_LOGIN_URL: prepend + 'api/portal/login',
	PORTAL_LOGIN_BEACON_URL: prepend + 'api/portal/beacon-login',
	PORTAL_GAMELIST_URL: prepend + 'api/portal/game-list',
	PORTAL_DEFAULT_EXPERIENCE: prepend + 'api/portal/default-experience-query',
	REPORTING_URL: prepend + 'api/report',
	LEADERBOARD_URL: leaderboardAddress + 'wp-json/api/leaderboard/data',
	DROP_BOX_TOKEN: dropbox_token,
	AUTO_LOGIN_NAME: 'superAdmin@doextraordinary.com.au',
	AUTO_LOGIN_PW: '2one.Five'
};
