// react resources imports
import React, { Component } from 'react';

export default class TimerBox extends Component {
	formatTimeGroup(time) {
		let formatedTime = ('0' + time).slice(-2);
		return formatedTime;
	}

	render() {
		let hrs = this.formatTimeGroup(Math.floor((this.props.timeRemaining / (60 * 60)) % 24));
		let min = this.formatTimeGroup(Math.floor((this.props.timeRemaining / 60) % 60));
		let sec = this.formatTimeGroup(Math.floor((this.props.timeRemaining) % 60));

		return (
			<div style={this.props.style}>
				<div style={this.props.titleContainer}>
					<h1 style={this.props.titleStyle}>{this.props.timerTitleText}</h1>
				</div>
				<div style={ Object.assign({}, this.props.timerContainer, { backgroundColor: this.props.style.backgroundColor }) }>
					<h1 style={Object.assign({}, this.props.timerTextStyle, { color: (this.props.timeRemaining > 60 ? this.props.timerTextStyle.color : 'red') })}>{ hrs + ' : ' + min + ' : ' + sec}</h1>
				</div>
			</div>
		);
	}
}
