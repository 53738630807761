// import GLOBAL from '../lib/Globals';
import * as gameActions from './Game';
import * as uniModalActions from './UniversalModal';
import * as timerActions from './Timers';
import * as eventActions from './Event';
import * as userActions from './User';
import * as interactionActions from './Interaction';

/* action types */

export const ASSIGN_GAME_MAP = 'ASSIGN_GAME_MAP';
export const START_MASTER_STAGE = 'START_MASTER_STAGE';
export const START_STAGE = 'START_STAGE';
export const SET_STAGE_INDEX = 'SET_STAGE_INDEX';
export const START_INTERACTION = 'START_INTERACTION';
export const SET_INTERACTION_INDEX = 'SET_INTERACTION_INDEX';
export const ASSIGN_FLOATING_BUTTONS = 'ASSIGN_FLOATING_BUTTONS';
export const SUBMIT_LOCK = 'SUBMIT_LOCK';
export const GIVE_GAME_FEEDBACK = 'GIVE_GAME_FEEDBACK';
export const PROCESS_GAME_ACTION = 'PROCESS_GAME_ACTION';
export const UPDATE_INTERACTION_VALUES = 'UPDATE_INTERACTION_VALUES';
export const UNLOCK_INTERACTION = 'UNLOCK_INTERACTION';
export const UPDATE_STAGE_VALUES = 'UPDATE_STAGE_VALUES';
export const UNLOCK_STAGE = 'UNLOCK_STAGE';
export const UPDATE_MASTER_STAGE_VALUES = 'UPDATE_MASTER_STAGE_VALUES';
export const ADD_PREV_ANSWER = 'ADD_PREV_ANSWER';
export const ADD_PREV_ANSWER_STICKY_HEADER = 'ADD_PREV_ANSWER_STICKY_HEADER';
export const UPDATE_PREV_ANSWER = 'UPDATE_PREV_ANSWER';
export const ASSIGN_MENU_BUTTONS = 'ASSIGN_MENU_BUTTONS';

/* action creators */

// all movement calls throughout the app are now routed through this function
export function setUserLocation(mStgIndex, stgIndex, interactionIndex) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let mountedTimers = getState().mountedTimers;

		// makes sure we have the game timer mounted if we navigate past the start button through the admin menu
		if (gameData.extra_settings.includes('time_restriction') && !mountedTimers.gameTimer) {
			dispatch(timerActions.startGameTimer());
		}

		// if an interaction has been started, there will be an active interactionInterval object acting as a timer for tracking how long the interaction was played for
		if (getState().timerIntervals.interactionInterval) {
			dispatch(timerActions.stopInteractionTimer());
		}

		dispatch(startMasterStage(mStgIndex));

		if (stgIndex !== undefined && stgIndex !== null) {
			dispatch(startStage(stgIndex));
		}

		if (interactionIndex !== undefined && interactionIndex !== null) {
			dispatch(startInteraction(interactionIndex));
		}
	};
}

export function assignGameMap() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;

		dispatch({
			type: ASSIGN_GAME_MAP,
			imageAddress: gameData.map
		});
	};
}

export function startMasterStage(newIndex) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let index = newIndex;
		let moved = false;
		let showMapButton = false;
		let imageAddress = '';
		let showSelectionInfo = gameData.master_stages[index].stage_sel_instructions.length > 0 ? true : false;
		let showInfoButton = false;
		let showNotesButton = false;

		// update visibility of floating buttons
		if (gameData.map !== '' || gameData.master_stages[index].master_stage_map_image !== '') {
			showMapButton = true;

			if (gameData.master_stages[index].master_stage_map_image !== '') {
				imageAddress = gameData.master_stages[index].master_stage_map_image;
			}
		}

		if ((gameData.master_stages[index].instruction.html !== undefined && gameData.master_stages[index].instruction.html.length > 0) || (gameData.master_stages[index].instruction.bg !== undefined && gameData.master_stages[index].instruction.bg !== '')) {
			showInfoButton = true;
		}

		if (gameData.extra_settings.includes('notes_enabled')) {
			showNotesButton = true;
		}

		dispatch({
			type: START_MASTER_STAGE,
			index: index,
			windowState: 'master_stage',
			masterStageTitle: gameData.master_stages[index].title,
			imageAddress: imageAddress,
			backBtnVisibility: false,
			selectionInfoVisibility: showSelectionInfo,
			mapVisibility: showMapButton,
			infoVisibility: showInfoButton,
			noteVisibility: showNotesButton,
			hintsBtnVisibility: false,
			scene: 'experience',
			prevPos: {
				master_stage: cMSI,
				stage: cSI,
				interaction: cINT
			},
			currentPos: {
				master_stage: index,
				stage: null,
				interaction: null
			}
		});

		// check if the new index is different to the current master stage index
		if (newIndex !== cMSI) {
			moved = true;

			dispatch(eventActions.setEventTriggers());
			dispatch(timerActions.updateMasterStageTimer());
			// dispatch(gameActions.updateGameStyles());
		}

		if (gameData.master_stages[index].structure === 'Linear') {
			// go to first non completed stage
			for (let i = 0; i < userData.master_stage[index].stage.length; i++) {
				if (userData.master_stage[index].stage[i].completed === false) {
					dispatch(startStage(i), moved);
					break;
				}
			}
		} else { // non linear master stage
			// reset stage index
			if (cSI !== null) {
				dispatch(setStageIndex(null));
				dispatch(timerActions.updateStageTimer());
			}
			// reset interaction index
			if (cINT !== null) {
				dispatch(setInteractionIndex(null));
			}
		}

		dispatch(assignFloatingButtons());
	};
}

export function startStage(newIndex, mStgMoved) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let moved = false;
		let imageAddress = '';
		let showSelectionInfo = gameData.master_stages[cMSI].stages[newIndex].selection_instructions.length > 0 ? true : false;
		let showInstructions = false;
		let showBackButton = false;
		let showStageMap = false;
		let showStageTime = (gameData.master_stages[cMSI].stages[newIndex].time_restriction !== '' ? true : false);
		let showYourPoints = (gameData.master_stages[cMSI].stages[newIndex].game_mode === 'Scavenger' ? true : false);
		let showProgressBar = (gameData.master_stages[cMSI].stages[newIndex].show_progress !== 'no' ? true : false);
		let progressInterval = showProgressBar ? 100 / gameData.master_stages[cMSI].stages[newIndex].puzzles.length : 0;
		let completedInteractions = 0;

		// update completed Interactions value if there is a show progress bar
		if (showProgressBar) {
			for (let j = 0; j < userData.master_stage[cMSI].stage[newIndex].puzzle.length; j++) {
				if (userData.master_stage[cMSI].stage[newIndex].puzzle[j].completed) {
					completedInteractions += progressInterval;
				}
			}
		}

		if (gameData.master_stages[cMSI].structure === 'Non-Linear' || gameData.master_stages[cMSI].abandon_stage_allowed === 'yes') {
			showBackButton = true;
		}

		// instructions button display
		if ((gameData.master_stages[cMSI].stages[newIndex].instructions.html !== undefined && gameData.master_stages[cMSI].stages[newIndex].instructions.html.length > 0) || (gameData.master_stages[cMSI].stages[newIndex].instructions.bg !== undefined && gameData.master_stages[cMSI].stages[newIndex].instructions.bg !== '')) {
			showInstructions = true;
		}

		if (getState().expSceneButtons.mapButtonVis || gameData.master_stages[cMSI].stages[newIndex].selection_map !== '') {
			showStageMap = true;
			if (gameData.master_stages[cMSI].stages[newIndex].selection_map !== '') {
				imageAddress = gameData.master_stages[cMSI].stages[newIndex].selection_map;
			}
		}

		dispatch({
			type: START_STAGE,
			index: newIndex,
			windowState: 'stage',
			titleType: gameData.stage_singular,
			showStageTime: showStageTime,
			imageAddress: imageAddress,
			backBtnVisibility: showBackButton,
			stageTitle: gameData.master_stages[cMSI].stages[newIndex].display_title,
			selectionInfoVisibility: showSelectionInfo,
			showYourPoints: showYourPoints,
			mapVisibility: showStageMap,
			showInstructions: showInstructions,
			enableProgressBar: showProgressBar,
			progressInterval: progressInterval,
			currentProgress: completedInteractions,
			prevPos: {
				master_stage: cMSI,
				stage: cSI,
				interaction: cINT
			},
			currentPos: {
				master_stage: cMSI,
				stage: newIndex,
				interaction: null
			}
		});

		// only update the stage index if it actually changes
		if (newIndex !== cSI || mStgMoved) {
			moved = true;

			dispatch(eventActions.setEventTriggers());
			dispatch(timerActions.updateStageTimer());
			// dispatch(gameActions.updateGameStyles());
		}

		dispatch(assignFloatingButtons());

		// if its a linear stage
		if (gameData.master_stages[cMSI].stages[newIndex].structure === 'Linear') {
			// go to the first non completed interaction
			for (let i = 0; i < userData.master_stage[cMSI].stage[newIndex].puzzle.length; i++) {
				if (!userData.master_stage[cMSI].stage[newIndex].puzzle[i].completed) {
					dispatch(startInteraction(i), moved);
					break;
				}
			}
		}
	};
}

// Set Stage Index - allows for the updating of the stage index without changing window state
export function setStageIndex(index) {
	return {
		type: SET_STAGE_INDEX,
		index: index
	};
}

export function startInteraction(newIndex, stgMoved) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let showBackButton = true;
		let showActualRewardLabel = false;
		let showHintsButton = false;
		let interactionReward = '';
		let interaction = gameData.master_stages[cMSI].stages[cSI].puzzles[newIndex];
		let interactionContent = interaction.type !== 'content' ? interaction.question : interaction.answer.content;
		let showAttempts = false;
		let intAttempts = parseInt(interaction.attempts);
		let submitButtonText = 'SUBMIT';
		let showTextInputField = interaction.type === 'text' ? true : false;
		let intHints = interaction.hints && interaction.hints.length > 0 ? interaction.hints : [];

		// run timer for interaction
		dispatch(timerActions.runInteractionTimer());

		if (intAttempts > 0) {
			showAttempts = true;
			intAttempts = intAttempts - userData.master_stage[cMSI].stage[cSI].puzzle[newIndex].attempts;

			if (interaction.repeatable_interaction === 'yes') {
				intAttempts = 0;
			}
		}

		// determines whether to show the reward label for an interaction
		if (interaction.points || interaction.display_points) {
			showActualRewardLabel = true;
			interactionReward = interaction.display_points ? interaction.display_points : interaction.points;
		}

		// update the visual state for the Hints button
		if (interaction.hints !== undefined && interaction.hints.length > 0) {
			showHintsButton = true;
		}

		// show back button
		if (gameData.master_stages[cMSI].structure === 'Linear' || gameData.master_stages[cMSI].abandon_stage_allowed === 'no') {
			if (gameData.master_stages[cMSI].stages[cSI].structure === 'Linear') {
				showBackButton = false;
			}
		}

		// if there is an admin defined submit button text value use that otherwise use the predefined values
		if (interaction.submit_button_title !== undefined && interaction.submit_button_title !== '') {
			submitButtonText = interaction.submit_button_title;
		} else {
			// text and image selection are not present since they user the default submit button text
			switch (interaction.type) {
			case 'proximity':
				submitButtonText = 'SEARCH';
				break;
			case 'image scanning qr':
				submitButtonText = 'SCAN';
				break;
			case 'image scanning barcode':
				submitButtonText = 'SCAN';
				break;
			case 'take_image':
				submitButtonText = 'TAKE PHOTO';
				break;
			case 'take_video':
				submitButtonText = 'TAKE VIDEO';
				break;
			case 'content':
				submitButtonText = 'CONTINUE';
				break;
			case 'survey':
				submitButtonText = 'SUBMIT';
				break;
			case 'number':
				submitButtonText = 'KEYPAD';
				break;
			default:
				submitButtonText = 'CHECK ANSWER';
				break;
			}
		}

		if (interaction.type === 'survey') {
			dispatch(interactionActions.setUpSurvey(interaction.resolutions));
		}

		dispatch({
			type: START_INTERACTION,
			index: newIndex,
			interactionType: interaction.type,
			windowState: 'interaction',
			hints: intHints,
			backBtnVisibility: showBackButton,
			showReward: showActualRewardLabel,
			rewardValue: interactionReward,
			hintsButtonVis: showHintsButton,
			interactionContent: interactionContent,
			userNoteAllowed: interaction.text_field === 'yes' ? true : false,
			intAttemptsVisibility: showAttempts,
			intAttempts: intAttempts,
			submitButtonText: submitButtonText,
			showTextInputField: showTextInputField,
			prevPos: {
				master_stage: cMSI,
				stage: cSI,
				interaction: cINT
			},
			currentPos: {
				master_stage: cMSI,
				stage: cSI,
				interaction: newIndex
			}
		});

		// if stgMoved is true, then most likely we've gone from a stage with a single interaction to another with a single interaction, resulting in the interaction index not changing
		if (newIndex !== cINT || stgMoved) {
			dispatch(eventActions.setEventTriggers());
		}

		// required for generation of floating buttons if button states change
		dispatch(assignFloatingButtons());
	};
}

// Set Interaction Index - allows for the updating of the interaction index without changing window state
export function setInteractionIndex(index) {
	return {
		type: SET_INTERACTION_INDEX,
		index: index
	};
}

export function lockSubmitButton(bool) {
	return {
		type: SUBMIT_LOCK,
		locked: bool
	};
}

export function assignFloatingButtons() {
	return (dispatch, getState) => {
		let expSceneButtons = getState().expSceneButtons;
		let windowState = getState().windowState;

		// non state variables
		let floatingButtons = [];

		// help
		if (getState().gameData.help_screen.html && getState().gameData.help_screen.html.length > 0) {
			let buttonObject = {
				id: 'helpBtn',
				title: 'Help',
				floatingTitle: 'Help',
				owner: 'all',
				callback: () => {
					dispatch(uniModalActions.openModal('help'));
				}
			};

			floatingButtons.push(buttonObject);
		}

		// leaderboard
		if (getState().gameData.extra_settings.includes('send_to_leaderboard')) {
			let buttonObject = {
				id: 'leaderBtn',
				title: 'Leaderboard',
				floatingTitle: 'Leader',
				owner: 'all',
				callback: () => {
					window.open(getState().gameData.leaderboard.url_path, '_blank');
				}
			};

			floatingButtons.push(buttonObject);
		}

		// past answers
		if (!getState().gameData.extra_settings.includes('hide_past_answer')) {
			let buttonObject = {
				id: 'answBtn',
				title: 'Prev Answers',
				floatingTitle: 'Answ',
				owner: 'all',
				callback: () => {
					dispatch(uniModalActions.openModal('previousAnswers'));
				}
			};

			floatingButtons.push(buttonObject);
		}

		// back button
		if (getState().showBackButton) {
			let buttonObject = {
				id: 'backBtn',
				title: 'Back',
				floatingTitle: '',
				owner: 'panel_top',
				callback: () => {
					dispatch(backButtonPressed());
				}
			};

			floatingButtons.push(buttonObject);
		}

		// notes button
		if (expSceneButtons.notesButtonVis) {
			let buttonObject = {
				id: 'notesBtn',
				title: 'Notes',
				floatingTitle: 'Notes',
				owner: 'all',
				callback: () => {
					dispatch(uniModalActions.openModal('notes'));
				}
			};

			floatingButtons.push(buttonObject);
		}

		// map
		if (expSceneButtons.mapButtonVis) {
			let buttonObject = {
				id: 'mapBtn',
				title: 'Maps',
				floatingTitle: 'Maps',
				owner: 'all',
				callback: () => {
					dispatch(uniModalActions.openModal('map'));
				}
			};

			floatingButtons.push(buttonObject);
		}

		// info button
		if (expSceneButtons.infoButtonVis && windowState === 'masterStage') {
			let buttonObject = {
				id: 'infoBtn',
				title: 'Information',
				floatingTitle: 'Info',
				owner: 'all',
				callback: () => {
					dispatch(uniModalActions.openModal('info'));
				}
			};

			floatingButtons.push(buttonObject);
		}

		dispatch({
			type: ASSIGN_FLOATING_BUTTONS,
			floatingButtons: floatingButtons
		});
	};
}

export function backButtonPressed() {
	return (dispatch, getState) => {
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;

		// non state variable
		let stage = getState().gameData.master_stages[cMSI].stages[cSI];

		if (stage.structure === 'Linear') {
			dispatch(setUserLocation(cMSI));
		} else {
			if (getState().windowState === 'interaction') {
				dispatch(setUserLocation(cMSI, cSI));
			} else if (getState().windowState === 'stage') {
				dispatch(setUserLocation(cMSI));
			}
		}
	};
}

export function giveGameFeedback(status) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;
		// let beaconSearch = getState().beaconSearch;
		// let idleBeaconInteraction = getState().idleBeaconInteraction;

		// non state variables
		let value = {
			feedback: '',
			buttonText: 'Continue',
			buttonIcon: 'arrow-right'
		};
		let interaction = null;
		let stage = null;
		let masterStage = gameData.master_stages[cMSI];

		// if there is an interaction index value
		if (cINT !== null) {
			interaction = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT];
		}

		// if there is a stage index value
		if (cSI !== null) {
			stage = gameData.master_stages[cMSI].stages[cSI];
		}

		switch (status) {
		case 'interaction_correct':
			if ((interaction.correct_feedback.html || interaction.correct_feedback.bg)) {
				value.feedback = interaction.correct_feedback;
			}
			break;
		case 'interaction_incorrect':
			if ((interaction.incorrect_feedback.html || interaction.incorrect_feedback.bg)) {
				value.feedback = interaction.incorrect_feedback;
				if (interaction.accept_any_answer !== 'yes' || getState().userData.master_stage[cMSI].stage[cSI].puzzle[cINT].attempts !== parseInt(interaction.attempts)) {
					value.buttonText = 'Try Again';
					value.buttonIcon = 'replay';
				}
			}

			// if the incorrect answer has a reward assigned to it, give the player the reward
			if (interaction.resolutions[0].rewards.length > 0) {
				dispatch(userActions.giveReward(0));
			}
			break;
		// maximum interactions for a stage completed
		case 'maxStage':
			if ((stage.success_all_screen.html || stage.success_all_screen.bg)) {
				value = stage.success_all_screen;
			}
			break;
		case 'stageExpired':
			if (stage.failure_screen.html || stage.failure_screen.bg) {
				value.feedback = stage.failure_screen;
			}
			break;
		case 'maxMasterStage':
			if (masterStage.success_screen.html || masterStage.success_screen.bg) {
				value.feedback = masterStage.success_screen;
			}
			break;
		case 'masterExpired':
			if (masterStage.failure_screen.html || masterStage.failure_screen.bg) {
				value.feedback = masterStage.failure_screen;
			}
			break;
		case 'gameWin':
			dispatch(timerActions.stopAllTimers());

			if (gameData.success_screen.html || gameData.success_screen.bg) {
				value.feedback = gameData.success_screen;
				value.buttonText = 'Game Summary';
			}
			break;
		case 'gameOver': // failure based on game time running out
			if (gameData.failure_screen.html || gameData.failure_screen.bg) {
				value.feedback = gameData.failure_screen;
				value.buttonText = 'Game Summary';
			}
			break;
		default:
			break;
		}

		dispatch({
			type: GIVE_GAME_FEEDBACK,
			status: status,
			feedback: value
		});

		if (value.feedback !== '') {
			dispatch(uniModalActions.openModal('feedback', value));
		} else {
			dispatch(processGameAction());
		}
	};
}

// dispatches the next action that needs to happen on button press from the user when the stage completion modal component is active
export function processGameAction() {
	return (dispatch, getState) => {
		let gameStatus = getState().gameFeedback.status;
		let userData = getState().userData;

		// non state variables
		let timerExpired = false;

		if ((getState().currentStageIndex !== null && userData.master_stage[getState().currentMasterStageIndex].stage[getState().currentStageIndex].expired) || userData.master_stage[getState().currentMasterStageIndex].expired) {
			timerExpired = true;
		}

		if (getState().showModal) {
			dispatch(uniModalActions.closeModal());
		}

		dispatch({ type: PROCESS_GAME_ACTION });

		switch (gameStatus) {
		case 'interaction_correct':
		case 'interaction_incorrect':
			if (!timerExpired) {
				dispatch(updateInteractionValues());
			}
			dispatch(lockSubmitButton(false));
			break;
		case 'maxStage': // completed enough interactions for a stage
		case 'stageExpired':
			// compare our current position with the intended position
			dispatch(nextStageAction());
			break;
		case 'masterExpired': // master stage timer ran out
		case 'maxMasterStage': // completed enough stages for a master stage
			dispatch(nextMasterStageAction());
			break;
		case 'gameWin': // completed all master stages for an experience
		case 'gameOver': // game timer ran out
			dispatch(gameActions.goToSummary());
			break;
		default:
			// dispatch(setDebugMsg('Error with processing game action in redux, game status: ' + gameStatus + ' does not exist'));
			break;
		}
	};
}


/* --- Interaction Process logic start --- */
// handles what to do when the user has finished an interaction
export function updateInteractionValues() {
	return (dispatch, getState) => {
		// set all the state variables
		let gameData = getState().gameData;
		let userData = getState().userData;
		// let networkingStatus = getState().networkingStatus;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;
		let interactionTriggers = getState().completionEventTriggers.interactionTriggers;

		// non state variables
		let loc = { mStg: cMSI, stg: cSI, int: cINT };
		let gameStage = gameData.master_stages[cMSI].stages[cSI];
		let gameInteraction = gameStage.puzzles[cINT];
		let userStage = userData.master_stage[cMSI].stage[cSI];
		let userInteraction = userStage.puzzle[cINT];
		let interactionsCompleted = userStage.completionPoints;
		let nINT = cINT + 1; // nextInteraction
		let repeatable_interaction = gameInteraction.repeatable_interaction;
		let repeatable_stage = gameStage.repeatable_stage;
		let maxAttempts = parseInt(gameInteraction.attempts); // need to convert to integer
		let interactionCount = gameStage.puzzles.length;
		let minRequirements = parseInt(gameStage.minimum_requirements);
		let goToNextInteraction = false;
		let goBackToStage = false;
		let stageCompleted = false;
		let triggerCompletion = false;

		let completed = false;
		let locked = false;
		let attempts = userInteraction.attempts;
		let userAnswer = userInteraction.type === 'survey' ? userInteraction.answer.selection : userInteraction.answer;

		if (!userInteraction.completed) {
			// linear stage interaction logic
			if (gameStage.structure === 'Linear') {
				// got interaction correct
				if (userInteraction.correct) {
					// if the stage is repeatable, don't complete the interation
					if (repeatable_stage === 'yes') {
						completed = false;
						triggerCompletion = triggerInteractionCompletionEvent(userData.position.current, interactionTriggers);
						interactionsCompleted += 1; // this may be an issue as it will add one to interactions completed everytime
					} else {
						completed = true;
						interactionsCompleted += 1;
					}

					// still have interactions to complete in stage
					if (nINT < gameStage.puzzles.length) {
						goToNextInteraction = true;
					} else {
						stageCompleted = true;
					}
				} else {
					// interaction incorrect
					attempts += 1;
					// dispatch(interactionActions.subtractAttempt());

					// any answer is accepted
					if (gameInteraction.accept_any_answer === 'yes' || userInteraction.attempts === maxAttempts) {
						interactionsCompleted += 1;
						locked = true;
						// still have interactions to complete in stage
						if (nINT < gameStage.puzzles.length) {
							goToNextInteraction = true;
						} else {
							stageCompleted = true;
						}
					}
				}
			} else {
				// non linear stage interaction logic
				// got interaction correct
				if (userInteraction.correct) {
					if (repeatable_interaction === 'yes' || repeatable_stage === 'yes') {
						completed = false;
					} else {
						completed = true;
						interactionsCompleted += 1;
					}
					triggerCompletion = triggerInteractionCompletionEvent(userData.position.current, interactionTriggers);
					goBackToStage = true; // go back to stage
					dispatch(unlockInteractions(gameStage.puzzles, gameInteraction.title)); // unlock any interactions that were waiting for this interaction to be completed
				} else { // interaction incorrect
					attempts += 1;
					// dispatch(interactionActions.subtractAttempt());

					// if any answer will be accepted
					if (gameInteraction.accept_any_answer === 'yes') {
						if (gameInteraction.repeatable_interaction === 'yes') {
							// if the user has used all their attempts 
							if (attempts === maxAttempts) {
								locked = true;
								completed = true;
								interactionsCompleted += 1; // add point towards completion but don't set the interaction as complete
								goBackToStage = true; // go back to stage
							} else {
								triggerCompletion = triggerInteractionCompletionEvent(userData.position.current, interactionTriggers);
								completed = false;
							}
						} else {
							locked = true;
							completed = true;
							interactionsCompleted += 1;
							goBackToStage = true; // go back to stage
						}
					} else {
						// if repeatable this allows the user to repeat the interaction as many times as they wish
						if (gameInteraction.repeatable_interaction === 'yes') {
							// if the user has used all their attempts
							if (attempts === maxAttempts) {
								locked = true;
								completed = true;
								interactionsCompleted += 1; // add point towards completion but don't set the interaction as complete
								triggerCompletion = triggerInteractionCompletionEvent(userData.position.current, interactionTriggers);
								goBackToStage = true; // go back to stage
							} else {
								triggerCompletion = triggerInteractionCompletionEvent(userData.position.current, interactionTriggers);
								completed = false;
							}
						} else {
							// non linear stage interaction - incorrect
							if (attempts === maxAttempts) {
								locked = true;
								completed = true;
								interactionsCompleted += 1; // add point towards stage completion
								triggerCompletion = triggerInteractionCompletionEvent(userData.position.current, interactionTriggers);
								goBackToStage = true; // go back to stage
								dispatch(unlockInteractions(gameStage.puzzles, gameInteraction.title)); // unlock any interactions that were waiting for this interaction to be completed
							} else {
								completed = false;
							}
						}
					}
				}
			}

			// send the results to the prev answer array in redux
			if (userInteraction.type === 'survey') {
				dispatch(addPrevAnswer(loc, userInteraction.stageName, userInteraction.puzzleName, userInteraction.puzzlePoints, userInteraction.answer.selection, userInteraction.correct));
			} else {
				dispatch(addPrevAnswer(loc, userInteraction.stageName, userInteraction.puzzleName, userInteraction.puzzlePoints, userInteraction.answer, userInteraction.correct));
			}

			// trigger any associated completion events
			if (triggerCompletion) {
				dispatch(eventActions.triggerCompletionEvent('Interaction'));
			}
		}

		dispatch({
			type: UPDATE_INTERACTION_VALUES,
			completed: completed,
			locked: locked,
			attempts: attempts,
			interactionsCompleted: interactionsCompleted,
			loc: loc,
			stageName: userInteraction.stageName,
			interactionName: userInteraction.puzzleName,
			interactionPoints: userInteraction.puzzlePoints,
			answer: userAnswer,
			correct: userInteraction.correct
		});

		dispatch(interactionActions.toggleAnswerLock(false));

		// check if the user has completed the required interactions within the stage and the timer has not run out
		if (stageCompleted || interactionsCompleted === interactionCount || interactionsCompleted === minRequirements) {
			if (!userStage.expired) {
				dispatch(updateStageValues(true, 'updateInteractionValues'));
			}
		} else {
			// linear stage progression if the stage is not complete
			if (goToNextInteraction) {
				dispatch(setUserLocation(cMSI, cSI, nINT));
			}

			// Non-linear stage progression if the stage is not complete
			if (goBackToStage) {
				dispatch(setUserLocation(cMSI, cSI));
			}

			// unlock the submit button
			dispatch(lockSubmitButton(false));
		}
	};
}

export function addPrevAnswer(loc, stageName, puzzleName, puzzlePoints, answer, correct) {
	return (dispatch, getState) => {
		let storedAnswers = getState().currentAnswers.prevAnswers;
		let currentHeaders = getState().currentAnswers.headers;

		// non state variables
		let headerExists = false;
		let lastAnswer = {
			id: loc.mStg + '_' + loc.stg + '_' + loc.int + '_' + 'answer',
			location: loc,
			puzzleName: puzzleName,
			puzzlePoints: puzzlePoints,
			answer: answer,
			correct: correct
		};

		if (Array.isArray(answer)) {
			lastAnswer.answer = answer.join(', ');
		}

		for (let i = 0; i < currentHeaders.length; i++) {
			if (currentHeaders[i].stageName === stageName) {
				headerExists = true;
				break;
			}
		}

		if (!headerExists) {
			dispatch(addPrevAnswerStickyHeader(stageName, loc));
		}

		let prevAnswerIndex = storedAnswers.findIndex(prevAnswer => prevAnswer.id === lastAnswer.id);

		if (prevAnswerIndex === -1) {
			dispatch({
				type: ADD_PREV_ANSWER,
				lastAnswer: lastAnswer
			});
		} else {
			dispatch({
				type: UPDATE_PREV_ANSWER,
				lastAnswer: lastAnswer,
				index: prevAnswerIndex
			});
		}
	};
}

export function addPrevAnswerStickyHeader(header, loc) {
	return (dispatch, getState) => {
		let newIndex = getState().currentAnswers.prevAnswers.length;

		// non state variables
		let headerObj = {
			id: newIndex + '_Sticky',
			location: {
				mStg: loc.mStg,
				stg: loc.stg,
				int: -1
			},
			stageName: header
		};

		dispatch({
			type: ADD_PREV_ANSWER_STICKY_HEADER,
			index: newIndex,
			header: headerObj
		});
	};
}

// unlock any dependent interactions
export function unlockInteractions(interactionArray, currentInteraction) {
	return (dispatch, getState) => {
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;

		for (let i = 0; i < interactionArray.length; i++) {
			if (interactionArray[i].unlock_on_interaction === currentInteraction && !interactionArray[i].completed) {
				userData.master_stage[cMSI].stage[cSI].puzzle[i].locked = false;
				dispatch({
					type: UNLOCK_INTERACTION,
					interactionIndex: i
				});
			}
		}
	};
}

function triggerInteractionCompletionEvent(currentPos, triggerArray) {
	for (let i = 0; i < triggerArray.length; i++) {
		if (triggerArray[i].parameterValue.mStgIndex == currentPos.master_stage && triggerArray[i].parameterValue.stgIndex == currentPos.stage && triggerArray[i].parameterValue.interactionIndex == currentPos.interaction) {
			return true;
		}
	}

	return false;
}
/* --- Interaction Process logic end --- */

function comparePositionValues(posA, posB) {
	Object.entries(posA).forEach((posA_key, posA_value) => {
		Object.entries(posB).forEach((posB_key, posB_value) => {
			if (posA_key === posB_key && posA_value === posB_value) {
				return false;
			}
		});
	});

	return true;
}

/* --- Process Stage logic Start --- */
// updates the stage progress
export function updateStageValues(stageCompleted, calledBy) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let stageTriggers = getState().completionEventTriggers.stageTriggers;

		// non state variables
		let nSI = cSI + 1; // nextStageIndex
		let repeatable_stage = gameData.master_stages[cMSI].stages[cSI].repeatable_stage;
		let mStgCompletionPoints = userData.master_stage[cMSI].completionPoints;
		let stgCompletionPoints = userData.master_stage[cMSI].stage[cSI].completionPoints;
		let stgMinReq = parseInt(gameData.master_stages[cMSI].stages[cSI].minimum_requirements);

		let locked = false;
		let completed = false;

		// the destination this code is supposed to take the user to
		let intendedDest = {
			master_stage: cMSI,
			stage: nSI,
			interaction: null
		};
		let allowMove = comparePositionValues(intendedDest, userData.position.current);
		let triggerCompletion = false;

		// update Userdata with the title of the stage that was just completed
		if (!(userData.master_stage[cMSI].completedStages.includes(gameData.master_stages[cMSI].stages[cSI].title))) {
			userData.master_stage[cMSI].completedStages.push(gameData.master_stages[cMSI].stages[cSI].title);
			mStgCompletionPoints++;
		}

		// Put logic in here for unlocking next stage also, if in linear type game.
		if (stageCompleted && repeatable_stage === 'yes') {
			// stage is repeatable, need to reset all of the stage variables so it can be repeated
			completed = false;
			locked = false;
			stgCompletionPoints = 0;
			mStgCompletionPoints = userData.master_stage[cMSI].completionPoints;
			// dispatch(eventActions.completeRepeatable(true, 'stage')); // used by Event Manager for triggering an event when on a repeatable stage or interaction
			triggerCompletion = true;
		} else if (stageCompleted) {
			// stage was completed
			completed = true;
			locked = true;
			triggerCompletion = true;
		} else {
			// stage timer expired
			completed = false;
			locked = true;
			triggerCompletion = true;
		}

		// Condition to check if this is a linear master stage and to unlock the next stage if there is one
		if (gameData.master_stages[cMSI].structure === 'Non-Linear') {
			// if it's a linear master stage then we don't care if the next stage is locked or not, we're going to it regardless
		// 	while (nSI < gameData.master_stages[cMSI].stages.length) {
		// 		// Checks if the next stage requires conditions to be met to be unlocked
		// 		if (gameData.master_stages[cMSI].stages[nSI].unlock_on !== 'None' || userData.master_stage[cMSI].stage[nSI].locked === false) {
		// 			nSI++;
		// 		} else {
		// 			nSI++;
		// 			dispatch({
		// 				type: UNLOCK_STAGE,
		// 				stageIndex: nSI
		// 			});
		// 			break;
		// 		}
		// 	}
		// } else { // non linear master stage
			// loop through all stages and unlock any that are waiting for this stage to be completed
			for (let i = 0; i < gameData.master_stages[cMSI].stages.length; i++) {
				if (gameData.master_stages[cMSI].stages[i].unlock_on === gameData.master_stages[cMSI].stages[cSI].title) {
					userData.master_stage[cMSI].stage[i].locked = false;
					dispatch({
						type: UNLOCK_STAGE,
						stageIndex: i
					});
				}
			}
		}

		// trigger any associated completion events
		if (triggerCompletion && stageTriggers.length > 0) {
			dispatch(eventActions.triggerCompletionEvent('Stage'));
		}

		dispatch({
			type: UPDATE_STAGE_VALUES,
			completed: completed,
			locked: locked,
			stgCompletionPoints: stgCompletionPoints,
			mStgCompletionPoints: mStgCompletionPoints
		});

		// as long as the master stage timer has not expired - does not matter if master stage has no timer or not
		if (!userData.master_stage[cMSI].expired) {
			// don't call giveGameFeedback if the stage timer has already expired
			if (!userData.master_stage[cMSI].stage[cSI].expired) {
				// if the stage is not repeatable
				if (repeatable_stage === 'no') {
					// completed all interactions
					if (stgCompletionPoints === gameData.master_stages[cMSI].stages[cSI].puzzles.length) {
						dispatch(giveGameFeedback('maxStage'));
					} else if (stgCompletionPoints === stgMinReq) { // completed minimum interactions
						dispatch(giveGameFeedback('maxStage'));
					} else {
						// user started mid way through the stage and completed it
						if (stageCompleted) {
							dispatch(giveGameFeedback('maxStage'));
						} else {
							dispatch(nextStageAction());
						}
					}
				} else {
					dispatch(nextStageAction());
				}
			} else {
				if (allowMove) {
					dispatch(giveGameFeedback('stageExpired'));
				}
			}
		} else {
			if (allowMove) {
				dispatch(nextStageAction());
			}
		}
	};
}

// processes what is supposed to happen next when a stage is completed
export function nextStageAction() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let submitLocked = getState().submitLocked;

		// non state variables
		let index = cSI;
		let masterStage = gameData.master_stages[cMSI];
		let repeatable_stage = masterStage.stages[cSI].repeatable_stage;

		if (repeatable_stage !== 'yes') {
			index = cSI + 1;
		}

		// if there are still stages to complete for the master stage
		if (index < masterStage.stages.length) {
			// if the master stage is linear
			if (masterStage.structure === 'Linear') {
				dispatch(setUserLocation(cMSI, index));
				// dispatch(userActions.updateProgressBarPercentage(0));
				// unlock buttons
				if (submitLocked) {
					dispatch(lockSubmitButton(false));
				}
			} else { // non linear master stage
				dispatch(updateMasterStageValues());
			}
		} else { // this is the last stage for the master stage
			dispatch(updateMasterStageValues()); // check what master stage action needs to happen next
		}
	};
}
/* --- Process Stage logic end --- */

/* --- Process Master Stage logic start --- */
// check if the user's game progress and dispatch the relevant action that needs to happen
export function updateMasterStageValues() {
	return (dispatch, getState) => {
		// set all the state variables
		let gameData = getState().gameData;
		let userData = getState().userData;
		let cMSI = getState().currentMasterStageIndex;

		// non state variables
		let nMS = cMSI + 1; // nextMasterStage
		let mStgMinReq = parseInt(gameData.master_stages[cMSI].minimum_requirements);
		// the destination this code is supposed to take the user to
		let intendedDest = {
			master_stage: nMS,
			stage: null,
			interaction: null
		};
		let allowMove = comparePositionValues(intendedDest, userData.position.current);
		let completed = false;
		let completionPoints = userData.master_stage[cMSI].completionPoints;
		let nextAction = '';


		// process action for master stage time expiring
		if (userData.master_stage[cMSI].expired) {
			if (allowMove) {
				nextAction = 'masterExpired';
			}
		} else {
			// check if the user has completed all of the stages to progress to the next master stage.
			if (completionPoints >= gameData.master_stages[cMSI].stages.length) {
				completionPoints = gameData.master_stages[cMSI].stages.length;
				completed = true;

				if (nMS < gameData.master_stages.length) {
					nextAction = 'maxMasterStage';
				} else {
					nextAction = 'gameOver';
				}
			} else if (completionPoints === mStgMinReq) {
				completed = true;
				nextAction = 'maxMasterStage';
			} else {
				// We'll only get to this point on a linear master stage if we're on the last stage of the current master stage
				if (gameData.master_stages[cMSI].structure === 'Linear') {
					completed = true;
					nextAction = 'maxMasterStage';
				}

				if (getState().submitLocked) {
					dispatch(lockSubmitButton(false));
				}
			}
		}

		dispatch({
			type: UPDATE_MASTER_STAGE_VALUES,
			completed: completed,
			completionPoints: completionPoints
		});

		if (nextAction.length > 0) {
			dispatch(giveGameFeedback(nextAction));
		} else {
			dispatch(setUserLocation(cMSI));
		}

		// switch (nextAction) {
		// 	case 'next':
		// 		dispatch(giveGameFeedback('maxMasterStage'));
		// 		break;
		// 	case 'expired':
		// 		dispatch(giveGameFeedback('masterExpired'));
		// 		break;
		// 	case 'gameOver':
		// 		dispatch(giveGameFeedback('gameOver'));
		// 		break;
		// 	default:
		// 		// go back to stage selection for this master stage
		// 		dispatch(setUserLocation(cMSI));
		// 		break;
		// }
	};
}

// process what is supposed to happen next when a master stage is completed
export function nextMasterStageAction() {
	return (dispatch, getState) => {
		let cMSI = getState().currentMasterStageIndex;

		// non state variables
		let nMSI = cMSI + 1; // nextMasterStageIndex

		// if it's not the last master stage for the experience
		if (nMSI < getState().gameData.master_stages.length) {
			dispatch(setUserLocation(nMSI));
			// unlock buttons
			if (getState().submitLocked) {
				dispatch(lockSubmitButton(false));
			}
		} else { // if it was the last master stage
			dispatch(giveGameFeedback('gameWin'));
		}
	};
}
/* --- Process Master Stage logic end --- */
