import React, { Component } from 'react';

// third party resources imports
import { Button, IconButton, TextField } from '@material-ui/core';
import {
	ArrowBack as BackIcon,
	MoreHoriz as MoreIcon,
	WbIncandescent as HintsIcon
} from '@material-ui/icons';

// custom resources imports
import Styles from '../../lib/Styles';

// container components
import SelectionPanel from '../SelectionPanel';
import InteractionPanel from '../InteractionPanel.js';
import WYSIWYGRenderer from '../WYSIWYGRenderer';

// declares local variables
const styles = Styles.scenes.middleSceneContainer;

export default class MiddleContainer extends Component {
	renderLeftComponent() {
		return (
			<div style={{ flex: 1 }}>
				{this.props.showBackButton ? <Button variant='contained' color='primary'
					onClick={() => {
						this.props.backButtonPressed();
					}}>
					<p>Back</p>
				</Button> : <div />}
			</div>
		);
	}

	renderRightComponent() {
		const {
			gameData,
			gameStyles,
			screenDimensions,
			currentMasterStageIndex,
			currentStageIndex,
			windowState
			// showSelectionFeedback,
		} = this.props;

		let stagesArray = gameData.master_stages[currentMasterStageIndex].stages;
		let rightComponent;

		switch (windowState) {
		case 'master_stage':
			rightComponent = (
				<div style={{ display: 'flex', flex: 1 }}>
					{/*<div style={{ flex: 1, borderWidth: 1, borderColor: 'red' }}>
						{/*showSelectionFeedback &&
							<WYSIWYGRenderer
							html={gameData.master_stages[currentMasterStageIndex].stage_sel_instructions}
							// content={gameData.master_stages[currentMasterStageIndex].stage_sel_instructions}
							// textStyles={gameStyles}
							// centered={false}
							// expandable={true}
							// backgroundImage={false}
							// basicView={true}
							{...this.props}
						/>}
					</div>*/}
					<div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ display: 'flex', flex: 1, maxHeight: gameStyles.secondary.fontSize * 1.2 }}>
							<h1 style={Object.assign({}, gameStyles.secondary, { color: 'white', textAlign: 'center' })}>{gameData.stage_plural}</h1>
						</div>
						<SelectionPanel
							dataArray={stagesArray}
							selectionType={'stage'}
							{...this.props}
						/>
					</div>
				</div>);
			break;
		case 'stage':
			const stage = stagesArray[currentStageIndex];
			const interactionArray = stage.puzzles;

			rightComponent = (
				<div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
					<div style={{ display: 'flex', flex: 1 }}>
						{screenDimensions.size !== 'small' &&
							<WYSIWYGRenderer
								html={gameData.master_stages[currentMasterStageIndex].stages[currentStageIndex].selection_instructions}
								// textStyles={gameStyles}
								// centered={false}
								// expandable={true}
								// backgroundImage={false}
								// basicView={true}
								{...this.props}
							/>}
					</div>
					<div style={{ display: 'flex', flex: 1 }}>
						<SelectionPanel
							dataArray={interactionArray}
							selectionType={'interaction'}
							selectionImageStyle={{ width: '180px', height: '120px' }}
							xs={6}
							sm={6}
							md={4}
							{...this.props}
						/>
					</div>
				</div>);
			break;
		case 'interaction':
			rightComponent = (
				<div style={{ display: 'flex', flex: 1 }}>
					<InteractionPanel	{...this.props}	/>
				</div>);
			break;
		default:
			rightComponent = <div style={{ display: 'flex', flex: 1 }} />;
			break;
		}

		return rightComponent;
	}

	render() {
		const {	screenDimensions, windowState	} = this.props;

		return (
			<div style={styles.container}>
				{screenDimensions.size !== 'small' && (windowState === 'stage' || windowState === 'interaction') ? this.renderLeftComponent() : <div />}
				{this.renderRightComponent()}
			</div>
		);
	}
}
