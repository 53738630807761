import React from 'react';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { deepPurple, amber } from '@material-ui/core/colors';

const theme = createMuiTheme({
	palette: {
		primary: {
			// Purple and green play nicely together.
			main: deepPurple[500]
		},
		secondary: {
			// This is green.A700 as hex.
			main: amber[500]
		}
	}
});

export default function Palette(props) {
	return (
		<ThemeProvider theme={theme}>
			{props.children}
		</ThemeProvider>
	);
}
