// action types
import {
	UPDATE_ATTEMPTS,
	SET_INTERACTION_TEXT,
	SET_IMAGE_SELECTION,
	RESET_IMAGE_SELECTION,
	SET_SURVEY_SELECTION,
	TOGGLE_ANSWER_PROCESS_LOCK,
	SETUP_SURVEY_OPTIONS
} from '../actions/Interaction';

import {
	START_INTERACTION
} from '../actions/Experience';

/* reducers */
export function interactionAttempts(state = { attempts: 0, showAttempts: false }, action) {
	switch (action.type) {
	case START_INTERACTION:
		state.attempts = action.intAttempts;
		state.showAttempts = action.intAttemptsVisibility;
		return state;
	case UPDATE_ATTEMPTS:
		state.attempts = action.attempts;
		return state;
	default:
		return state;
	}
}

export function interactionTextInput(state = '', action) {
	switch (action.type) {
	case START_INTERACTION:
		state = '';
		return state;
	case SET_INTERACTION_TEXT:
		state = action.text;
		return state;
	default:
		return state;
	}
}

export function interactionImageSelection(state = [], action) {
	switch (action.type) {
	case SET_IMAGE_SELECTION:
		state = action.selection;

		return state;
	case RESET_IMAGE_SELECTION:
		state = [];
		return state;
	default:
		return state;
	}
}

export function answerProcessLock(state = false, action) {
	switch (action.type) {
	case TOGGLE_ANSWER_PROCESS_LOCK:
		state = action.bool;
		return state;
	default:
		return state;
	}
}

export function surveyOptions(state = [], action) {
	switch (action.type) {
	case SETUP_SURVEY_OPTIONS:
		state = action.surveyArray;
		return state;
	default:
		return state;
	}
}

export function interactionSurveySelection(state = '', action) {
	switch (action.type) {
	case SET_SURVEY_SELECTION:
		state = action.selection;
		return state;
	default:
		return state;
	}
}
