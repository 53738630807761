import React, { Component } from 'react';
import Addresses from '../lib/Addresses';

// third party resources
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

// custom components
import Pagination from './Pagination';
import Styles from '../lib/Styles';
import WYSIWYGRenderer from './WYSIWYGRenderer';

const styles = Styles.components.customSwiper;

export default class CustomSwiper extends Component {
	constructor(props) {
		super(props);

		let swiperSlidesArray = this.props.swiperData.map((rawData) => {
			let bgImage = rawData.bg.replace('/storage/', '');
			let bgImageURL = Addresses.RESOURCE_URL + bgImage;

			return (
				<WYSIWYGRenderer style={styles.wysiwygContainer} bgStyle={{ display: 'flex', flex: 1 }} html={rawData.html} bgImage={bgImageURL} />
			);
		});

		if (this.props.lastSlide) {
			swiperSlidesArray.push(this.props.lastSlide);
		}

		this.state = {
			slideIndex: 0,
			swiperSlides: swiperSlidesArray
		};
	}

	moveSlide(direction) {
		if (direction === 'next') {
			this.handleChangeIndex(this.state.slideIndex + 1);
		} else {
			this.handleChangeIndex(this.state.slideIndex - 1);
		}
	}

	handleChangeIndex(index) {
		this.setState({ slideIndex: index });
	}

	renderArrowButton(direction) {
		return (
			<IconButton
				onClick={() => {
					this.moveSlide(direction);
				}}>
				{direction === 'next' ? <KeyboardArrowRight style={styles.arrowIcon} /> : <KeyboardArrowLeft style={styles.arrowIcon} />}
			</IconButton>
		);
	}

	render() {
		const { gameStyles } = this.props;
		const { swiperSlides } = this.state;

		return (
			<div style={styles.container}>
				<div style={styles.swiperSideContainer}>
					{this.state.slideIndex !== 0 ? this.renderArrowButton('prev') : <div style={{ display: 'flex', flex: 1 }} />}
				</div>
				<div style={styles.swiperCenterContainer}>
					<SwipeableViews
						style={styles.swiperContainer}
						children={swiperSlides}
						index={this.state.slideIndex}
						onChangeIndex={(index) => {
							this.handleChangeIndex(index);
						}}
						enableMouseEvents />
					<Pagination
						dots={swiperSlides.length}
						index={this.state.slideIndex}
						dotActiveColor={gameStyles.secondary.color}
					/>
				</div>
	    	<div style={styles.swiperSideContainer}>
	    		{this.state.slideIndex + 1 !== this.state.swiperSlides.length ? this.renderArrowButton('next') : <div style={{ display: 'flex', flex: 1 }} />}
				</div>
    	</div>
		);
	}
}
