import React, { Component } from 'react';

// third party resources
import { Modal } from '@material-ui/core';

// Import custom styles
import Styles from '../lib/Styles';

import ModalWindow from './modal_components/ModalWindow';

// The classes this container may display
import IndividualRego from './pre_experience_scene/IndividualRegistration';
import CustomNumberPad from './CustomNumberpad';
import ZoomableImage from './ZoomableImage';
import PrevAnswersModal from './PreviousAnswers';
import HintsModal from './InteractionHints';
import NotesModal from './NotesModal';

// import LightboxRenderer from './LightboxRenderer';

const styles = Styles.components.universalModal;

export default class UniversalModal extends Component {
	bespokeContent() {
		const { modalProps, gameStyles } = this.props;

		switch (modalProps.screenType) {
		case 'rego_member':
		case 'edit_rego_member':
			return <IndividualRego editMember={modalProps.data.editMember} {...this.props} />;
		case 'keypad':
			let keypadMode = modalProps.data.keypadMode ? modalProps.data.keypadMode : 'admin';
			let responseType = modalProps.data.responseType ? modalProps.data.responseType : null;
			let unlockPin = modalProps.data.unlockPin ? modalProps.data.unlockPin : null;

			return <CustomNumberPad stageStyles={gameStyles} {...this.props} keypadMode={keypadMode} unlockPin={unlockPin} enterLocked={this.props.keypadEnterLocked} responseType={responseType} />;
		case 'map':
			return 	<ZoomableImage style={{ display: 'flex', flex: 1, justifyContent: 'center' }} imgSrc={modalProps.data.bg} />;
		case 'previousAnswers':
			return <PrevAnswersModal data={modalProps.data.prevAnswers} stickyHeaders={modalProps.data.headers} {...this.props} />;
		case 'hints':
			return <HintsModal data={modalProps.data.currentHints} {...this.props} />;
		case 'notes':
			return <NotesModal data={modalProps.data.notes} {...this.props} />;
		default:
			return <div />;
		}
	}

	render() {
		const { modalProps } = this.props;

		return (
			<Modal
				style={styles.container}
				open={this.props.showModal}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<div style={styles.modalContainer}>
					<ModalWindow
						title={this.props.universalModalHeader.header}
						showHeader={this.props.universalModalHeader.headerType !== 'none' ? true : false}
						closeModalWindow={() => this.props.closeModal()}
						{...this.props}>
						{modalProps.modalType === 'bespoke' ? this.bespokeContent() : <div />}
					</ModalWindow>
				</div>
			</Modal>
		);
	}
}
