import React, { Component } from 'react';

// third party resources imports
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup
} from '@material-ui/core';

// custom resources imports
import Styles from '../lib/Styles';

// declares local variables
const styles = Styles.scenes.imageSelection;

export default class SurveySelection extends Component {
	renderRadioSurvey(surveyArray) {
		let buttonArray = [];

		for (let i = 0; i < surveyArray.length; i++) {
			buttonArray.push(
				<FormControlLabel
					style={{ fontSize: 20, color: 'white' }}
					value={surveyArray[i].key}
					control={<Radio />}
					label={surveyArray[i].key}
					labelPlacement='bottom'
				/>
			);
		}
		return buttonArray;
	}


	render() {
		const { surveyOptions } = this.props;

		return (
			<div style={styles.container}>
				<div style={styles.imageSelectionContainer}>
					<FormControl style={{ display: 'flex', flex: 1 }} component='fieldset'>
				  	<RadioGroup style={{ display: 'flex', flex: 1, justifyContent: 'center' }} row value={this.props.interactionSurveySelection} onChange={select => this.props.setSurveySelection(select.target.value)}>
							{this.renderRadioSurvey(surveyOptions)}
						</RadioGroup>
					</FormControl>
				</div>
			</div>
		);
	}
}
