import React, { Component } from 'react';

// custom components
import PaginationDot from './PaginationDot';
import Styles from '../lib/Styles';

const styles = Styles.components.pagination;

export default class Pagination extends Component {
	render() {
		const { index, dots, dotActiveColor } = this.props;
		const children = [];

		let activeColor = dotActiveColor ? dotActiveColor : styles.active;

		for (let i = 0; i < dots; i ++) {
			children.push(
				<PaginationDot key={i} index={i} active={i === index} activeStyle={activeColor} />
			);
		}

		return <div style={styles.root}>{children}</div>;
	}
}
