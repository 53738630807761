// Redux actions
import * as gameActions from './Game';
import * as interactionActions from './Interaction';
import * as uniModalActions from './UniversalModal';

// third party resources imports

// custom resources
export const SET_KEYPAD_NUMBERS = 'SET_KEYPAD_NUMBERS';

/*
 * action creators
 */

export function keypadNumberInput(number) {
	return (dispatch, getState) => {
		let keypadMode = getState().keypadMode;
		let storedNumbers = getState().storedNumbers;

		// non state variables
		let newStoredNums = storedNumbers;

		// If we are in admin mode, compare input to correct combination
		if (keypadMode === 'passcode') {
			if (storedNumbers.length < 4) {
				newStoredNums.push(number);
			}

			dispatch({
				type: SET_KEYPAD_NUMBERS,
				newStoredNumbers: newStoredNums
			});

			if (newStoredNums.length === 4) {
				dispatch(checkPinCode(newStoredNums));
			}
		} else {
			if (storedNumbers.length < 20) {
				newStoredNums.push(number);

				dispatch({
					type: SET_KEYPAD_NUMBERS,
					newStoredNumbers: newStoredNums
				});
			}
		}
	};
}

export function checkPinCode(combination) {
	return (dispatch, getState) => {
		let unlockPin = getState().pincode;

		// non state variables
		let success = false;

		for (let i = 0; i < 4; i++) {
			if (combination[i] === unlockPin[i] && combination.length === i + 1) {
				success = true;
			}
		}

		if (success) {
			// Play animation of success
			dispatch(submitNumberValue());
		} else {
			dispatch({
				type: SET_KEYPAD_NUMBERS,
				newStoredNumbers: []
			});
		}
	};
}

// used for both pincode and number checking
export function submitNumberValue() {
	return (dispatch, getState) => {
		let storedNumbers = getState().storedNumbers;
		let passcodeType = getState().modalProps.data.passcodeType;

		if (storedNumbers.length > 0) {
			switch (passcodeType) {
			case 'keypad_interaction':
				dispatch(interactionActions.compareNumResults(storedNumbers.join('')));
				break;
			case 'keypad_locked_start':
				dispatch(gameActions.startGame());
				dispatch(uniModalActions.closeModal());
				break;
			case 'keypad_locked_end':
				break;
			default:
				console.error('Keypad type ' + passcodeType + ' not recognized');
				break;
			}
		}

		dispatch({
			type: SET_KEYPAD_NUMBERS,
			newStoredNumbers: []
		});
	};
}

export function backSpace() {
	return (dispatch, getState) => {
		let tempCode = getState().storedNumbers;

		tempCode.pop();
		dispatch({
			type: SET_KEYPAD_NUMBERS,
			newStoredNumbers: tempCode
		});
	};
}
