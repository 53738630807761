// import {Keyboard} from 'react-native';

import Common from '../lib/Globals.js';

import * as userActions from './User';
import * as gameActions from './Game';
import * as timerActions from './Timers';
import * as experienceActions from './Experience';
import * as uniModalActions from './UniversalModal';

/*
 * action types
 */
export const UPDATE_ATTEMPTS = 'UPDATE_ATTEMPTS';
export const SET_INTERACTION_TEXT = 'SET_INTERACTION_TEXT';
export const SET_IMAGE_SELECTION = 'SET_IMAGE_SELECTION';
export const RESET_IMAGE_SELECTION = 'RESET_IMAGE_SELECTION';
export const SETUP_SURVEY_OPTIONS = 'SETUP_SURVEY_OPTIONS';
export const SET_SURVEY_SELECTION = 'SET_SURVEY_SELECTION';
export const TOGGLE_ANSWER_PROCESS_LOCK = 'TOGGLE_ANSWER_PROCESS_LOCK';
export const PROCESS_INTERACTION_ANSWER = 'PROCESS_INTERACTION_ANSWER';

/*
 * action creators
 */
export function subtractAttempt() {
	return (dispatch, getState) => {
		let previousAttempts = getState().interactionAttempts.attempts;

		// non state variables
		let attemptsLeft = (previousAttempts -= 1);

		dispatch({
			type: UPDATE_ATTEMPTS,
			attempts: attemptsLeft
		});
	};
}

// set text interaction input
export function setInteractionText(text) {
	return {
		type: SET_INTERACTION_TEXT,
		text: text
	};
}

export function selectImage(index) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;
		let imageSelectionArray = getState().interactionImageSelection;

		// non state variables
		let interaction = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT];
		// Switch selection icon on/off through checking var
		// Add and remove item from array as pressed.
		let currentSelectedImages = imageSelectionArray;

		if (interaction.answer.match_all === 'yes') {
			// match all answers checked
			if (imageSelectionArray.includes(index)) {
				currentSelectedImages.splice(imageSelectionArray.indexOf(index), 1);
			} else {
				currentSelectedImages.push(index);
			}
		} else if (interaction.answer.combine_answers === true) {
			// combine answers checked
			let maxImages = parseInt(interaction.answer.max_selection);

			if (imageSelectionArray.length === maxImages) {
				if (imageSelectionArray.includes(index)) {
					currentSelectedImages.splice(imageSelectionArray.indexOf(index), 1);
				} else {
					currentSelectedImages.shift();
					currentSelectedImages.push(index);
				}
			} else {
				if (imageSelectionArray.includes(index)) {
					currentSelectedImages.splice(imageSelectionArray.indexOf(index), 1);
				} else {
					currentSelectedImages.push(index);
				}
			}
		} else {
			// single image selection
			if (imageSelectionArray.includes(index)) {
				currentSelectedImages.splice(imageSelectionArray.indexOf(index), 1);
			} else {
				currentSelectedImages = [index];
			}
		}

		dispatch({
			type: SET_IMAGE_SELECTION,
			selection: currentSelectedImages
		});
	};
}

export function resetImageSelection() {
	return {
		type: RESET_IMAGE_SELECTION
	};
}

export function setUpSurvey(answers) {
	return (dispatch, getState) => {
		let tempOptionArray = [];
		let tempArray = answers;

		for (let i = 0; i < tempArray.length; i++) {
			let optionObject = {
				key: tempArray[i].description,
				selected: false,
				icon: 'radio-button-unchecked'
			};

			tempOptionArray.push(optionObject);
		}

		dispatch({
			type: SETUP_SURVEY_OPTIONS,
			surveyArray: tempOptionArray
		});
	};
}

// set survey input
export function setSurveySelection(selection) {
	return {
		type: SET_SURVEY_SELECTION,
		selection: selection
	};
}

let checkForIncorrectResponseObj = resolutions => {
	let newResolutions = resolutions;

	if (newResolutions[0].incorrect_answer_response === 'yes') {
		newResolutions = resolutions.slice(1);
	}

	return newResolutions;
};

// interaction answer processing functions
export function submitButtonPressed() {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let interaction = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT];
		let answers = interaction.resolutions;

		// Run different cases depending on what puzzle type we are on.
		switch (interaction.type) {
		case 'number':
			dispatch(uniModalActions.openModal('keypad_interaction'));
			break;
		case 'text':
			if (getState().interactionTextInput.length > 0) {
				dispatch(compareTextResults(getState().interactionTextInput, answers));
			}
			break;
		case 'image selection':
			// stops the user from getting an incorrect answer if nothing is selected
			if (getState().interactionImageSelection.length > 0) {
				dispatch(compareImageSelectionResult(interaction, answers));
			}
			break;
		case 'content':
			dispatch(processInteractionAnswer(0, true, 'n/a'));
			break;
		case 'survey':
			let selection = getState().interactionSurveySelection;
			// stops the user from getting an incorrect answer if nothing is selected
			if (selection !== '') {
				dispatch(surveySelection(selection, interaction, answers));
			}
			break;
		default:
			console.log('No interaction loaded');
			break;
		}
	};
}

// called from customNumberPad
export function compareNumResults(userInputNum) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;

		// non state variables
		let interaction = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT];
		let answer = interaction.resolutions;
		let correctAnswer = false;
		let answerIndex = 0;
		let answerDesc = {
			answer: ''
		};

		for (let i = 0; i < answer.length; i++) {
			if (answer[i].int_value !== undefined) {
				let interactionAnswerNum = answer[i].int_value;

				if (userInputNum === interactionAnswerNum && i !== 0) {
					answerDesc.answer = answer[i].description;
					correctAnswer = true;
					answerIndex = i;
					break;
				}
			}
		}

		if (!getState().answerProcessLock) {
			dispatch(toggleAnswerLock(true));
			dispatch(processInteractionAnswer(answerIndex, correctAnswer, answerDesc.answer));
		}
	};
}

export function compareTextResults(userText, answers) {
	return (dispatch) => {
		let correctAnswer = false;
		let answerIndex = 0;
		let userInputText = Common.removeAllSpecialCharacters(userText.toLowerCase());
		let answerDesc = {
			answer: ''
		};

		let userAnswerText = new RegExp("\\b" + userInputText + "\\b", 'g');

		for (let i = 0; i < answers.length; i++) {
			if (answers[i].string_value !== undefined) {
				// New work on logic to update how string comparisons for text interactions are done
				if (i !== 0) {
					let answerText = Common.removeAllSpecialCharacters(answers[i].string_value.toLowerCase());
					let match = userAnswerText.test(answerText);

					if (match) {
						answerDesc.answer = answers[i].description;
						correctAnswer = true;
						answerIndex = i;
						break;
					} else {
						answerDesc.answer = userText;
					}
				}
			}
		}

		dispatch(processInteractionAnswer(answerIndex, correctAnswer, answerDesc.answer));
		dispatch(setInteractionText(''));
	};
}

export function compareImageSelectionResult(interaction, answerArray) {
	return (dispatch, getState) => {
		let correctAnswer = false;
		let correctSelection = 0; // acts as a counter for ensuring the correct number of selections has been performed
		let answerIndex = 0;
		let userSelectedImages = getState().interactionImageSelection;
		let answerDesc = {
			answer: '',
		};

		// need to fix backend functionality to get this to work correctly
		if (interaction.answer.match_all === 'yes') { // multiple image selection interaction
			// cycle through each potential answer possibility in the interaction array
			for (let i = 0; i < answerArray.length; i++) {
				if (answerArray[i].image_value !== undefined) {
					// selected the required number of images
					if (userSelectedImages.length === answerArray[i].image_value.length) {
						for (let j = 0; j < userSelectedImages.length; j++) {
							if (interaction.answer.order_required === 'yes') {
								if (userSelectedImages[j].toString() !== answerArray[i].image_value[j]) {
									// selected image was not apart of the answer it's being compared against. Break out of useless testing from this point.
									correctSelection = 0;
									break;
								} else {
									// correct, keep cycling through answer entries.
									correctSelection++;
								}
							} else {
								if (answerArray[i].image_value.includes(userSelectedImages[j].toString())) {
									correctSelection++;
								} else {
									// If playing a match all w/ no order required and element selected not in correct answer.
									correctSelection = 0;
									break;
								}
							}
						}
					} else {
						// Incorrect count of entries, no point testing.
						correctSelection = 0;
					}
					// if the correct number of images have been selected
					if (correctSelection === answerArray[i].image_value.length) {
						correctAnswer = true;
						answerIndex = i;
						answerDesc['answer'] = answerArray[i].description;
						break;
					}
				}
			}
		} else if (interaction.answer.combine_answers === true) { // combining answers from separate image selections
			let minImages = parseInt(interaction.answer.min_selection);
			let maxImages = parseInt(interaction.answer.max_selection);
			let correctAnsArray = [];
			let answerRewardArray = [];
			let answerDescArray = [];

			if (userSelectedImages.length <= maxImages && userSelectedImages.length >= minImages) {
				for (let i = 0; i < userSelectedImages.length; i++) {
					let imageSel = userSelectedImages[i].toString();
					let selected = false;

					for (let j = 0; j < answerArray.length; j++) {
						if (answerArray[j].image_value !== undefined && answerArray[j].image_value.includes(imageSel)) {
							selected = true;
							// extract reward from rewards array to push into answer reward array
							for (let k = 0; k < answerArray[j].rewards.length; k++) {
								answerRewardArray.push(answerArray[j].rewards[k]);
							}
							answerDescArray.push(answerArray[j].description);
						}
					}
					correctAnsArray.push(selected);
				}
			} else {
				correctSelection = 0;
			}

			if (correctAnsArray.includes(false)) {
				correctSelection = 0;
			} else {
				correctSelection = correctAnsArray.length;
			}

			// if the correct number of images have been selected
			if (correctSelection <= maxImages && correctSelection >= minImages) {
				correctAnswer = true;
				answerIndex = answerRewardArray;
				answerDesc['answer'] = answerDescArray;
			} else {
				correctSelection = 0;
			}
		} else {
			// single image selection
			for (let i = 0; i < answerArray.length; i++) {
				if (answerArray[i].image_value !== undefined) {
					for (let j = 0; j < answerArray[i].image_value.length; j++) {
						let imageAnswer = parseInt(answerArray[i].image_value[j]);

						for (let k = 0; k < userSelectedImages.length; k++) {
							let selectedImage = userSelectedImages[k];

							if (selectedImage === imageAnswer) {
								answerDesc['answer'] = answerArray[i].description;
								correctAnswer = true;
								answerIndex = i;
								break;
							}
						}
					}
				}
			}
		}

		dispatch(processInteractionAnswer(answerIndex, correctAnswer, answerDesc.answer));
		dispatch(resetImageSelection([]));
	};
}

// QR/Barcode comparison logic
// export function compareCodeResult(code) {
// 	return (dispatch, getState) => {
// 		let gameData = getState().gameData;
// 		let cMSI = getState().currentMasterStageIndex;
// 		let cSI = getState().currentStageIndex;
// 		let cINT = getState().currentInteractionIndex;

// 		// non state variables
// 		let answers = gameData.master_stages[cMSI].stages[cSI].puzzles[cINT].resolutions;
// 		let correctAnswer = false;
// 		let answerIndex = 0;
// 		let userCode = code;
// 		let answerDesc = {
// 			answer: '',
// 		};

// 		for (let i = 0; i < answers.length; i++) {
// 			if (answers[i].string_value !== undefined) {
// 				let answerCode = answers[i].string_value.toString();

// 				if (userCode === answerCode) {
// 					answerDesc['answer'] = answers[i].description;
// 					correctAnswer = true;
// 					answerIndex = i;
// 					break;
// 				}
// 			}
// 		}

// 		if (!getState().answerProcessLock) {
// 			dispatch(toggleAnswerLock(true));

// 			// reset code value for QR/Beacons
// 			dispatch(processInteractionAnswer(answerIndex, correctAnswer, answerDesc.answer));
// 		}
// 	};
// }

export function surveySelection(selection, interaction, answers) {
	return (dispatch, getState) => {
		let correctAnswer = false;
		let answerIndex = 0;
		let userResponse = {};

		for (let i = 0; i < answers.length; i++) {
			let surveyChoice = answers[i].description;

			if (selection === surveyChoice) {
				userResponse['selection'] = answers[i].description;
				correctAnswer = true;
				answerIndex = i;
				break;
			}
		}

		dispatch(processInteractionAnswer(answerIndex, correctAnswer, userResponse));
	};
}

// logic from interaction panel. Will need to update this logic to be compatible with the interaction panel logic and redux
export function processInteractionAnswer(answerIndex, matchedAnswer, userAnswer) {
	return (dispatch, getState) => {
		let gameData = getState().gameData;
		let cMSI = getState().currentMasterStageIndex;
		let cSI = getState().currentStageIndex;
		let cINT = getState().currentInteractionIndex;
		let userData = getState().userData;
		let gameStatus = getState().gameFeedback.status;
		let userNote = getState().userNotesText;

		// non state variables
		let stage = gameData.master_stages[cMSI].stages[cSI];
		let interaction = stage.puzzles[cINT];
		let userInteractionData = userData.master_stage[cMSI].stage[cSI].puzzle[cINT];
		let repeatable = false;
		let correct = matchedAnswer;
		let answer = userAnswer;
		let feedBackType = 'interaction_incorrect';

		if (getState().showModal) {
			dispatch(uniModalActions.closeModal());
		}

		if (interaction.repeatable_interaction === 'yes' || stage.repeatable_stage === 'yes') {
			repeatable = true;
		}

		if (interaction.accept_any_answer === 'yes') {
			correct = true;
		}

		// checks for Survey interaction and if it is a repeatable interaction or nested within a repeatable stage
		if (interaction.type === 'survey' && repeatable) {
			let answerArray = Array.isArray(userInteractionData.answer);
			let tempAnswer = answerArray ? userInteractionData.answer : [];

			tempAnswer.push(userAnswer);
			answer = tempAnswer;
		}

		if (gameStatus !== 'masterExpired' && gameStatus !== 'stageExpired') {
			dispatch({
				type: PROCESS_INTERACTION_ANSWER,
				correct: correct,
				answer: answer
			});

			if (correct) {
				feedBackType = 'interaction_correct';

				// should only create a note if it's a correct answer
				if (interaction.create_answer_note || interaction.text_field === 'yes') {
					// if there is a user note associated with the interaction create a new note for it
					let noteTitle = interaction.title;
					let noteContent = '';
					// User defined note
					if (interaction.text_field === 'yes') {
						if (userNote !== '') {
							dispatch(userActions.createNote(noteTitle, userNote));
						}
					} else {
						// note generated from answer description
						noteContent = '<p>' + interaction.resolutions[answerIndex].description + '</p>';
						dispatch(userActions.createNote(noteTitle, noteContent));
					}
				}

				// getting reward when you answer correctly is here. Rewards tied to incorrect answers is handled within giveGameFeedback
				dispatch(userActions.giveReward(answerIndex));
				dispatch(timerActions.stopInteractionTimer());
			}

			dispatch(experienceActions.giveGameFeedback(feedBackType));
		}
	};
}

export function toggleAnswerLock(bool) {
	return {
		type: TOGGLE_ANSWER_PROCESS_LOCK,
		bool: bool
	};
}
