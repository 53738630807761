const Styles480 = require('./Styles_480.js');

// need to tie in function that updates the script being used according the the screen size designation given
// < 480  = small
// 480 > 960 = medium
// 960 > 1920 = large
// > 1920 = x-large


module.exports = Styles480;
