import {
	ADD_NOTIFICATION,
	REMOVE_NOTIFICATION,
	TOGGLE_NOTIFICATION,
	ASSIGN_NOTIFICATION_PROPS
} from '../actions/Notifications';

// holds the content object for the notification module
export function notificationArray(state = [], action) {
	switch (action.type) {
	case ADD_NOTIFICATION:
		state.push(action.content);
		return state;
	case REMOVE_NOTIFICATION:
		let notifications = state.filter((item, index) => item.id !== action.id);
		return notifications;
	default:
		return state;
	}
}

export function notificationProps(state = { id: null, content: 'Default text', severity: 'error' }, action) {
	switch (action.type) {
	case ASSIGN_NOTIFICATION_PROPS:
		state.id = action.id;
		state.content = action.content;
		state.severity = action.severity;
		return state;
	default:
		return state;
	}
}

export function showNotification(state = false, action) {
	switch (action.type) {
	case ASSIGN_NOTIFICATION_PROPS:
	case TOGGLE_NOTIFICATION:
		state = action.isOpen;
		return state;
	default:
		return state;
	}
}