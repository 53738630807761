// react and react native resources imports
import React, { Component } from 'react';
// import {BackHandler, View, Text, Animated, Easing} from 'react-native';

// third party resources imports
// import Button from './Button';
import { Button, IconButton } from '@material-ui/core';
import { Backspace, Close, KeyboardReturn } from '@material-ui/icons';

// custom resources imports
import Styles from '../lib/Styles';

// declares local variables
const styles = Styles.components.customNumberPad;

export default class CustomNumberpad extends Component {
	// Set up default prop values in case none were introduced
	constructor(props) {
		super(props);
		let pin = this.props.gameData.exit_pin_code.split(['',]).map(Number);

		this.state = {
			selectedNumbers: [],
			correctCombination: pin
		};
		// this.springValue = new Animated.Value(0);
		// this.animatedFailOpacity = new Animated.Value(0);
		// this.animatedSuccessOpacity = new Animated.Value(0);
	}

	// If the passcode was wrong, shake the input field
	// _shakeInputField() {
	// 	this.springValue.setValue(0.3);
	// 	this.animatedFailOpacity.setValue(0);

	// 	Animated.spring(this.springValue, {
	// 		toValue: 1,
	// 		friction: 5,
	// 		tension: 200,
	// 		useNativeDriver: false,
	// 	}).start();

	// 	Animated.timing(this.animatedFailOpacity, {
	// 		toValue: 1,
	// 		duration: 800,
	// 		easing: Easing.linear,
	// 		useNativeDriver: false,
	// 	}).start();
	// }

	// // If the unlock / pass key was accepted, show a green fade for success
	// _unlockSuccessful() {
	// 	this.animatedSuccessOpacity.setValue(0);
	// 	Animated.timing(this.animatedSuccessOpacity, {
	// 		toValue: 1,
	// 		duration: 1000,
	// 		easing: Easing.linear,
	// 		useNativeDriver: false,
	// 	}).start();
	// }

	// Pre-Render input field elements and return the view as an array of items
	preRenderInputField() {
		const { gameStyles, stageStyles } = this.props;

		let result = [];
		// Used to display a red color pulse on failure
		// const failOpacityValue = this.animatedFailOpacity.interpolate({
		// 	inputRange: [0, 0.5, 1],
		// 	outputRange: ['rgba(255, 0, 0, 0)', 'rgba(255, 0, 0, 1)', 'rgba(255, 0, 0, 0)'],
		// });
		// // Used when the input was a correct code as a green pulse
		// const successOpacityValue = this.animatedSuccessOpacity.interpolate({
		// 	inputRange: [0, 0.5, 1],
		// 	outputRange: ['rgba(0, 255, 0, 0)', 'rgba(0, 255, 0, 1)', 'rgba(0, 255, 0, 0)'],
		// });
		// Loop through each input visual item to display
		if (this.props.keypadMode === 'passcode') {
			for (let i = 0; i < 4; i++) {
				result.push(
					this.state.selectedNumbers.length > i ?
						<div key={i} style={Object.assign({}, styles.passcodeItemContainer/*, { borderColor: stageStyles.secondary.color ? stageStyles.secondary.color : gameStyles.secondary.color }*/)}>
							{/*<Animated.View style={[styles.inputVisualBackground, {backgroundColor: successOpacityValue}]}>*/}
								<div style={styles.passcodeItem}>
									<div style={styles.passcodeTextContainer}>
										<p style={Object.assign({}, gameStyles.secondary, styles.passcodeText)}>*</p>
									</div>
								</div>
							{/*</Animated.View>*/}
					</div> :
						<div key={i} style={Object.assign({}, styles.passcodeItemContainer/*, { borderColor: stageStyles.secondary.color ? stageStyles.secondary.color : gameStyles.secondary.color }*/)}>
							<div style={styles.passcodeItem} />
							{/*<Animated.View style={[styles.inputVisualBackground, {backgroundColor: failOpacityValue}]} />*/}
						</div>
				);
			}
		} else {
			result = <p style={Object.assign({}, { color: gameStyles.secondary.color })}>{this.props.storedNumbers}</p>;
		}

		return result;
	}

	// Pre-Render the keypad buttons and set up the onPress functions
	preRenderKeypadButtons() {
		const { gameStyles, stageStyles } = this.props;

		let row = [0, 1, 2];
		// Go through each row
		let grid = row.map((item, y) => {
			// pre-rendering the row of buttons
			let rowContent = row.map((column, x) => {
				let index = 3 * y + x + 1;

				return (
					<div
						style={styles.keypadButtonContainer}
						key={'rowColumnIndex' + (x + y)}>
						<Button variant={'contained'}
							style={Object.assign({}, styles.keypadButton, { backgroundColor: stageStyles.primary.color ? stageStyles.primary.color : gameStyles.primary.color })}
							onClick={() => {
								this.props.keypadNumberInput(index);
							}}
						><p style={styles.keypadButtonText}>{index}</p></Button>
					</div>
				);
			});

			return (
				<div style={styles.rowContentItems} key={'rowIndex' + y}>{rowContent}</div>
			);
		});

		return grid;
	}

	renderKeypadTitle() {
		switch (this.props.modalProps.data.keypadMode) {
		case 'interaction_number':
			return 'Enter Answer';
		case 'passcode':
			return 'Enter Passcode';
		default:
			return 'Keypad';
		}
	}

	render() {
		const { gameStyles, stageStyles } = this.props;
		// Set up variables for the animations
		// Used for the shaking of the input field on failure
		// const movingMargin = this.springValue.interpolate({
		// 	inputRange: [0, 0.5, 1],
		// 	outputRange: [0, 70, 0]
		// });

		return (
			// <div style={[styles.container, (this.props.keypadMode === 'admin' ? styles.adminBorder : null) ]}>
			<div style={styles.container}>
				<div style={styles.header}>
				 	<div style={styles.titleContainer}>
				 		<div style={styles.titleContentContainer} />
				 		<div style={Object.assign({}, styles.titleContentContainer, { flex: 2 })}>
				 			<h1 style={Object.assign({}, gameStyles.secondary, styles.title)}>{this.renderKeypadTitle() }</h1>
				 		</div>
				 		<div style={styles.titleContentContainer}>
					 		<IconButton
					 			variant={'contained'}
								style={styles.exitButton}
								onClick={() => {
									// Do somthing to close the view
									this.props.closeModal();
									// unlock the interaction submit button
									this.props.lockSubmitButton(false);
								}}
							>
								<Close />
							</IconButton>
						</div>
					</div>
					<div style={styles.inputFieldContainer}>
					{/*<Animated.View style={[styles.inputVisualContainer, { transform: [{translateX: movingMargin }]}]}>*/}
						<div style={Object.assign({ display: 'flex', flex: 1 }, this.props.keypadMode === 'passcode' ? {} : styles.inputNumberField)}>
							{ this.preRenderInputField() }
						</div>
					</div>
					{/*</Animated.View>*/}
				</div>
				<div style={styles.keyPadContainer}>
					{ this.preRenderKeypadButtons() }
					<div style={styles.rowContentItems}>
						<div style={styles.keypadButtonContainer}>
							<IconButton variant={'contained'}
								style={Object.assign({}, styles.keypadButton, { color: 'white', backgroundColor: stageStyles.secondary.color ? stageStyles.secondary.color : gameStyles.secondary.color })}
								onClick={() => {
									this.props.backSpace();
								}}
							>
								<Backspace />
							</IconButton>
						</div>
						<div style={styles.keypadButtonContainer}>
							<Button variant={'contained'}
								style={Object.assign({}, styles.keypadButton, { backgroundColor: stageStyles.primary.color ? stageStyles.primary.color : gameStyles.primary.color })}
								onClick={() => {
									this.props.keypadNumberInput(0);
								}}
							><p style={styles.keypadButtonText}>0</p></Button>
						</div>
						<div style={styles.keypadButtonContainer}>
							{this.props.keypadMode === 'passcode' ? (
								<div style={styles.keypadButtonContainer} />
							) : (
								<Button
									style={Object.assign({}, styles.keypadButton, { color: 'white', backgroundColor: stageStyles.primary.color ? stageStyles.primary.color : gameStyles.primary.color })}
									onClick={() => {
										this.props.submitNumberValue();
									}}
								>
									<KeyboardReturn />
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
